import { flashMessage } from "redux-flash"
import { call, put } from 'redux-saga/effects'
import { deleteP2ReportError, deleteP2ReportSuccess, getP2ReportsError, getP2ReportsSuccess, postP2ReportError, postP2ReportSuccess } from '../actions/P2ReportsAction'
import { deleteP2ReportApi, getP2ReportsApi, postP2ReportApi } from '../services/Api'
import { getCookie } from '../utils/Cookies'
import history from '../services/History'

export function* getP2ReportsSaga() {
    try {
        const data = yield call(getP2ReportsApi, getCookie('access_token'))
        yield put(getP2ReportsSuccess(data))
    } catch (e) {
        yield put(getP2ReportsError())
    }
}

export function* postP2ReportSaga(actions) {
    try {
        const data = yield call(postP2ReportApi, actions.payload, getCookie('access_token'))
        yield put(postP2ReportSuccess(data))
        history.replace("/chiffrages-p2")
        yield put(flashMessage('Elément sauvegardé'))
    } catch (e) {
        yield put(flashMessage('Une erreur est survenu durant la sauvegarde.'))
        yield put(postP2ReportError())
    }
}

export function* deleteP2ReportSaga(actions) {
    try {
        yield call(deleteP2ReportApi, actions.payload, getCookie('access_token'))
        yield put(deleteP2ReportSuccess(actions.payload))
        yield put(flashMessage('Elément supprimé'))
    } catch (e) {
        yield put(deleteP2ReportError())
    }
}
import Badge from '@mui/material/Badge'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { useMediaQuery } from 'react-responsive'
// UI Elements
import Typography from '@mui/material/Typography'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Customer.module.css'

const Customer = (props) => {
  const href = '/customers/' + props.data.id
  const edit = '/customers/' + props.data.id + '/edit'
  const sites = '/customers/' + props.data.id + '/locations'
  const allotments = '/customers/' + props.data.id + '/lots'

  const isSmallScreen = useMediaQuery({
    query: '(max-width: 576px)'
  })

  const style = isSmallScreen ? { width: '100%' } : {}

  return (
    <Card className={styles.card} style={style}>
      <CardContent>
        <Typography variant="h6">
          {props.data.name}
        </Typography>
      </CardContent>
      <CardActions>
        {
          !props.readonly &&
          <>
            <Link className="btn btn-sm btn-warning" to={edit}>
              Modifier
            </Link>
            <a onClick={(e) => {
              e.preventDefault()
              props.onClone(props.data.id)
            }} href="#" className="btn btn-sm btn-info ml-2">
              Dupliquer
            </a>
          </>
        }
        <Badge className='ml-2' color="secondary" badgeContent={props.data.locations_counter}>
          <Link className="btn btn-sm btn-success" to={sites}>
            Sites
          </Link>
        </Badge>
        <Badge className='ml-2' color="secondary" badgeContent={props.data.allotments_counter}>
          <Link className="btn btn-sm btn-success" to={allotments}>
            Lots
          </Link>
        </Badge>
      </CardActions>
    </Card>
  )
}

export default Customer

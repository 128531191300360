import { call, put } from 'redux-saga/effects'
import { loginUserErrorAction, loginUserSuccessAction } from '../actions/LoginActions'
import { doPasswordAuthentication } from '../services/Api'
import { removeCookies, setCookie } from '../utils/Cookies'
import History from '../services/History'

export function* loginSaga(action) {
  const { payload: { email, password }, meta: { formik, from } } = action
  try {
    const rsp = yield call(doPasswordAuthentication, email, password)
    formik.setSubmitting(false)
    const { access_token, refresh_token } = rsp
    setCookie('access_token', access_token)
    setCookie('refresh_token', refresh_token, 2376)
    yield put(loginUserSuccessAction())
    if (from) {
      History.push(from)
    } else {
      History.push('/dashboard')
    }

  } catch (error) {
    formik.setValues({ email, password: '' })
    formik.setSubmitting(false)
    yield put(loginUserErrorAction("Erreur dans l'email ou le mot de passe !"))
  }
}

export function* logoutSaga() {
  removeCookies('access_token')
  removeCookies('refresh_token')
}
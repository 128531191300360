import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorkerRegistration";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => {
    alertify.confirm(
      "Une nouvelle version est disponible",
      function () {
        window.location.reload();
      },
      function () {}
    );
  },
});

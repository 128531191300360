import { defineDriver } from 'localforage'
import * as R from 'ramda'
import { createReducer } from 'reduxsauce'
import { OFFICE_SUCCESS, OFFICE_REQUEST, OFFICE_ERROR } from '../actions'


const initialValues = {
    data: [],
    fetching: false,
    error: null
}

const officesRequest = (state) => {
    return {
        ...state,
        fetching: true,
        error: null
    }
}

const officesSuccess = (state, action) => {
    const { payload } = action
    return {
        ...state,
        fetching: false,
        data: payload
    }
}

const officesError = (state) => {
    return {
        ...state,
        fetching: false,
        error: 'Une erreur est survenu.'
    }
}

export default createReducer(initialValues, {
    [OFFICE_REQUEST]: officesRequest,
    [OFFICE_SUCCESS]: officesSuccess,
    [OFFICE_ERROR]: officesError
})
import { AppBar } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import Menu from '../components/Menu'

const Header = ({ isConnected }) => {
    if (!isConnected) {
        return (
            <AppBar position='sticky' />
        )
    }
    return (
        <AppBar position='sticky' color="default">
            <Menu />
        </AppBar>
    )
}

const mapStateToProps = (state) => {
    return {
        isConnected: state.login.isAuthentified
    }
}

export default connect(mapStateToProps)(Header)
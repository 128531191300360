import { createSlice, createAction } from "@reduxjs/toolkit";
import * as R from "ramda";
import { createSelector } from "reselect";

const getEntities = (state) => state.products_visits.entities;
const getProductsVisitsIDS = (visit_id) => (state) =>
  state.products_visits.ids[visit_id] || [];
const getProductsVisitsVersions = (visit_id) => (state) =>
  state.products_visits.versions[visit_id] || [];
export const getProductsVisits = (visit_id) => {
  return createSelector(
    getEntities,
    getProductsVisitsIDS(visit_id),
    getProductsVisitsVersions(visit_id),
    (entities, ids, versionIds) => {
      return R.compose(
        R.reject(R.isNil),
        R.map((x) => entities[x])
      )([...ids, ...versionIds]);
    }
  );
};
export const getProductsVisitsForBuildingId = (visitId, buildingId) => {
  return createSelector(getProductsVisits(visitId), (entities) => {
    return R.filter((x) => x.building_id === buildingId, entities);
  });
};

const syncProductVisitSuccess = "ProductsVisits.syncProductVisitSuccess";
const syncProductVisitError = "ProductsVisits.syncProductVisitError";
const syncProductVisit = createAction(
  "ProductsVisits.syncProductVisit",
  (payload, visit_id, productState = "UPDATED") => {
    return {
      payload,
      meta: {
        visit_id,
        productState,
        createdAt: new Date().toISOString(),
        offline: {
          effect: {
            url: `/api/v2/product_visits/${visit_id}`,
            method: "PATCH",
            json: payload,
          },
          commit: {
            type: syncProductVisitSuccess,
            meta: { visit_id, productState },
          },
          rollback: {
            type: syncProductVisitError,
            meta: {
              visit_id,
              productState,
            },
          },
        },
      },
    };
  }
);

const initialState = {
  entities: {},
  ids: {},
  versions: {},
  fetching: false,
  error: false,
};

const ProductsVisitsSlice = createSlice({
  name: "ProductsVisits",
  initialState,
  reducers: {
    requestFromVisitId(state, actions) {
      const {
        payload: { visit_id },
      } = actions;
      state.fetching = true;
      state.error = false;
      state.ids[visit_id] ||= [];
      state.versions[visit_id] ||= [];
    },
    requestFromVisitIdSuccess: {
      prepare(payload, meta) {
        return { payload, meta };
      },
      reducer(state, actions) {
        const {
          payload,
          meta: { visit_id },
        } = actions;
        state.fetching = false;
        payload.forEach((x) => {
          state.entities[x.id] = x;
        });

        const ids = R.map((x) => x.id, payload);
        state.ids[visit_id] = ids;
      },
    },
    requestFromVisitIdError(state, action) {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(syncProductVisit.type, (state, actions) => {
        const {
          meta: { visit_id, productState },
          payload: { _destroy, id },
        } = actions;

        state.versions[visit_id] ||= [];
        state.ids[visit_id] ||= [];
        if (productState === "DELETED") {
          delete state.entities[id];
          const index = state.ids[visit_id].findIndex((x) => x === id);
          if (index >= 0) {
            state.ids[visit_id].splice(index, 1);
          }

          const versionIndex = state.versions[visit_id].findIndex(
            (x) => x === id
          );
          if (versionIndex >= 0) {
            state.versions[visit_id].splice(versionIndex, 1);
          }
        } else if (productState === "ADDED") {
          state.versions[visit_id].push(id);
          state.entities[id] = actions.payload;
        } else {
          state.entities[id] = actions.payload;
        }
      })
      .addCase(syncProductVisitSuccess, (state, actions) => {
        const {
          meta: { visit_id, productState },
          payload: { id },
        } = actions;

        if (productState === "DELETED") {
          delete state.entities[id];
          const index = state.ids[visit_id].findIndex((x) => x === id);
          if (index >= 0) {
            state.ids[visit_id].splice(index, 1);
          }
        } else if (productState === "ADDED") {
          state.entities[id] = actions.payload;
          state.ids[visit_id].push(id);
          const index = state.versions[visit_id].findIndex((x) => x === id);
          if (index >= 0) {
            state.versions[visit_id].splice(index, 1);
          }
        } else {
          state.entities[id] = actions.payload;
        }
      });
  },
});

export const ProductsVisitsReducer = ProductsVisitsSlice.reducer;
export const ProductsVisitsActions = {
  ...ProductsVisitsSlice.actions,
  syncProductVisit,
};

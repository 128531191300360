import history from '../services/History'
import { flashMessage } from "redux-flash"
import { call, put } from 'redux-saga/effects'
import { deleteCompareRapportError, deleteCompareRapportSuccess, getCompareRapportsError, getCompareRapportsSuccess, postCompareRapportError, postCompareRapportSuccess } from '../actions/CompareRapportsAction'
import { deleteCompareRapportApi, getCompareRapportsApi, postCompareRapportApi } from '../services/Api'
import { getCookie } from '../utils/Cookies'

export function* getCompareRapportsSaga() {
    try {
        const data = yield call(getCompareRapportsApi, getCookie('access_token'))
        yield put(getCompareRapportsSuccess(data))
    } catch (e) {
        yield put(getCompareRapportsError())
    }
}

export function* postCompareRapportSaga(actions) {
    try {
        const data = yield call(postCompareRapportApi, actions.payload, getCookie('access_token'))
        yield put(postCompareRapportSuccess(data))
        history.replace("/comparaison_rapports")
        yield put(flashMessage('Elément sauvegardé'))
    } catch (e) {
        yield put(flashMessage("Merci de sélectionner deux visites par site"))
        yield put(postCompareRapportError())
    }
}

export function* deleteCompareRapportSaga(actions) {
    try {
        yield call(deleteCompareRapportApi, actions.payload, getCookie('access_token'))
        yield put(deleteCompareRapportSuccess(actions.payload))
        yield put(flashMessage('Elément supprimé'))
    } catch (e) {
        yield put(deleteCompareRapportError())
    }
}
import { OFFICE_ERROR, OFFICE_REQUEST, OFFICE_SUCCESS } from './index'

export const OfficeRequest = () => {
    return {
        type: OFFICE_REQUEST
    }
}

export const OfficeSuccess = (payload) => {
    return {
        type: OFFICE_SUCCESS,
        payload
    }
}

export const OfficeError = () => {
    return {
        type: OFFICE_ERROR
    }
}
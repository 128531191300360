import { call, put } from 'redux-saga/effects'
import * as types from '../actions'
import { getProductTypes } from '../services/Api'


export function* productTypesSaga(payload) {
  try {
    const response = yield call(getProductTypes, payload)
    yield put({ type: types.GET_PRODUCT_TYPES_SUCCESS, response })

  } catch (error) {
    yield put({ type: types.GET_PRODUCT_TYPES_ERROR, error })
  }
}

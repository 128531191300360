import { useField } from 'formik'
import moment from 'moment'
import * as R from 'ramda'
import React, { useCallback } from 'react'

const VisitsSelector = R.compose(
    R.take(1000),
    R.sort((a, b) => {
        const aDate = moment(a.seen_at).format('YYYYMMDD')
        const bDate = moment(b.seen_at).format('YYYYMMDD')
        return bDate - aDate
    })
)

const VisitCheckbox = ({ visit }) => {
    const [field, meta, helpers] = useField('visit_ids')
    const onChange = useCallback((e) => {
        const value = e.target.checked

        if (value) {
            helpers.setValue(R.append(visit.id, field.value))
        } else {
            helpers.setValue(R.without([visit.id], field.value))
        }
    }, [field])

    moment().locale('fr')
    let date = moment(visit.seen_at).format('DD/MM/YYYY')
    return (
        <div className='rapportsCheckboxContainer'>
            <input onChange={onChange} checked={field.value.includes(visit.id)} type='checkbox' id={`visit-${visit.id}`} />
            <label htmlFor={`visit-${visit.id}`}>Visite du {date}</label>
        </div>
    )
}

const VisitsCheckbox = ({ visits }) => {
    return (
        <div className='rapportsVisitsContainer'>
            {
                VisitsSelector(visits).map((visit) => {
                    return <VisitCheckbox key={visit.id} visit={visit} />
                })
            }
        </div>
    )
}

export default VisitsCheckbox
import { call, put } from "redux-saga/effects";
import * as types from "../actions";
import { getMe, updateMe } from "../services/Api";
import { makeApi } from "../services/ApiSaga";

export function* meSaga() {
  try {
    const response = yield call(makeApi, getMe);
    yield put({ type: types.GET_ME_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.GET_ME_ERROR, payload: error.message });
  }
}

export function* updateMeSaga(action) {
  const { payload } = action;
  try {
    const response = yield call(makeApi, updateMe, payload);
    yield put({ type: types.UPDATE_ME_SUCCESS, payload: response });
  } catch (error) {
    yield put({ type: types.UPDATE_ME_ERROR, payload: error.message });
  }
}

import { useField } from 'formik';
import React from 'react';

const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
};

const TextAreaField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props)
    return (
        <div className="form-group" style={{ marginBottom: "5px" }}>
            <label style={{ fontSize: "11px" }} className={(meta.touched && meta.error) && 'text-danger'}>
                {label}
                {meta.touched && ((meta.error && <span className="text-danger">&nbsp; -- &nbsp;{meta.error}</span>))}

            </label>

            <textarea
                onInput={toInputUppercase}
                label={label}
                variant="outlined"
                className={"form-control form-control-sm " + ((meta.touched && meta.error) && "is-invalid")}
                placeholder={label}
                style={{ width: '100%', height: '70px' }}
                error={meta.touched && meta.error}
                {...field}
            />
        </div>
    )
}

export default TextAreaField
import { flashErrorMessage, flashMessage } from 'redux-flash'
import { call, put, select } from 'redux-saga/effects'
import * as types from '../actions'
import { OfficeError, OfficeSuccess } from '../actions/OfficesActions'
import { getOffices } from '../services/Api'
import { makeApi } from '../services/ApiSaga'

export function* doFetchOffices() {
    try {
        const rsp = yield call(makeApi, getOffices)
        yield put(OfficeSuccess(rsp))
    } catch (e) {
        yield put(OfficeError())
    }
}
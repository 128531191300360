import { createReducer } from 'reduxsauce'
import { createSelector } from "reselect";
import * as types from '../actions'

const initialValues = {
  payload: {},
  fetching: false,
  error: null
}

export const isReadOnlySelector = createSelector(
  (state) => state.me.payload,
  (currentUser) => {
    return currentUser.role == 'read_only'
  }
);

const doUserFetching = (state) => {
  return {
    ...state,
    fetching: true,
    error: null
  }
}

const doUserSuccess = (state, action) => {
  const { payload } = action
  return {
    ...state,
    fetching: false,
    payload: payload
  }
}

const doUserError = (state, action) => {
  const { payload } = action
  return {
    ...state,
    fetching: false,
    error: payload
  }
}


const doUserUpdateRequest = (state, action) => {
  return {
    ...state,
    fetching: true,
    error: null
  }
}

const doUserUpdateSuccess = (state, action) => {
  const { payload } = action
  return {
    ...state,
    fetching: false,
    payload: payload
  }
}

const doUserUpdateError = (state, action) => {
  const { payload } = action
  return {
    ...state,
    fetching: false,
    error: payload
  }
}


export default createReducer(initialValues, {
  [types.GET_ME]: doUserFetching,
  [types.GET_ME_SUCCESS]: doUserSuccess,
  [types.GET_ME_ERROR]: doUserError,
  [types.UPDATE_ME]: doUserUpdateRequest,
  [types.UPDATE_ME_SUCCESS]: doUserUpdateSuccess,
  [types.UPDATE_ME_ERROR]: doUserUpdateError
})
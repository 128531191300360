import * as R from 'ramda'
import { createReducer } from 'reduxsauce'
import {
    DELETE_P2_REPORTS,

    DELETE_P2_REPORTS_ERROR, DELETE_P2_REPORTS_SUCCESS, GET_P2_REPORTS,

    GET_P2_REPORTS_ERROR, GET_P2_REPORTS_SUCCESS,



    POST_P2_REPORTS, POST_P2_REPORTS_ERROR,
    POST_P2_REPORTS_SUCCESS
} from '../actions'


const INITIAL_STATE = {
    payload: [],
    fetching: true,
    error: null,
    fetchingNew: false,
    fetchingDeletion: false
}


const getP2ReportRequest = (state) => {
    return {
        ...state,
        fetching: true
    }
}

const getP2ReportSuccess = (state, actions) => {
    return {
        ...state,
        fetching: false,
        payload: actions.payload
    }
}

const getP2ReportError = (state, actions) => {
    return {
        ...state,
        fetching: false
    }
}

const postP2ReportRequest = (state, actions) => {
    return {
        ...state,
        fetchingNew: true
    }
}

const postP2ReportSuccess = (state, actions) => {
    return {
        ...state,
        fetchingNew: false,
        payload: [actions.payload, ...state.payload]
    }
}

const postP2ReportError = (state, actions) => {
    return {
        ...state,
        fetchingNew: false
    }
}

const deleteP2ReportRequest = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: true
    }
}

const deleteP2ReportSuccess = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: false,
        payload: R.reject(R.propEq('id', actions.payload), state.payload)
    }
}

const deleteP2ReportError = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: false
    }
}

const HANDLERS = {
    [GET_P2_REPORTS]: getP2ReportRequest,
    [GET_P2_REPORTS_SUCCESS]: getP2ReportSuccess,
    [GET_P2_REPORTS_ERROR]: getP2ReportError,
    [POST_P2_REPORTS]: postP2ReportRequest,
    [POST_P2_REPORTS_ERROR]: postP2ReportError,
    [POST_P2_REPORTS_SUCCESS]: postP2ReportSuccess,
    [DELETE_P2_REPORTS_ERROR]: deleteP2ReportError,
    [DELETE_P2_REPORTS]: deleteP2ReportRequest,
    [DELETE_P2_REPORTS_SUCCESS]: deleteP2ReportSuccess
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)
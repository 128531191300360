import * as R from 'ramda'
import { createSelector } from 'reselect'
import * as types from '../actions'

// Selectors

export const productTypesSelector = state => state.product_types

export const productTypesFilterSelector = (category_id, trademark_id) => {
  return createSelector(
    productTypesSelector,
    product_types => R.compose(
      R.filter((item) => {
        if (R.has('category_ids', item) && R.has('trademark_ids', item)) {
          return item.category_ids.includes(category_id) && item.trademark_ids.includes(trademark_id)
        }
        return false
      }),
      R.values
    )(product_types)
  )
}

// Reducers
export default function (state = [], action) {
  const response = action.response
  switch (action.type) {
    case types.GET_PRODUCT_TYPES:
      return {
        ...state,
        meta: {
          category_id: action.category_id,
          trademark_id: action.trademark_id
        }
      }
    case types.GET_PRODUCT_TYPES_SUCCESS:
      return R.mergeRight(state, R.reduce((prev, current) => {
        prev[current.id] = current
        return prev;
      }, {}, response))
    case types.GET_PRODUCT_TYPES_ERROR:
      return state
    case types.RESET_PRODUCT_TYPES:
      return state
    case types.SET_PRODUCT_TYPES_FROM_SYNC:
      return R.mergeRight(state, action.payload)
    case types.SET_PRODUCT_TYPES_FROM_SYNC_DELETION:
      return R.omit(action.payload, state)
    case types.PRODUCT_ADD_REQUEST:
      const { payload: { product_type, trademark }, meta: { category_id } } = action
      if (R.has(product_type.id, state)) {
        const prevProductType = R.prop(product_type.id, state)
        const _product_type = {
          ...prevProductType,
          category_ids: R.uniq([...prevProductType.category_ids, category_id]),
          trademark_ids: R.uniq([...prevProductType.trademark_ids, trademark.id])
        }
        return R.mergeRight(state, { [product_type.id]: _product_type })
      } else {
        return R.mergeRight(state, {
          [product_type.id]: {
            ...product_type,
            category_ids: [category_id],
            trademark_ids: [trademark.id]
          }
        })
      }
    default:
      return state
  }
}

import { flashMessage } from "redux-flash"
import { call, put } from 'redux-saga/effects'
import { deleteImageBankError, deleteImageBankSuccess, getImageBanksError, getImageBanksSuccess, postImageBankError, postImageBankSuccess } from '../actions/ImageBanksAction'
import { deleteImageBankApi, getImageBanksApi, postImageBankApi, getImageBankApi, getPublicImageBankApi } from '../services/Api'
import { getCookie } from '../utils/Cookies'
import history from '../services/History'
import * as types from '../actions'
import { makeApi } from '../services/ApiSaga'

export function* getImageBanksSaga() {
    try {
        const data = yield call(getImageBanksApi, getCookie('access_token'))
        yield put(getImageBanksSuccess(data))
    } catch (e) {
        yield put(getImageBanksError())
    }
}

export function* getImageBankSaga(action) {
    const { payload: { id } } = action
    try {
        const rsp = yield call(makeApi, getImageBankApi, id)
        yield put({ type: types.GET_IMAGE_BANK_SUCCESS, payload: rsp })

    } catch (error) {
        yield put({ type: types.GET_IMAGE_BANK_ERROR, payload: error.message })
    }
}

export function* getPublicImageBankSaga(action) {
    const { payload: { id } } = action
    try {
        const rsp = yield call(makeApi, getPublicImageBankApi, id)
        yield put({ type: types.GET_PUBLIC_IMAGE_BANK_SUCCESS, payload: rsp })

    } catch (error) {
        yield put({ type: types.GET_PUBLIC_IMAGE_BANK_ERROR, payload: error.message })
    }
}

export function* postImageBankSaga(actions) {
    try {
        const data = yield call(postImageBankApi, actions.payload, getCookie('access_token'))
        yield put(postImageBankSuccess(data))
        history.replace('/banque-images')
        yield put(flashMessage('Elément sauvegardé'))
    } catch (e) {
        yield put(flashMessage('Une erreur est survenu durant la sauvegarde.'))
        yield put(postImageBankError())
    }
}

export function* deleteImageBankSaga(actions) {
    try {
        yield call(deleteImageBankApi, actions.payload, getCookie('access_token'))
        yield put(deleteImageBankSuccess(actions.payload))
        yield put(flashMessage('Elément supprimé'))
    } catch (e) {
        yield put(deleteImageBankError())
    }
}
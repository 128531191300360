import * as types from './index'

export const loginUserAction = (email, password, formikCtx, from = null) => {
  return {
    type: types.LOGIN_USER,
    payload: { email, password },
    meta: { formik: formikCtx, from }
  }
}

export const loginUserSuccessAction = () => {
  return {
    type: types.LOGIN_USER_SUCCESS
  }
}

export const loginUserErrorAction = (message) => {
  return {
    type: types.LOGIN_USER_ERROR,
    payload: message
  }
}

export const logoutAction = () => {
  return {
    type: types.LOGOUT_REQUEST
  }
}
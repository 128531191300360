import { call, put } from 'redux-saga/effects'
import * as types from '../actions'
import { getEnergyTypes } from '../services/Api'


export function* energyTypesSaga(payload) {
  try {
    const response = yield call(getEnergyTypes, payload)
    yield put({ type: types.GET_ENERGY_TYPES_SUCCESS, response })

  } catch (error) {
    yield put({ type: types.GET_ENERGY_TYPES_ERROR, error })
  }
}

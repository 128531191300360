import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import * as R from "ramda";
import CategoryBox from "../../components/CategoryBox";
import TrademarkBox from "../../components/TrademarkBox";
import CreateEquipment from "./CreateEquipment";
import { useWatch } from "react-hook-form";

const CategoriesSelector = ({ category_id, index, onPress, control }) => {
  const category = useSelector((state) => state.category[category_id]);
  const children = useMemo(() => {
    if (!category) return [];
    return R.compose(R.sort(R.compose(R.toLower, R.prop("name"))))(
      category.children || []
    );
  }, [category]);

  const productVisits = useWatch({
    name: "products_visits_attributes",
    control: control,
  });

  const trademarks = useMemo(() => {
    if (!category) return [];
    return R.compose(R.sort(R.compose(R.toLower, R.prop("name"))))(
      category.trademarks || []
    );
  }, [category]);

  return (
    <div index={index} count={children.length}>
      <Box display="flex" flexDirection="column" justifyContent="center" p={2}>
        {children.length <= 0 && <CreateEquipment category_id={category_id} />}
        {children.map((child) => {
          const badge = productVisits.filter((x) =>
            x.category_ids.includes(child.id)
          ).length;
          return (
            <CategoryBox
              key={child.id}
              count={badge}
              onPress={() =>
                onPress({ type: "CATEGORY", object_id: child.id, index })
              }
              category={child}
              size="small"
              color={child.color}
            />
          );
        })}
        {trademarks.map((child) => {
          const badge = productVisits.filter(
            (x) => x.product.trademark_id === child.id
          ).length;
          return (
            <TrademarkBox
              key={child.id}
              count={badge}
              trademark={child}
              onPress={() =>
                onPress({ type: "TRADEMARK", object_id: child.id, index })
              }
            />
          );
        })}
      </Box>
    </div>
  );
};

export default CategoriesSelector;

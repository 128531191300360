import * as R from 'ramda'
import { createReducer } from 'reduxsauce'
import {
    DELETE_RAPPORTS,

    DELETE_RAPPORTS_ERROR, DELETE_RAPPORTS_SUCCESS, GET_RAPPORTS,

    GET_RAPPORTS_ERROR, GET_RAPPORTS_SUCCESS,



    POST_RAPPORTS, POST_RAPPORTS_ERROR,
    POST_RAPPORTS_SUCCESS
} from '../actions'


const INITIAL_STATE = {
    payload: [],
    fetching: true,
    error: null,
    fetchingNew: false,
    fetchingDeletion: false
}


const getRapportRequest = (state) => {
    return {
        ...state,
        fetching: true
    }
}

const getRapportSuccess = (state, actions) => {
    return {
        ...state,
        fetching: false,
        payload: actions.payload
    }
}

const getRapportError = (state, actions) => {
    return {
        ...state,
        fetching: false
    }
}

const postRapportRequest = (state, actions) => {
    return {
        ...state,
        fetchingNew: true
    }
}

const postRapportSuccess = (state, actions) => {
    return {
        ...state,
        fetchingNew: false,
        payload: [actions.payload, ...state.payload]
    }
}

const postRapportError = (state, actions) => {
    return {
        ...state,
        fetchingNew: false
    }
}

const deleteRapportRequest = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: true
    }
}

const deleteRapportSuccess = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: false,
        payload: R.reject(R.propEq('id', actions.payload), state.payload)
    }
}

const deleteRapportError = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: false
    }
}

const HANDLERS = {
    [GET_RAPPORTS]: getRapportRequest,
    [GET_RAPPORTS_SUCCESS]: getRapportSuccess,
    [GET_RAPPORTS_ERROR]: getRapportError,
    [POST_RAPPORTS]: postRapportRequest,
    [POST_RAPPORTS_ERROR]: postRapportError,
    [POST_RAPPORTS_SUCCESS]: postRapportSuccess,
    [DELETE_RAPPORTS_ERROR]: deleteRapportError,
    [DELETE_RAPPORTS]: deleteRapportRequest,
    [DELETE_RAPPORTS_SUCCESS]: deleteRapportSuccess
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)
import Badge from '@mui/material/Badge'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
// UI Elements
import Typography from '@mui/material/Typography'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Allotment.module.css'

function Allotment(props) {

  const { onDestroy } = props
  const edit = `/customers/${props.data.customer_id}/lots/${props.data.id}/edit`
  const locations = `/customers/${props.data.customer_id}/lots/${props.data.id}/locations`

  return (
    <Card className={styles.card} >
      <CardContent>
        <Typography variant="h6">
          {props.data.name}
        </Typography>
      </CardContent>
      <CardActions>
        {
          !props.readonly &&
          <>
            <button className="btn btn-xs btn-danger" onClick={() => onDestroy(props.data)}>
              Supprimer
            </button>
            <Link className="btn btn-xs btn-warning ml-2" to={edit}>
              Modifier
            </Link>
            <a href="#" className="btn btn-xs btn-info ml-2" onClick={(e) => {
              e.preventDefault()
              props.onClone(props.data)
            }}>
              Dupliquer
            </a>
          </>
        }
        <Badge className='ml-2' color="secondary" badgeContent={props.data.locations_counter}>
          <Link className="btn btn-xs btn-success" to={locations}>
            Sites
          </Link>
        </Badge>
      </CardActions>
    </Card>
  )
}

export default Allotment

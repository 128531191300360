import Config from "../config/Config";
import * as types from "./index";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export const allotmentsAction = (customer_id, load_location = false) => {
  return {
    type: types.GET_ALLOTMENTS,
    payload: {
      customer_id,
      load_location,
    },
  };
};

export const allotmentsActionSuccess = (customer_id, payload) => {
  return {
    type: types.GET_ALLOTMENTS_SUCCESS,
    payload,
    meta: { customer_id },
  };
};

export const allotmentsActionError = (customer_id, payload) => {
  return {
    type: types.GET_ALLOTMENTS_ERROR,
    payload,
    meta: { customer_id },
  };
};

export const createAllotmentAction = (customer_id, payload) => {
  const internalUUID = uuidv4();
  return {
    type: types.CREATE_ALLOTMENT,
    payload,
    meta: {
      customer_id,
      id: payload.id,
      version: internalUUID,
      createdAt: new Date().toISOString(),
      offline: {
        effect: {
          url: `/api/v1/customers/${customer_id}/allotments`,
          method: "POST",
          json: payload,
          headers: {
            "Cache-Control": "no-cache",
          },
        },
        commit: {
          type: types.CREATE_ALLOTMENT_SUCCESS,
          meta: {
            customer_id,
            version: internalUUID,
          },
        },
        rollback: {
          type: types.CREATE_ALLOTMENT_ERROR,
          meta: {
            version: internalUUID,
            customer_id,
          },
        },
      },
    },
  };
};

export const getAllotmentAction = (customer_id, id) => {
  return {
    type: types.GET_ALLOTMENT,
    payload: { customer_id, id },
  };
};

export const getAllotmentActionSuccess = (customer_id, id, payload) => {
  return {
    type: types.GET_ALLOTMENT_SUCCESS,
    payload,
    meta: { customer_id, id },
  };
};

export const getAllotmentActionError = (customer_id, id, payload) => {
  return {
    type: types.GET_ALLOTMENT_ERROR,
    payload,
    meta: { customer_id, id },
  };
};

export const updateAllotmentAction = (customer_id, id, payload) => {
  const internalUUID = uuidv4();

  return {
    type: types.UPDATE_ALLOTMENT,
    payload: payload,
    meta: {
      id: id,
      customer_id,
      version: internalUUID,
      createdAt: new Date().toISOString(),
      offline: {
        effect: {
          url: `/api/v1/allotments/${id}`,
          method: "PATCH",
          json: payload,
          headers: {
            "Cache-Control": "no-cache",
          },
        },
        commit: {
          type: types.UPDATE_ALLOTMENT_SUCCESS,
          meta: {
            customer_id,
            id,
            version: internalUUID,
          },
        },
        rollback: {
          type: types.UPDATE_ALLOTMENT_ERROR,
          meta: {
            version: internalUUID,
            customer_id,
            id,
          },
        },
      },
    },
  };
};

export const cloneAllotmentAction = (customer_id, id) => {
  return {
    type: types.CLONE_ALLOTMENT,
    payload: { id },
    meta: { customer_id },
  };
};

export const cloneAllotmentSuccessAction = (customer_id, id, payload) => {
  return {
    type: types.CLONE_ALLOTMENT_SUCCESS,
    payload: payload,
    meta: { customer_id, id },
  };
};

export const cloneAllotmentErrorAction = (customer_id, id, payload) => {
  return {
    type: types.CLONE_ALLOTMENT_ERROR,
    payload,
    meta: { customer_id, id },
  };
};

export const destroyAllotmentAction = (customer_id, id) => {
  return {
    type: types.DESTROY_ALLOTMENT,
    payload: { customer_id, id },
  };
};

export const destroyAllotmentActionSuccess = (customer_id, id) => {
  return {
    type: types.DESTROY_ALLOTMENT_SUCCESS,
    payload: { customer_id, id },
  };
};

export const destroyAllotmentActionError = (customer_id, id) => {
  return {
    type: types.DESTROY_ALLOTMENT_ERROR,
    payload: { customer_id, id },
  };
};

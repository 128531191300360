import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import FuseJS from "fuse.js";
import * as R from "ramda";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { customerAction } from "../actions/CustomersActions";
//Actions
import {
  cloneLocationAction,
  destroyLocationAction,
  locationsAction,
} from "../actions/LocationsActions";
import { confirm } from "../components/Confirms/ConfirmDestroy";
import Location from "../components/Location";
// UI Elements
import ScreenTitle from "../components/ScreenTitle";
import { buildLocationsKey } from "../reducers/LocationsReducer";
import { useNavigate, useParams } from "react-router";
import { isReadOnlySelector } from "../reducers/MeReducer";

const searchOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: false,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["label", "street", "zip", "city", "year", "comment"],
};

const selectEntities = (state) => state.locations.entities;
const selectEntitiesIds = (key) => (state) => {
  const { location_ids = [], waitForSync = [] } =
    state.locations.ids[key] || {};
  return R.uniq([...location_ids, ...waitForSync]);
};

const selectLocations = (customer_id, allotment_id) =>
  createSelector(
    selectEntitiesIds(buildLocationsKey(customer_id, allotment_id)),
    selectEntities,
    (ids, entities) => {
      return R.compose(
        sortByNameCaseInsensitive,
        R.filter(
          (x) =>
            x.customer_id === customer_id && x.allotment_id === allotment_id
        ),
        R.map((x) => entities[x])
      )(ids);
    }
  );

const sortByNameCaseInsensitive = R.sortBy(
  R.compose(R.toLower, R.prop("label"))
);

const LocationsContainer = ({}) => {
  const fuseJSRef = useRef(new FuseJS([], searchOptions));
  const navigation = useNavigate();
  const { customer_id, allotment_id = null } = useParams();

  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(locationsAction(customer_id, allotment_id));
    dispatch(customerAction(customer_id));
  }, []);
  const isReadOnly = useSelector(isReadOnlySelector)

  const customer = useSelector(
    (state) => state.customers.entities[customer_id]
  );
  const loading = useSelector((state) => state.locations.fetching);

  const entities = useSelector(selectLocations(customer_id, allotment_id));

  const allotment = useSelector(
    (state) => state.allotments.entities[allotment_id] || {}
  );

  useEffect(() => {
    fuseJSRef.current.setCollection(entities);
  }, [entities]);

  const locations = useMemo(() => {
    if (search === "") return entities;
    const filters = fuseJSRef.current.search(search);
    return R.map((x) => x.item, filters);
  }, [search, entities]);

  const onClone = useCallback((location) => {
    const { id } = location;
    dispatch(cloneLocationAction(id, customer_id, allotment_id));
  }, []);

  const onDestroy = useCallback(async (location) => {
    const { id } = location;
    if (
      await confirm(`Êtes-vous sur de vouloir supprimer ${location.label} ?`)
    ) {
      dispatch(destroyLocationAction(customer_id, allotment_id, id));
    }
  }, []);

  const title = useMemo(() => {
    if (!customer) return "Sites";
    if (allotment_id && allotment)
      return `Sites - ${customer.name} - ${allotment.name}`;
    return `Sites - ${customer.name}`;
  }, [customer]);

  const urlNewLocation = useMemo(() => {
    if (allotment_id) {
      return `/customers/${customer_id}/lots/${allotment_id}/locations_new`;
    }
    return `/customers/${customer_id}/locations_new`;
  }, []);

  const urlBackLocation = useMemo(() => {
    if (allotment_id) {
      return `/customers/${customer_id}/lots`;
    }
    return "/dashboard";
  });

  return (
    <div>
      <ScreenTitle
        loading={loading}
        onSearch={setSearch}
        back={true}
        button="Retour"
        href={urlBackLocation}
        title={title}
      />
      <Grid container spacing={1}>
        {locations.map((location) => (
          <Grid key={location.id} item xs={12} sm={12} md={6} lg={4}>
            <Location
              readonly={isReadOnly}
              onClone={onClone}
              onDestroyLocation={onDestroy}
              data={location}
              size={6}
            />
          </Grid>
        ))}
      </Grid>
      {
        !isReadOnly &&
        <Fab
          onClick={() => {
            navigation(urlNewLocation);
          }}
          color="primary"
          aria-label="add"
          className="fab-add"
        >
          <AddIcon />
        </Fab>
      }
    </div>
  );
};

export default LocationsContainer;

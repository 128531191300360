import {
  Button,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FuseJS from "fuse.js";
import moment from "moment";
import * as R from "ramda";
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteImageBank, getImageBanks } from "../actions/ImageBanksAction";
import { confirm } from "../components/Confirms/ConfirmDestroy";
import ScreenTitle from "../components/ScreenTitle";
import { useNavigate } from "react-router";
import { flashMessage } from "redux-flash";
import { isReadOnlySelector } from "../reducers/MeReducer";

const searchOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: false,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["user_fullname", "title"],
};

const ImageBankContainer = ({}) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const fuseJSRef = useRef(new FuseJS([], searchOptions));
  const [search, setSearch] = useState("");
  const isReadOnly = useSelector(isReadOnlySelector)

  useEffect(() => {
    dispatch(getImageBanks());
  }, []);

  const image_banks = useSelector((state) => {
    return Object.values(state.image_banks.entities);
  });

  const onDestroy = useCallback(async (item) => {
    if (await confirm(`Êtes-vous sûr de vouloir supprimer ce image_bank ?`)) {
      dispatch(deleteImageBank(item.id));
    }
  }, []);

  useEffect(() => {
    dispatch(getImageBanks());
  }, []);

  useEffect(() => {
    fuseJSRef.current.setCollection(image_banks);
  }, [image_banks]);

  const customersFiltered = useMemo(() => {
    if (search === "") return image_banks;
    const filters = fuseJSRef.current.search(search);
    return R.map((x) => x.item, filters);
  }, [search, image_banks]);

  const onSearch = useCallback(
    (_value = "") => {
      setSearch(_value);
    },
    [image_banks]
  );

  const onCopy = useCallback((st) => {
    navigator.clipboard.writeText(window.location.protocol + "//" + window.location.host + st);
    dispatch(flashMessage("La donnée a été copiée."));
  });

  return (
    <div>
      <ScreenTitle onSearch={onSearch} title="Banque d'images" />
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Généré par</TableCell>
              <TableCell>Date de création</TableCell>
              <TableCell>Date d'expiration</TableCell>
              <TableCell size="small" align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {R.map((v, i) => {
              return (
                <TableRow key={v.id}>
                  <TableCell>{v.title}</TableCell>
                  <TableCell>{v.user_fullname}</TableCell>
                  <TableCell>
                    {moment(v.created_at).format("DD-MM-YYYY HH:mm")}
                  </TableCell>
                  <TableCell>
                    {v.expire_date &&
                      moment(v.expire_date).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell size="small" align="right">
                    <Button
                      href={v.file}
                      target="_blank"
                      variant="contained"
                      color="primary"
                    >
                      Télécharger
                    </Button>
                    &nbsp;
                    <Button
                      onClick={() =>
                        navigation(`/banque-images-publique/${v.id}`)
                      }
                      variant="contained"
                      color="primary"
                    >
                      Visualiser
                    </Button>
                    &nbsp;
                    <Button onClick={() => onCopy(`/banque-images-publique/${v.id}`)} color="warning" variant="contained">
                      Copier le lien
                    </Button>
                    &nbsp;
                    {
                      !isReadOnly &&
                      <>
                        <Button
                          onClick={() => navigation(`/banque-images/${v.id}/edit`)}
                          variant="contained"
                          color="primary"
                        >
                          Editer
                        </Button>
                        &nbsp;
                        <Button
                          onClick={() => onDestroy(v)}
                          variant="contained"
                          color="secondary"
                        >
                          Supprimer
                        </Button>
                      </>
                    }
                  </TableCell>
                </TableRow>
              );
            }, customersFiltered)}
          </TableBody>
        </Table>
      </Paper>
      <Fab
        onClick={() => navigation("/banque-images/create")}
        color="primary"
        aria-label="add"
        className="fab-add"
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default ImageBankContainer;

import * as types from './index'

export const productsAction = (token, category_id, product_type_id, trademark_id = '') => {
  return {
    type: types.GET_PRODUCTS,
    token,
    category_id,
    product_type_id,
    trademark_id
  }
}

export const resetProductsAction = () => {
  return {
    type: types.RESET_PRODUCTS
  }
}

export const setProductFromSync = (payload) => {
  return {
    type: types.SET_PRODUCT_FROM_SYNC,
    payload
  }
}

export const setProductFromSyncDeletion = (payload) => {
  return {
    type: types.SET_PRODUCT_FROM_SYNC_DELETION,
    payload
  }
}
import { Button, Grid } from '@mui/material'
import { FieldArray, Form } from 'formik'
import React from 'react'
import ImagesField from '../../components/forms/Formik/ImagesField'
import PanoramicsField from '../../components/forms/Formik/PanoramicsField'
import TextField from '../../components/forms/Formik/TextField'

const BuildingForm = ({ isValid, readonly }) => {
    return (
        <Form autoComplete="off">
            <Grid container justify="center" spacing={0}>
                <Grid item xs={12}>
                    <TextField name='name' label={'Libellé'} />
                </Grid>
                <Grid item xs={12}>
                    <FieldArray
                        name='images_attributes'
                        component={ImagesField}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldArray
                        name='panoramics_attributes'
                        component={PanoramicsField}
                    />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    {
                        !readonly &&
                        <Button variant="contained" color={isValid ? 'primary' : 'secondary'} type="submit">
                            Sauvegarder
                        </Button>
                    }
                </Grid>
            </Grid>
        </Form>
    )
}

export default BuildingForm
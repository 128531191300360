import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { Form } from 'formik'
import React from 'react'
import FileField from '../../components/forms/Formik/FileField'
import DateField from '../../components/forms/Formik/DateField'
import TextField from '../../components/forms/Formik/TextField'

const CustomerForm = ({ btnTitle, isValid = true }) => {
  return (
    <Form autoComplete="off"    >
      <Grid container justify="center" spacing={0}>
        <Grid item xs={12}>
            <TextField name='title' label="Titre de l'image" />
        </Grid>
        <Grid item xs={12}>
          <DateField name='expire_date' label="Date d'expiration" />
        </Grid>
        <Grid item xs={12}>
          <FileField name='file' label="Image" />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button variant="contained" color={isValid ? 'primary' : 'secondary'} type="submit">{btnTitle}</Button>
        </Grid>
      </Grid>
    </Form>
  )
}

export default CustomerForm
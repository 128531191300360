import React from "react";
import { connect, useSelector } from "react-redux";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import Redirect from "../components/Redirect";
import CreateAllotmentContainer from "../containers/Allotments/CreateAllotmentContainer";
import UpdateAllotmentContainer from "../containers/Allotments/UpdateAllotmentContainer";
import AllotmentsContainer from "../containers/AllotmentsContainer";
import CreateCustomerContainer from "../containers/Customers/CreateCustomerContainer";
import UpdateCustomerContainer from "../containers/Customers/UpdateCustomerContainer";
import DashboardContainer from "../containers/DashboardContainer";
// containers
import Layout from "../containers/LayoutContainer";
import CreateLocationContainer from "../containers/Locations/CreateLocationContainer";
import UpdateLocationContainer from "../containers/Locations/UpdateLocationContainer";
import LocationsContainer from "../containers/LocationsContainer";
import LoginContainer from "../containers/LoginContainer";
import ProfileContainer from "../containers/ProfileContainer";
import RapportContainer from "../containers/RapportContainer";
import ImageBankContainer from "../containers/ImageBankContainer";
import ImageBankCreateContainer from "../containers/ImageBanks/CreateContainer";
import ImageBankUpdateContainer from "../containers/ImageBanks/UpdateContainer";
import ImageBankShowContainer from "../containers/ImageBanks/ShowContainer";
import CompareRapportContainer from "../containers/CompareRapportContainer";
import P2ReportContainer from "../containers/P2ReportContainer";
import P3ReportContainer from "../containers/P3ReportContainer";
import RapportCreateContainer from "../containers/Rapports/CreateContainer";
import CompareRapportCreateContainer from "../containers/CompareRapports/CreateContainer";
import P2ReportCreateContainer from "../containers/P2Reports/CreateContainer";
import P3ReportCreateContainer from "../containers/P3Reports/CreateContainer";
import RouterVisitContainer from "../containers/RouterVisitContainer";
import CreateVisitContainer from "../containers/Visits/Resources/CreateVisitContainer";
import VisitsContainer from "../containers/VisitsContainer";
import ImagesLocalContainer from "../containers/Images/ImagesLocalContainer";
import LocalLogsContainer from "../containers/LocalLogsContainer";
import CustomRouter from "../components/CustomRouter";

import BuildingCreateContainer from "../containers/Buildings/CreateContainer";
import BuildingUpdateContainer from "../containers/Buildings/EditContainer";
import BuildingsContainer from "../containers/BuildingsContainer";
import ProductVisitContainer from "../containers/ProductVisitContainer";
import UpdateVisitContainer from "../containers/Visits/Resources/UpdateVisitContainer";
import OfflineList from "../containers/OfflineList";

const AppRoutes = () => {
  return (
    <CustomRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route exact path="/connexion" element={<LoginContainer />} />
          <Route exact path="/" element={<Redirect to={"/dashboard"} />} />

          <Route
            exact
            path="/banque-images-publique/:id"
            element={<ImageBankShowContainer />}
          />
          <Route
            exact
            path="/dashboard"
            element={
              <RequiredAuth>
                <DashboardContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <RequiredAuth>
                <ProfileContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/local_images"
            element={
              <RequiredAuth>
                <ImagesLocalContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/local_logs"
            element={
              <RequiredAuth>
                <LocalLogsContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/offlines"
            element={
              <RequiredAuth>
                <OfflineList />
              </RequiredAuth>
            }
          />

          <Route
            exact
            path="/banque-images"
            element={
              <RequiredAuth>
                <ImageBankContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/banque-images/create"
            element={
              <RequiredAuth>
                <ImageBankCreateContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/banque-images/:id/edit"
            element={
              <RequiredAuth>
                <ImageBankUpdateContainer />
              </RequiredAuth>
            }
          />

          <Route
            exact
            path="/rapports"
            element={
              <RequiredAuth>
                <RapportContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/rapports/create"
            element={
              <RequiredAuth>
                <RapportCreateContainer />
              </RequiredAuth>
            }
          />

          <Route
            exact
            path="/comparaison_rapports"
            element={
              <RequiredAuth>
                <CompareRapportContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/comparaison_rapports/create"
            element={
              <RequiredAuth>
                <CompareRapportCreateContainer />
              </RequiredAuth>
            }
          />

          <Route
            exact
            path="/chiffrages-p2"
            element={
              <RequiredAuth>
                <P2ReportContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/chiffrages-p2/create"
            element={
              <RequiredAuth>
                <P2ReportCreateContainer />
              </RequiredAuth>
            }
          />

          <Route
            exact
            path="/points-p3"
            element={
              <RequiredAuth>
                <P3ReportContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/points-p3/create"
            element={
              <RequiredAuth>
                <P3ReportCreateContainer />
              </RequiredAuth>
            }
          />

          <Route
            exact
            path="/customers_new"
            element={
              <RequiredAuth>
                <CreateCustomerContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/customers/:id/edit"
            element={
              <RequiredAuth>
                <UpdateCustomerContainer />
              </RequiredAuth>
            }
          />

          <Route
            exact
            path="/customers/:customer_id/locations"
            element={
              <RequiredAuth>
                <LocationsContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/customers/:customer_id/locations_new"
            element={
              <RequiredAuth>
                <CreateLocationContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/customers/:customer_id/locations/:id/edit"
            element={
              <RequiredAuth>
                <UpdateLocationContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/customers/:customer_id/locations/:location_id/visits"
            element={
              <RequiredAuth>
                <VisitsContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/customers/:customer_id/locations/:location_id/visits/new"
            element={
              <RequiredAuth>
                <CreateVisitContainer />
              </RequiredAuth>
            }
          />

          <Route
            exact
            path="/customers/:customer_id/lots"
            element={
              <RequiredAuth>
                <AllotmentsContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/customers/:customer_id/lots_new"
            element={
              <RequiredAuth>
                <CreateAllotmentContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/customers/:customer_id/lots/:id/edit"
            element={
              <RequiredAuth>
                <UpdateAllotmentContainer />
              </RequiredAuth>
            }
          />

          <Route
            exact
            path="/customers/:customer_id/lots/:allotment_id/locations"
            element={
              <RequiredAuth>
                <LocationsContainer />
              </RequiredAuth>
            }
          />

          <Route
            exact
            path="/customers/:customer_id/lots/:allotment_id/locations_new"
            element={
              <RequiredAuth>
                <CreateLocationContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/customers/:customer_id/lots/:allotment_id/locations/:id/edit"
            element={
              <RequiredAuth>
                <UpdateLocationContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/customers/:customer_id/lots/:allotment_id/locations/:location_id/visits"
            element={
              <RequiredAuth>
                <VisitsContainer />
              </RequiredAuth>
            }
          />
          <Route
            exact
            path="/customers/:customer_id/lots/:allotment_id/locations/:location_id/visits/new"
            element={
              <RequiredAuth>
                <CreateVisitContainer />
              </RequiredAuth>
            }
          />

          <Route
            path="/visites/:id"
            element={
              <RequiredAuth>
                <RouterVisitContainer />
              </RequiredAuth>
            }
          >
            <Route path={`edition`} element={<UpdateVisitContainer />} />
            <Route
              path={`categorie/:category_id/batiments`}
              element={<BuildingsContainer />}
            />
            <Route
              path={`categorie/:category_id/batiments/create`}
              element={<BuildingCreateContainer />}
            />
            <Route
              path={`batiments/:building_id/edition`}
              element={<BuildingUpdateContainer />}
            />
            <Route
              path={`batiments/:building_id/visits`}
              element={<ProductVisitContainer />}
            />
          </Route>
        </Route>
      </Routes>
    </CustomRouter>
  );
};

export const RequiredAuth = ({ children }) => {
  const isConnected = useSelector((state) => state.login.isAuthentified);
  const location = useLocation();

  if (!isConnected) {
    return <Redirect to={"/connexion"} state={{ from: location.pathname }} />;
  }
  return children;
};

export default AppRoutes;

import { Grid } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { isEmpty } from 'ramda'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { getFile } from '../../../services/StoreFile'
import { renameFile } from '../../../utils/JsonTools'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { isReadOnlySelector } from '../../../reducers/MeReducer'

// Must be in a FieldArray
const deep_value = (o, p) => p.split('.').reduce((a, v) => a[v], o);

const InternalImageField = ({ picture, index, onRemovePicture }) => {
    const [thumb, setThumb] = useState(null)
    const isReadOnly = useSelector(isReadOnlySelector)
    useEffect(() => {
        if (!picture.file || isEmpty(picture.urfilel)) return

        if (picture.file && picture.file instanceof Blob) {
            setThumb(window.URL.createObjectURL(picture.file))
            return
        }

        (async () => {
            const item = await getFile(picture.file)
            if (item) {
                setThumb(item.url)
            }
        })()


    }, [picture])
    return (
        <div style={{ position: 'relative', maxWidth: '200px', maxHeight: '200px' }}>
            <img style={{ maxWidth: '200px', maxHeight: '200px', backgroundColor: 'grey' }} className='img-fluid' src={thumb} />
            {
                !isReadOnly &&
                <div style={{ position: 'absolute', top: 0, left: 0, backgroundColor: '#DC143C', borderBottomRightRadius: '50%' }}>
                    <CloseIcon onClick={() => {
                        onRemovePicture(index, picture)
                    }} color='inherit' />
                </div>
            }
        </div>
    )
}

const PanoramicsField = ({ push, remove, replace, ...props }) => {
    const inputRef = useRef(null)
    const isReadOnly = useSelector(isReadOnlySelector)
    useEffect(() => {
        const input = document.createElement('input')
        input.type = 'file'
        input.onchange = (e) => {
            push({
                id: uuidv4(),
                file: renameFile(e.target.files[0])
            })
            input.value = ''
        }
        inputRef.current = input
        return () => {
            input.onchange = null
        }
    }, [])

    const onClickGalery = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }, [])
    const onRemovePicture = useCallback((index, img) => {
        if (img.file instanceof Blob) {
            remove(index)
        } else {
            replace(index, { ...img, _destroy: true })
        }
    }, [remove, replace])
    const panoramics_attributes = deep_value(props.form.values, props.name)

    return (
        <div>
            {!isReadOnly && <Button variant="secondary" onClick={onClickGalery}>Ajouter un visuel 3D</Button>}
            <Grid container xs={12} justify="left">
                {
                    panoramics_attributes.map((picture, index) => {
                        if (picture._destroy) {
                            return null
                        }
                        return (
                            <InternalImageField key={index} picture={picture} index={index} onRemovePicture={onRemovePicture} />
                        )
                    })
                }
            </Grid>
        </div>
    )
}

export default React.memo(PanoramicsField)
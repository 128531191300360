import * as R from "ramda";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { flashMessage } from "redux-flash";
import { v4 as uuidv4 } from "uuid";
import { updateVisitAction } from "../actions/VisitsActions";
import {
  ProductsVisitsActions,
  getProductsVisitsForBuildingId,
} from "../reducers/ProductsVisitsReducer";
import ScreenTitle from "../components/ScreenTitle";
import { useForm, useFieldArray } from "react-hook-form";
import ProductsViews from "./Visits/ProductsViews";
import { Stack } from "@mui/material";
import ProductVisit from "./Visits/ProductVisit";
import { saveJSONFileInStorage, saveJSONInStorage } from "../utils/JsonTools";
import { confirm } from "../components/Confirms/ConfirmAction";
import { DeepCompare } from "../utils/DeepCompare";
import { isReadOnlySelector } from "../reducers/MeReducer";

const ProductVisitContainer = ({}) => {
  const { id, building_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isReadOnly = useSelector(isReadOnlySelector)

  const visit = useSelector((state) => state.visits.entities[id]);
  const building = useSelector(
    (state) => state.buildings.entities[building_id]
  );
  const category = useSelector((state) => state.category[building.category_id]);
  const productsVisits = useSelector(
    getProductsVisitsForBuildingId(id, building_id)
  );

  const initialValues = useMemo(() => {
    return {
      products_visits_attributes: productsVisits,
    };
  }, [productsVisits]);

  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues: initialValues,
  });

  const { fields, append, update } = useFieldArray({
    control: control,
    name: "products_visits_attributes",
  });

  const title = useMemo(() => {
    let title = "";
    const customer_name = R.path(
      ["location_attributes", "customer", "name"],
      visit
    );
    const location_name = R.path(["location_attributes", "label"], visit);
    const building_name = R.prop("name", building);
    const category_name = R.prop("name", category);

    if (customer_name) {
      title += `${customer_name} - `;
    }
    if (location_name) {
      title += `${location_name} - `;
    }
    if (building_name) {
      title += `${building_name} - `;
    }
    if (category_name) {
      title += `Visite ${category_name}`;
    }
    return title;
  }, [visit]);
  const link = `/visites/${id}/categorie/${building.category_id}/batiments`;

  const onCloneProductVisit = useCallback(
    (values) => {
      const clonedProductVisit = {
        ...values,
        id: uuidv4(),
        images_attributes: [],
      };
      append(clonedProductVisit);
    },
    [append]
  );

  const onDestroyProductVisit = useCallback((index, values) => {
    update(index, {
      ...values,
      _destroy: true,
    });
  }, []);

  const onGoBack = useCallback(async () => {
    if (formState.isDirty) {
      if (
        await confirm(
          "Des éléments dans le formulaire n'ont pas été sauvegardés, êtes-vous sur de vouloir retourner sur la page d'avant ?"
        )
      ) {
        navigate(`/visites/${id}/categorie/${building.category_id}/batiments`);
      }
    } else {
      navigate(`/visites/${id}/categorie/${building.category_id}/batiments`);
    }
  }, [building, formState]);

  const onSubmit = useCallback(
    async (values) => {
      const { products_visits_attributes: oldValues } = initialValues;

      const newValues = await saveJSONFileInStorage(
        values.products_visits_attributes
      );

      await saveJSONInStorage('product_visit', values.products_visits_attributes)

      // Check for new entry but not deleted
      const oldIdsValues = R.map((x) => x.id, oldValues);
      const newEntries = R.filter((x) => {
        return !oldIdsValues.includes(x.id) && !R.has("_destroy", x);
      }, newValues);

      const updatedEntries = R.filter((x) => {
        return (
          oldIdsValues.includes(x.id) &&
          !DeepCompare(
            x,
            R.find((oldValue) => x.id === oldValue.id, oldValues),
            [
              "building_id",
              "category_ids",
              "category_name",
              "category_root_id",
              "created_at",
              "product",
              "product_id",
              "updated_at",
              "visit_id",
            ]
          )
        );
      }, newValues);

      newEntries.forEach((x) => {
        dispatch(ProductsVisitsActions.syncProductVisit(x, id, "ADDED"));
      });
      updatedEntries.forEach((x) => {
        dispatch(
          ProductsVisitsActions.syncProductVisit(
            x,
            id,
            R.has("_destroy", x) ? "DELETED" : "UPDATED"
          )
        );
      });

      reset({
        products_visits_attributes: R.filter(
          (x) => !R.has("_destroy", x),
          newValues
        ),
      });
    },
    [initialValues]
  );

  return (
    <Stack spacing={2}>
      <ScreenTitle
        dirty={formState.isDirty}
        onClickBack={onGoBack}
        title={title}
        save={!isReadOnly}
        back={true}
        button="Retour"
        href={link}
        action={handleSubmit(onSubmit)}
      />
      {
        !isReadOnly &&
          <ProductsViews
            control={control}
            visit_id={id}
            building_id={building_id}
            root_category_id={building.category_id}
            append={append}
          />
      }
      <Stack spacing={1}>
        {fields.map((item, index) => {
          if (R.has("_destroy") && item._destroy) return null;
          return (
            <ProductVisit
              onDelete={onDestroyProductVisit}
              onClone={onCloneProductVisit}
              control={control}
              key={item.id}
              index={index}
              readonly={isReadOnly}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default ProductVisitContainer;

import { Formik } from 'formik'
import React, { useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { flashMessage } from 'redux-flash'
import { v4 as uuidv4 } from 'uuid'
import { createImageBankAction } from '../../actions/ImageBanksAction'
import { confirm } from '../../components/Confirms/ConfirmAction'
import FormContainer from './FormContainer'
import ScreenTitle from '../../components/ScreenTitle'
import { initialValues, validationSchema } from '../../Models/ImageBank'
import { getCookie } from '../../utils/Cookies'
import { saveJSONFileInStorage } from '../../utils/JsonTools'


const CreateImageBankContainer = ({ }) => {
    const navigation = useNavigate()
    const formikRef = useRef(null)
    const dispatch = useDispatch()

    const onBack = useCallback(async (e) => {
        e.preventDefault()

        if (formikRef.current.dirty) {
            if (!await confirm("Des éléments dans le formulaire n'ont pas été sauvegardés, êtes-vous sur de vouloir retourner sur la page d'avant ?")) {
                return
            }
        }
        navigation('/banque-images')
    }, [formikRef])

    const onSubmit = useCallback(async (values, formikCtx) => {
        formikCtx.setSubmitting(true)
        const newValues = await saveJSONFileInStorage({ id: uuidv4(), ...values })
        dispatch(createImageBankAction(getCookie('access_token'), newValues))
        formikCtx.setSubmitting(false)
        navigation(`/banque-images`, { replace: true })
        dispatch(flashMessage('L\'image a été créée.'))
    }, [])

    return (
        <div>
            <Formik
                innerRef={formikRef}
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {
                    ({ dirty, isValid }) => {
                        return (
                            <>
                                <ScreenTitle dirty={dirty} back={true} button='Retour' onClickBack={onBack} title={'Nouvelle image'} />
                                <FormContainer isValid={isValid} btnTitle='Sauvegarder' />
                            </>
                        )
                    }
                }
            </Formik>
        </div>
    )
}

export default CreateImageBankContainer
import { createSlice, createAction } from "@reduxjs/toolkit";
import * as R from "ramda";
import { createSelector } from "reselect";
import { DESTROY_VISIT_SUCCESS } from "../actions";

const cloneVisitByID = createAction("Visits.cloneVisitByID");

const updateVisitByIDSuccess = "Visits.updateVisitByIDSuccess";
const updateVisitByIDError = "Visits.updateVisitByIDError";
const updateVisitByID = createAction(
  "Visits.updateVisitByID",
  (visitId, payload) => {
    return {
      payload,
      meta: {
        createdAt: new Date().toISOString(),
        offline: {
          effect: {
            url: `/api/v2/visits/${visitId}`,
            method: "PATCH",
            json: payload,
          },
          commit: {
            type: updateVisitByIDSuccess,
            meta: {
              id: visitId,
            },
          },
          rollback: {
            type: updateVisitByIDError,
            meta: {
              id: visitId,
            },
          },
        },
      },
    };
  }
);

const createVisitSuccess = "Visits.createVisitSuccess";
const createVisitError = "Visits.createVisitError";
const createVisit = createAction(
  "Visits.createVisit",
  (payload, { location_id }) => {
    return {
      payload,
      meta: {
        location_id,
        createdAt: new Date().toISOString(),
        offline: {
          effect: {
            url: "/api/v2/visits",
            method: "POST",
            json: payload,
          },
          commit: {
            type: createVisitSuccess,
            meta: {
              location_id,
            },
          },
          rollback: {
            type: createVisitError,
            meta: { location_id },
          },
        },
      },
    };
  }
);

const entitiesSelector = (state) => state.visits.entities;
const idsSelector = (location_id) => (state) =>
  state.visits.ids[location_id] || [];
const idsVersions = (location_id) => (state) =>
  state.visits.versions[location_id] || [];
const entitiesIdsSelector = (location_id) => {
  return createSelector(
    idsSelector(location_id),
    idsVersions(location_id),
    (entitiesIds, versionsIds) => {
      return R.uniq([...entitiesIds, ...versionsIds]);
    }
  );
};

export const selectVisitsByLocationId = (location_id) => {
  return createSelector(
    entitiesIdsSelector(location_id),
    entitiesSelector,
    (idsEntities, entities) => {
      return R.compose(
        R.reject(R.isNil),
        R.map((x) => entities[x])
      )(idsEntities);
    }
  );
};

const initialValues = {
  entities: {},
  ids: {},
  fetching: false,
  error: false,
  waitForSync: [],
  versions: {},
};

const VisitsSlice = createSlice({
  name: "Visits",
  initialState: initialValues,
  reducers: {
    requestVisitsFromLocation(state, actions) {
      state.error = false;
      state.fetching = true;
    },
    requestVisitsFromLocationSuccess: {
      reducer(state, actions) {
        const {
          payload,
          meta: { location_id },
        } = actions;

        const ids = R.map((x) => x.id, payload);
        state.ids[location_id] = ids;

        payload.forEach((x) => {
          state.entities[x.id] = x;
        });

        state.error = false;
        state.fetching = false;
      },
      prepare(payload, meta) {
        return { payload, meta };
      },
    },
    requestsVisitsFromLocationError(state, action) {
      state.error = true;
      state.fetching = false;
    },

    cloneVisitFromIdSuccess: {
      reducer(state, actions) {
        const {
          payload,
          meta: { location_id },
        } = actions;
        state.entities[payload.id] = payload;
        state.ids[location_id].push(payload.id);
      },
      prepare(payload, meta) {
        return { payload, meta };
      },
    },
    requestVisitById(state, actions) {
      state.fetching = true;
      state.error = false;
    },
    requestVisitByIdError(state, actions) {
      state.fetching = false;
      state.error = actions.payload;
    },
    requestVisitByIdSuccess(state, actions) {
      const { payload } = actions;
      state.fetching = false;
      state.error = false;
      state.entities[payload.id] = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(DESTROY_VISIT_SUCCESS, (state, actions) => {
        const {
          payload: { id, location_id },
        } = actions;
        if (state.entities[id]) {
          delete state.entities[id];
        }
        if (state.ids[location_id]) {
          const ids = state.ids[location_id];
          state.ids[location_id] = R.without([id], ids);
        }
      })
      .addCase(updateVisitByIDSuccess, (state, actions) => {
        const {
          payload,
          meta: { id },
        } = actions;

        state.entities[id] = payload;
      })
      .addCase(updateVisitByID.type, (state, actions) => {
        const {
          payload,
          meta: { id },
        } = actions;
        state.entities[id] = payload;
      })
      .addCase(createVisit.type, (state, actions) => {
        const {
          payload,
          meta: { location_id },
        } = actions;
        state.waitForSync.push(payload.id);
        state.entities[payload.id] = payload;
        state.versions[location_id] ||= [];
        state.versions[location_id].push(payload.id);
      })
      .addCase(createVisitSuccess, (state, actions) => {
        const {
          payload,
          meta: { location_id },
        } = actions;

        state.entities[payload.id] = payload;
        state.ids[location_id].push(payload.id);
        const index = state.waitForSync.findIndex((x) => x == payload.id);
        if (index >= 0) {
          state.waitForSync.splice(index, 1);
        }

        state.versions[location_id] ||= [];
        const indexVersion = state.versions[location_id].findIndex(
          (x) => x === payload.id
        );
        if (indexVersion >= 0) {
          state.versions[location_id].splice(indexVersion, 1);
        }
      });
  },
});

export const VisitsActions = {
  ...VisitsSlice.actions,
  cloneVisitByID,
  updateVisitByID,
  createVisit,
};

export default VisitsSlice.reducer;

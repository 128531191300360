import * as types from './index'

export const rootsCategoriesAction = (token) => {
  return {
    type: types.GET_ROOTS_CATEGORIES,
    token
  }
}

export const categoryAction = (token, id, with_color = false) => {
  return {
    type: types.GET_CATEGORY,
    token,
    id,
    meta: { with_color }
  }
}
export const setCategoryAction = (id, level) => {
  return {
    type: types.SET_CATEGORY,
    id,
    level
  }
}

export const resetCategoryAction = () => {
  return {
    type: types.RESET_CATEGORY
  }
}

export const SaveCategoryAction = (label, parent_id) => {
  return {
    type: types.SAVE_CATEGORY,
    payload: {
      label, parent_id
    }
  }
}

export const SaveCategorySuccessAction = () => {
  return {
    type: types.SAVE_CATEGORY_SUCCESS
  }
}

export const SaveCategoryErrorAction = () => {
  return {
    type: types.SAVE_CATEGORY_ERROR
  }
}

export const setCategoryFromSync = (payload) => {
  return {
    type: types.SET_CATEGORY_FROM_SYNC,
    payload
  }
}

export const setCategoryFromSyncDeletion = (payload) => {
  return {
    type: types.SET_CATEGORY_FROM_SYNC_DELETION,
    payload
  }
}
import { Badge } from '@mui/material'
// UI Elements
import Button from '@mui/material/Button'
import React, { Component } from 'react'

class ButtonBox extends Component {

  onClickBtn = () => {
    this.props.onPress && this.props.onPress(this.props.item)
  }

  render() {
    const { count } = this.props
    const style = (this.props.size && this.props.size === 'small') ? { width: "100%" } : { width: '100%' }

    return (
      <div style={{ marginRight: '10px', marginTop: '10px' }}>
        <Badge color="primary" badgeContent={count} style={{ width: '100%' }}>
          <Button color='primary' onClick={this.onClickBtn} p={2} m={1} boxShadow={1} variant="contained" style={style} size="small" boxShadow={1} variant="contained">
            {this.props.item.name}
          </Button>
        </Badge>
      </div>
    )
  }
}

export default ButtonBox

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { Form } from 'formik'
import React from 'react'
import FileField from '../forms/Formik/FileField'
import TextAreaField from '../forms/Formik/TextAreaField'
import TextField from '../forms/Formik/TextField'

const CustomerForm = ({ btnTitle, isValid = true }) => {
  return (
    <Form autoComplete="off"    >
      <Grid container justify="center" spacing={0}>
        <Grid container xs={12}>
          <Grid item xs={12} sm={6} style={{ paddingRight: '10px' }}>
            <TextField name='name' label="Raison sociale" />
            <TextField name='address' label="Adresse" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField name='zip_code' label="Code postal" />
            <TextField name='city' label="Ville" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextAreaField name='comment' label="Commentaire" />
        </Grid>
        <Grid item xs={12}>
          <FileField name='file' label="Logo" />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button variant="contained" color={isValid ? 'primary' : 'secondary'} type="submit">{btnTitle}</Button>
        </Grid>
      </Grid>
    </Form>
  )
}

export default CustomerForm
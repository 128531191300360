import { call, put } from 'redux-saga/effects'
import { REFRESH_TOKEN_ERROR, REFRESH_TOKEN_SUCCESS } from '../actions'
import { logoutAction } from '../actions/LoginActions'
import { doRefreshTokenAuthenticiation } from '../services/Api'
import { getCookie, setCookie } from '../utils/Cookies'

export function* refreshTokenSaga() {
    const refreshToken = getCookie('refresh_token')

    try {
        const rsp = yield call(doRefreshTokenAuthenticiation, refreshToken)
        const { access_token, refresh_token } = rsp
        setCookie('refresh_token', refresh_token)
        setCookie('access_token', access_token)
        yield put({ type: REFRESH_TOKEN_SUCCESS })
    } catch (e) {
        yield put({ type: REFRESH_TOKEN_ERROR })
        if (e.message === 401) {
            yield put(logoutAction())
        }
    }
}
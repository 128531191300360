import React, { useCallback, useEffect, useState } from 'react'
import localforage from 'localforage'
import { filter } from 'ramda'
import { Button, Fab, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import ScreenTitle from '../components/ScreenTitle'
import { useDispatch, useSelector } from "react-redux";
import { flashMessage } from "redux-flash";

const regex = /^(local):\/\/[0-9a-zA-Z-]*$/
const regex2 = /^persist:root$/

const LocalLogsContainer = () => {
    const dispatch = useDispatch();
    const [images, setImages] = useState([])
    useEffect(() => {
        (async () => {
            const keys = await localforage.keys()
            const keys_filted = filter((x) => {
                return (!x.match(regex) && !x.match(regex2))
            }, keys)
            setImages(keys_filted)
        })()
    }, [])

    const onCopy = useCallback(async (name) => {
        const item = await localforage.getItem(name)
        navigator.clipboard.writeText(JSON.stringify(item));
        dispatch(flashMessage("La donnée a été copiée."));
    }, []);

    const onDelete = useCallback(async (name) => {
        await localforage.removeItem(name)
        dispatch(flashMessage("La donnée a été supprimée."));
    }, []);

    return (
        <div>
            <ScreenTitle title="Gestion des logs en local" />

            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom interne</TableCell>
                            <TableCell size='small' align='right'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            images.map((x) => {
                                return (
                                    <TableRow>
                                        <TableCell>{x}</TableCell>
                                        <TableCell size='small' align='right'>
                                            <Button onClick={() => { onCopy(x) }} variant="contained" color="primary">Copier</Button>
                                            &nbsp;
                                            <Button onClick={() => { onDelete(x) }} variant="contained" color="error">Supprimer</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
}

export default LocalLogsContainer
import Config from "../config/Config";
import * as types from "./index";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export const customersAction = () => {
  return {
    type: types.GET_CUSTOMERS,
  };
};
export const customerAction = (id) => {
  return {
    type: types.GET_CUSTOMER,
    payload: { id },
  };
};

export const createCustomerAction = (token, customer) => {
  const internalUUID = uuidv4();
  return {
    type: types.CREATE_CUSTOMER,
    payload: customer,
    meta: {
      id: customer.id,
      version: internalUUID,
      createdAt: new Date().toISOString(),
      offline: {
        effect: {
          url: `/api/v1/customers`,
          method: "POST",
          json: customer,
          headers: {
            "Cache-Control": "no-cache",
          },
        },
        commit: {
          type: types.CREATE_CUSTOMER_SUCCESS,
          meta: { version: internalUUID },
        },
        // action to dispatch if network action fails permanently:
        rollback: { type: types.CREATE_CUSTOMER_ERROR, version: internalUUID },
      },
    },
  };
};

export const updateCustomerAction = (id, customer, token) => {
  const internalUUID = uuidv4();
  return {
    type: types.UPDATE_CUSTOMER,
    payload: customer,
    meta: {
      id: id,
      version: internalUUID,
      createdAt: new Date().toISOString(),
      offline: {
        effect: {
          url: `/api/v1/customers/${id}`,
          method: "PATCH",
          json: customer,
          headers: {
            "Cache-Control": "no-cache",
          },
        },
        commit: {
          type: types.UPDATE_CUSTOMER_SUCCESS,
          meta: { version: internalUUID },
        },
        // action to dispatch if network action fails permanently:
        rollback: { type: types.UPDATE_CUSTOMER_ERROR, version: internalUUID },
      },
    },
  };
};

export const cloneCustomerAction = (id) => {
  return {
    type: types.CLONE_CUSTOMER,
    payload: { id },
  };
};

export const cloneCustomerSuccessAction = (payload) => {
  return {
    type: types.CLONE_CUSTOMER_SUCCESS,
    payload: payload,
  };
};

export const cloneCustomerErrorAction = (id) => {
  return {
    type: types.CLONE_CUSTOMER_ERROR,
    payload: { id },
  };
};

import React, { useCallback, useEffect, useState } from 'react'
import localforage from 'localforage'
import { filter } from 'ramda'
import { Button, Fab, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import ScreenTitle from '../../components/ScreenTitle'
import JSZip from "jszip"

const regex = /^local:\/\/[0-9a-zA-Z-]*$/

const ImagesLocalContainer = () => {
    const [images, setImages] = useState([])
    useEffect(() => {
        (async () => {
            const keys = await localforage.keys()
            const keys_filted = filter((x) => {
                return !!x.match(regex)
            }, keys)
            setImages(keys_filted)
        })()
    }, [])

    const onDownload = useCallback(async (name) => {
        const item = await localforage.getItem(name)
        const itemUrl = URL.createObjectURL(item)
        const link = document.createElement('a')
        link.href = itemUrl
        link.download = item.name

        document.body.appendChild(link);
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        // Remove link from body
        document.body.removeChild(link);
    }, [])

    const onZip = useCallback(async () => {
        const zip = new JSZip()
        for (const name of images) {
            const item = await localforage.getItem(name)
            zip.file(item.name, item, { binary: true })
        }
        const zipBlob = await zip.generateAsync({ type: 'blob' })
        const zipUrl = URL.createObjectURL(zipBlob)
        const link = document.createElement('a')
        link.href = zipUrl
        link.download = 'gem-images-local.zip'

        document.body.appendChild(link);
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );

        // Remove link from body
        document.body.removeChild(link);
    }, [images])

    return (
        <div>
            <ScreenTitle title="Gestion des images local" />
            <Button onClick={onZip} variant="contained" color="primary">Télécharger une archive</Button>

            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nom interne</TableCell>
                            <TableCell size='small' align='right'>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            images.map((x) => {
                                return (
                                    <TableRow>
                                        <TableCell>{x}</TableCell>
                                        <TableCell size='small' align='right'>
                                            <Button onClick={() => { onDownload(x) }} variant="contained" color="primary">Télécharger</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
}

export default ImagesLocalContainer
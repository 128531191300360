import Config from "../config/Config";
import * as types from "./index";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export const ProductAddProductTypeRequest = (trademark_id) => {
  return {
    type: types.PRODUCT_ADD_REQUEST_PRODUCT_TYPE,
    payload: {
      trademark_id,
    },
  };
};

export const ProductAddProductTypeSuccess = (payload) => {
  return {
    type: types.PRODUCT_ADD_REQUEST_PRODUCT_TYPE_SUCCESS,
    payload,
  };
};

export const ProductAddProductTypeError = () => {
  return {
    type: types.PRODUCT_ADD_REQUEST_PRODUCT_TYPE_ERROR,
  };
};

export const ProductAddProductRequest = (product_type_id) => {
  return {
    type: types.PRODUCT_ADD_REQUEST_PRODUCT,
    payload: {
      product_type_id,
    },
  };
};

export const ProductAddProductSuccess = (payload) => {
  return {
    type: types.PRODUCT_ADD_REQUEST_PRODUCT_SUCCESS,
    payload,
  };
};

export const ProductAddProductError = () => {
  return {
    type: types.PRODUCT_ADD_REQUEST_PRODUCT_ERROR,
  };
};

export const ProductAddRequest = (payload) => {
  return {
    type: types.PRODUCT_ADD_REQUEST,
    payload,
    meta: {
      createdAt: new Date().toISOString(),
      offline: {
        effect: {
          url: "/api/v1/products",
          method: "POST",
          json: payload,
        },
        commit: {
          type: types.PRODUCT_ADD_REQUEST_SUCCESS,
          meta: { category_id: payload.category_id },
        },
        rollback: {
          type: types.PRODUCT_ADD_REQUEST_ERROR,
          meta: { category_id: payload.category_id },
        },
      },
      category_id: payload.category_id,
    },
  };
};

export const ProductAddRequestSuccess = () => {
  return {
    type: types.PRODUCT_ADD_REQUEST_SUCCESS,
  };
};

export const ProductAddRequestError = () => {
  return {
    type: types.PRODUCT_ADD_REQUEST_ERROR,
  };
};

import React, { useCallback, useMemo } from "react";
import { Box, Stack } from "@mui/material";
import { productsFilterSelector } from "../../reducers/ProductsReducer";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import ButtonBox from "../../components/ButtonBox";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useWatch } from "react-hook-form";

import { ProductAddRequest } from "../../actions/ProductAddActions";
const ProductsSelector = ({
  onPress,
  category_id,
  trademark_id,
  product_type_id,
  control,
}) => {
  const entities = useSelector(
    productsFilterSelector(trademark_id, product_type_id, category_id)
  );

  const productVisits = useWatch({
    name: "products_visits_attributes",
    control: control,
  });

  const dispatch = useDispatch();

  const trademark = useSelector((state) => state.trademarks[trademark_id]);
  const productType = useSelector(
    (state) => state.product_types[product_type_id]
  );
  const category = useSelector((state) => state.category[category_id]);

  const products = useMemo(() => {
    return R.sort((a, b) => {
      const nameA = a.name || "";
      const nameB = b.name || "";
      return nameA.localeCompare(nameB, undefined, { numeric: true });
    }, entities);
  }, [entities]);

  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const onNewProduct = useCallback(
    (values) => {
      if (values.name === "" || !values.name) {
        return;
      }
      const newProductValue = {
        id: uuidv4(),
        name: values.name,
        trademark: trademark,
        product_type: productType,
        category_id: category.id,
        trademark_id: trademark.id,
        product_type_id: productType.id,
        trademark_name: trademark.name,
        product_type_name: productType.name,
        category_name: category.name,
      };
      dispatch(ProductAddRequest(newProductValue));
    },
    [trademark, productType, category]
  );
  return (
    <div>
      <Box display="flex" flexDirection="column" justifyContent="center" p={2}>
        <Stack direction="row" spacing={2}>
          <input
            placeholder="Ajouter"
            className="form-control"
            type="text"
            required
            {...register("name")}
          />
          <button
            onClick={handleSubmit(onNewProduct)}
            className="btn btn-primary mb-2"
          >
            OK
          </button>
        </Stack>
        {products.map((product) => {
          const badge = productVisits.filter(
            (x) => x.product_id === product.id
          ).length;
          return (
            <ButtonBox
              key={product.id}
              count={badge}
              item={product}
              onPress={() =>
                onPress({ type: "PRODUCT", object_id: product.id, index: 0 })
              }
            />
          );
        })}
      </Box>
    </div>
  );
};

export default ProductsSelector;

import { Box } from "@mui/material";
import * as R from "ramda";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createSelector } from "reselect";
import CategoryBox from "../../../components/CategoryBox";

const selectCategories = (state) => Object.values(state.category);
const selectorCategoriesRoot = createSelector(
  selectCategories,
  (categories) => {
    return R.filter((x) => x.parent_id === null, categories);
  }
);

const selectBuildingEntityIds = (visit_id) => (state) =>
  state.buildings.ids[visit_id] || [];
const selectBuildingVersionIds = (visit_id) => (state) =>
  state.buildings.versions[visit_id] || [];
const selectBuildingIds = (visit_id) =>
  createSelector(
    selectBuildingEntityIds(visit_id),
    selectBuildingVersionIds(visit_id),
    (entityIds, versionIds) => {
      return [...entityIds, ...versionIds];
    }
  );

const selectBuildingsEntity = (state) => state.buildings.entities;

const buildingsCountForCategory = (visit_id, category_id) =>
  createSelector(
    selectBuildingIds(visit_id),
    selectBuildingsEntity,
    (ids, entities) => {
      return ids.filter((x) => entities[x].category_id === category_id).length;
    }
  );

const CategoryItem = ({ visit_id, category }) => {
  const badge = useSelector(buildingsCountForCategory(visit_id, category.id));
  const navigation = useNavigate();

  return (
    <CategoryBox
      key={category.id}
      count={badge}
      category={category}
      onPress={() => {
        navigation(`/visites/${visit_id}/categorie/${category.id}/batiments`);
      }}
    />
  );
};

const CategoriesRootList = ({ visit_id }) => {
  const categories = useSelector(selectorCategoriesRoot);

  return (
    <Box display="flex" flexDirection="row" justifyContent="center" p={2}>
      {categories.map((category, index) => {
        return (
          <CategoryItem
            key={category.id}
            visit_id={visit_id}
            category={category}
          />
        );
      })}
    </Box>
  );
};

export default CategoriesRootList;

import { TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
// UI Elements
import Typography from '@mui/material/Typography'
import React, { Component } from 'react'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'


class ScreenTitle extends Component {

  render() {

    const { back, href, save, action, onSearch, onClickBack, loading = false, dirty = false } = this.props
    let subtitle, button, btn

    if (this.props.subtitle !== undefined || this.props.subtitle !== '') {
      subtitle = (
        <Typography component="span" style={{ marginLeft: '15px' }}>
          {this.props.subtitle}
        </Typography>
      )
    }

    if (save === true) {
      btn = (
        <Button color="primary" p={2} m={1} size="small" variant="contained" onClick={action} style={{ float: 'right', marginLeft: '20px' }}>
          Enregistrer
        </Button>
      )
    }

    if (back === true && !onClickBack) {
      button = (
        <Link className="btn btn-primary btn-sm" p={2} m={1} size="small" variant="contained" to={href} style={{ float: 'right' }}>
          {this.props.button}
        </Link>
      )
    } else if (back === true && onClickBack) {
      button = (
        <a href='#' onClick={onClickBack} className={`btn ${dirty ? 'btn-warning' : 'btn-primary'} btn-sm`} p={2} m={1} size="small" variant="contained" style={{ float: 'right' }}>
          {this.props.button}
        </a>
      )
    }

    return (
      <>
        <div className="d-none d-lg-block">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              {loading &&
                <>
                  <Spinner className='mr-2' size="sm" animation="grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  {'   '}
                </>
              }
              {this.props.title}
              {subtitle}
              {btn}

              {button}
            </Typography>
            {
              onSearch &&
              <TextField
                variant="standard"
                placeholder="Recherche"
                label="Recherche"
                margin="normal"
                inputProps={{
                  autoComplete: 'off'
                }}
                onChange={(e) => { onSearch(e.target.value) }}
              />
            }
            {this.props.children}
          </div>
          <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
        </div>
        <div className="d-lg-none">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant="h7">
              {loading &&
                <>
                  <Spinner className='mr-2' size="sm" animation="grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                  {'   '}
                </>
              }
              {this.props.title}
              {subtitle}
              <br />
              {btn}

              {button}
            </Typography>
            {
              onSearch &&
              <TextField
                inputProps={{
                  autoComplete: 'off'
                }}
                variant="standard"
                placeholder="Recherche"
                label="Recherche"
                onChange={(e) => { onSearch(e.target.value) }}
              />
            }
            {this.props.children}
          </div>
          <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
        </div>
      </>
    )
  }
}

export default ScreenTitle

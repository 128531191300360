import { Badge } from "@mui/material";
import { Formik } from "formik";
import * as R from "ramda";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Redirect from "../../components/Redirect";
import { flashMessage } from "redux-flash";
import {
  getAllotmentAction,
  updateAllotmentAction,
} from "../../actions/AllotmentsActions";
import { customerAction } from "../../actions/CustomersActions";
import { confirm } from "../../components/Confirms/ConfirmAction";
import AllotmentForm from "../../components/forms/AllotmentForm";
import ScreenTitle from "../../components/ScreenTitle";
import { validationSchema } from "../../Models/Allotment";
import { saveJSONInStorage } from '../../utils/JsonTools'

const UpdateAllotmentContainer = ({}) => {
  const navigation = useNavigate();
  const { customer_id, id } = useParams();
  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const selector = useCallback(
    (state) => {
      return {
        allotment: state.allotments.entities[id],
        customer: state.customers.entities[customer_id],
        loading: state.allotments.loading,
        error: state.allotments.error,
        isWaitForSync: R.compose(
          R.includes(id),
          R.defaultTo([]),
          R.path(["allotments", "ids", customer_id, "waitForSync"])
        )(state),
      };
    },
    [customer_id]
  );
  const { customer, loading, error, allotment, isWaitForSync } =
    useSelector(selector);

  useEffect(() => {
    dispatch(customerAction(customer_id));
    if (!isWaitForSync) {
      dispatch(getAllotmentAction(customer_id, id));
    }
  }, []);

  const title = useMemo(() => {
    if (!customer) return "";
    return `${customer.name} - Edition d'un lot`;
  }, []);

  const onBack = useCallback(
    async (e) => {
      e.preventDefault();
      if (formikRef.current && formikRef.current.dirty) {
        if (
          !(await confirm(
            "Des éléments dans le formulaire n'ont pas été sauvegardés, êtes-vous sur de vouloir retourner sur la page d'avant ?"
          ))
        ) {
          return;
        }
      }
      navigation(`/customers/${customer_id}/lots`);
    },
    [formikRef]
  );

  const onSubmit = useCallback(
    (values, formikCtx) => {
      formikCtx.setSubmitting(true);
      dispatch(
        updateAllotmentAction(customer_id, id, {
          ...values,
          updated_at: new Date().toISOString,
        })
      );
      saveJSONInStorage('allotment',values)
      formikCtx.setSubmitting(false);
      dispatch(flashMessage("Le lot a été mis à jour."));
      navigation(`/customers/${customer_id}/lots`);
    },
    [customer_id, id]
  );

  if (loading && R.isNil(allotment)) {
    return (
      <div>
        <ScreenTitle
          back={true}
          button="Retour"
          onClickBack={() => {
            navigation(`/customers/${customer_id}/lots`, { replace: true });
          }}
          title={"Chargement du lots..."}
        />
      </div>
    );
  }

  if (!loading && R.isNil(allotment) && error === "404") {
    return <Redirect to={`/customers/${customer_id}/lots`} />;
  }

  return (
    <div>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={allotment}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid }) => {
          return (
            <>
              <ScreenTitle
                dirty={dirty}
                onClickBack={onBack}
                back={true}
                button="Retour"
                title={title}
              >
                <div>
                  <Badge
                    color="secondary"
                    badgeContent={allotment.locations_counter}
                  >
                    <Link
                      className="btn btn-success btn-sm"
                      p={2}
                      m={1}
                      size="small"
                      variant="contained"
                      to={`/customers/${customer_id}/lots/${id}/locations`}
                    >
                      Sites
                    </Link>
                  </Badge>
                </div>
              </ScreenTitle>
              <AllotmentForm isValid={isValid} />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateAllotmentContainer;

export const DeepCompare = (a, b, blacklist = []) => {
  if (typeof a == "object" && a != null && typeof b == "object" && b != null) {
    var count = [0, 0];
    for (var key in a) count[0]++;
    for (var key in b) count[1]++;
    if (count[0] - count[1] != 0) {
      return false;
    }
    for (var key in a) {
      if (!blacklist.includes(key)) {
        if (!(key in b) || !DeepCompare(a[key], b[key])) {
          return false;
        }
      }
    }
    for (var key in b) {
      if (!blacklist.includes(key)) {
        if (!(key in a) || !DeepCompare(b[key], a[key])) {
          return false;
        }
      }
    }
    return true;
  } else {
    return a == b;
  }
};

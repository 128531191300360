import * as R from "ramda";
import { createReducer } from "reduxsauce";
import * as types from "../actions";
import moment from "moment";

import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  entities: {},
  customers_ids: [],
  loading: false,
  waitForSync: [],
  error: null,
};

const CustomersSlice = createSlice({
  initialState: INITIAL_STATE,
  name: "Customers",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(types.GET_CUSTOMERS, (state) => {
        state.loading = true;
      })
      .addCase(types.GET_CUSTOMERS_ERROR, (state) => {
        state.loading = false;
      })
      .addCase(types.GET_CUSTOMERS_SUCCESS, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.customers_ids = payload.map((x) => x.id);
        payload.forEach((x) => {
          if (state.entities[x.id]) {
            const item = state.entities[x.id];
            if (moment(item.updated_at).isBefore(x.updated_at)) {
              state.entities[x.id] = x;
            }
          } else {
            state.entities[x.id] = x;
          }
        });
      })
      .addCase(types.CREATE_CUSTOMER, (state, action) => {
        const { payload } = action;

        state.waitForSync.push(payload.id);
        state.entities[payload.id] = payload;
      })
      .addCase(types.CREATE_CUSTOMER_SUCCESS, (state, action) => {
        const { payload } = action;

        state.customers_ids.push(payload.id);
        state.entities[payload.id] = payload;
        const index = state.waitForSync.findIndex((x) => x === payload.id);
        if (index >= 0) {
          state.waitForSync.splice(index, 1);
        }
      })
      .addCase(types.GET_CUSTOMER, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(types.GET_CUSTOMER_ERROR, (state, action) => {
        state.loading = false;
        state.error = action.path;
      })
      .addCase(types.GET_CUSTOMER_SUCCESS, (state, action) => {
        const { payload } = action;
        state.loading = false;
        state.entities[payload.id] = payload;
      })
      .addCase(types.UPDATE_CUSTOMER, (state, action) => {
        const { payload } = action;
        state.entities[payload.id] = payload;
      })
      .addCase(types.UPDATE_CUSTOMER_SUCCESS, (state, action) => {
        const { payload } = action;
        state.entities[payload.id] = payload;
      })
      .addCase(types.CLONE_CUSTOMER, (state) => {
        state.loading = true;
      })
      .addCase(types.CLONE_CUSTOMER_SUCCESS, (state, actions) => {
        const { payload } = actions;
        state.loading = false;
        state.entities[payload.id] = payload;
        state.customers_ids.push(payload.id);
      })
      .addCase(types.CLONE_CUSTOMER_ERROR, (state) => {
        state.loading = false;
      });
  },
});

const reducers = CustomersSlice.reducer;

export default reducers;

import {
  DELETE_IMAGE_BANKS,
  DELETE_IMAGE_BANKS_ERROR,
  DELETE_IMAGE_BANKS_SUCCESS,
  GET_IMAGE_BANKS,
  GET_IMAGE_BANKS_ERROR,
  GET_IMAGE_BANKS_SUCCESS,
  POST_IMAGE_BANKS,
  POST_IMAGE_BANKS_ERROR,
  POST_IMAGE_BANKS_SUCCESS,
} from "../actions";

import Config from "../config/Config";
import * as types from "./index";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export const getImageBanks = () => {
  return {
    type: GET_IMAGE_BANKS,
  };
};

export const getImageBanksSuccess = (payload) => {
  return {
    type: GET_IMAGE_BANKS_SUCCESS,
    payload,
  };
};

export const getImageBanksError = () => {
  return {
    type: GET_IMAGE_BANKS_ERROR,
  };
};

export const postImageBank = (payload) => {
  return {
    type: POST_IMAGE_BANKS,
    payload,
  };
};

export const imageBankAction = (id) => {
  return {
    type: types.GET_IMAGE_BANK,
    payload: { id },
  };
};

export const createImageBankAction = (token, image_bank) => {
  const internalUUID = uuidv4();
  return {
    type: types.CREATE_IMAGE_BANK,
    payload: image_bank,
    meta: {
      id: image_bank.id,
      version: internalUUID,
      createdAt: new Date().toISOString(),
      offline: {
        effect: {
          url: `/api/v2/panoramic_images`,
          method: "POST",
          json: image_bank,
          headers: {
            "Cache-Control": "no-cache",
          },
        },
        commit: {
          type: types.CREATE_IMAGE_BANK_SUCCESS,
          meta: { version: internalUUID },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: types.CREATE_IMAGE_BANK_ERROR,
          version: internalUUID,
        },
      },
    },
  };
};

export const updateImageBankAction = (id, customer, token) => {
  const internalUUID = uuidv4();
  return {
    type: types.UPDATE_IMAGE_BANK,
    payload: customer,
    meta: {
      id: id,
      version: internalUUID,
      createdAt: new Date().toISOString(),
      offline: {
        effect: {
          url: `/api/v2/panoramic_images/${id}`,
          method: "PATCH",
          json: customer,
          headers: {
            "Cache-Control": "no-cache",
          },
        },
        commit: {
          type: types.UPDATE_IMAGE_BANK_SUCCESS,
          meta: { version: internalUUID },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: types.UPDATE_IMAGE_BANK_ERROR,
          version: internalUUID,
        },
      },
    },
  };
};

export const postImageBankSuccess = (payload) => {
  return {
    type: POST_IMAGE_BANKS_SUCCESS,
    payload: payload,
  };
};

export const postImageBankError = () => {
  return {
    type: POST_IMAGE_BANKS_ERROR,
  };
};

export const deleteImageBank = (payload) => {
  return {
    type: DELETE_IMAGE_BANKS,
    payload,
  };
};

export const deleteImageBankSuccess = (payload) => {
  return {
    type: DELETE_IMAGE_BANKS_SUCCESS,
    payload: payload,
  };
};

export const deleteImageBankError = () => {
  return {
    type: DELETE_IMAGE_BANKS_ERROR,
  };
};

export const publicImageBankAction = (id) => {
  return {
    type: types.GET_PUBLIC_IMAGE_BANK,
    payload: { id },
  };
};

import { path } from 'ramda'
import { call, put, select } from 'redux-saga/effects'
import { categoryAction } from '../actions/CategoriesActions'
import { ProductAddProductError, ProductAddProductSuccess, ProductAddProductTypeError, ProductAddProductTypeSuccess } from '../actions/ProductAddActions'
import { productsAction } from '../actions/ProductsActions'
import { productTypesAction } from '../actions/ProductTypesActions'
import { createProduct, getProducts, getProductTypes } from '../services/Api'
import { getCookie } from '../utils/Cookies'

export function* getProductTypesFromProductAdd(action) {
    const { payload: { trademark_id } } = action

    try {
        const rsp = yield call(getProductTypes, { token: getCookie('access_token'), trademark_id: trademark_id })
        yield put(ProductAddProductTypeSuccess(rsp))
    } catch (e) {
        yield put(ProductAddProductTypeError())
    }
}

export function* getProductFromProductAdd(action) {
    const { payload: { product_type_id } } = action

    try {
        const rsp = yield call(getProducts, { token: getCookie('access_token'), product_type_id: product_type_id })
        yield put(ProductAddProductSuccess(rsp))
    } catch (e) {
        yield put(ProductAddProductError())
    }
}

export function* createProductFromProductAdd(action) {
    const { payload, meta } = action

    const trademarkId = path(['trademark', 'id'], payload)
    const productTypeId = path(['product_type', 'id'], payload)
    const categoryId = path(['category_id'], payload)

    try {
        const rsp = yield call(createProduct, { token: getCookie('access_token'), data: payload })
        yield put(ProductAddProductSuccess())
        if (meta.load_products) {
            yield put(productsAction(getCookie('access_token'), payload.category_id, payload.product_type.id))
        } else {
            yield put(categoryAction(getCookie('access_token'), payload.category_id))
            const trademarkInRedux = yield select((state) => path(['product_types', 'meta'], state))
            if (trademarkInRedux
                && trademarkInRedux.trademark_id === trademarkId
                && trademarkInRedux.category_id === categoryId) {
                yield put(productTypesAction(getCookie('access_token'), categoryId, trademarkId))
            }
            const productsInRedux = yield select((state) => path(['products', 'meta'], state))
            if (productsInRedux
                && productsInRedux.category_id === categoryId
                && productsInRedux.product_type_id === productTypeId
                && productsInRedux.trademark_id === trademarkId) {
                yield put(productsAction(getCookie('access_token'), categoryId, productTypeId, trademarkId))
            }
        }
    } catch (e) {
        yield put(ProductAddProductError())
    }
}
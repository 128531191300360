import Config from '../config/Config'
import { ConvertJsonToFormData } from '../utils/ConvertJsonToFormData'
import { getCookie, setCookie } from '../utils/Cookies'
import axios from 'axios'
import axiosRetry from 'axios-retry';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

export const APIAuthenticate = axios.create({
  baseURL: Config.value('backendHost'),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Cache-Control': 'no-cache'
  }
})

APIAuthenticate.interceptors.request.use(request => {
  request.headers['Authorization'] = `Bearer ${getCookie('access_token')}`
  return request
}, (err) => Promise.reject(err))

const refreshAuthLogic = async (request) => {
  try {
    const rsp = await fetch(Config.value('backendHost') + '/oauth/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
      },
      body: JSON.stringify({
        grant_type: 'refresh_token',
        refresh_token: getCookie('refresh_token')
      })
    })

    const data = await rsp.json()
    const { access_token, refresh_token } = data
    setCookie('access_token', access_token)
    setCookie('refresh_token', refresh_token, 2376)

    request.response.config.headers['Authorization'] = `Bearer ${access_token}`
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(e)
  }
}

createAuthRefreshInterceptor(APIAuthenticate, refreshAuthLogic);
axiosRetry(APIAuthenticate, { retries: 5 });


export const doRefreshTokenAuthenticiation = (token) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/oauth/token'

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: token
    })
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}


export const doPasswordAuthentication = (email, password) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/oauth/token'

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    },
    body: JSON.stringify({
      grant_type: 'password',
      email: email,
      password: password
    })
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getMe = () => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/me'

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const updateMe = (values) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/me'
  const parameters = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(values)
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getTrademarks = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/trademarks'

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const destroyTrademarks = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + `/api/v1/trademarks/${request.id}`
  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp
      throw Error(rsp.status)
    })
}

export const getCustomers = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/customers'

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getCustomer = (id) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/customers/' + id

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(payload => {
      if (payload.ok) {
        return payload.json()
      }
      throw Error(payload.status)
    })
}

export const createCustomer = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/customers'

  const data = ConvertJsonToFormData(request.customer)

  const parameters = {
    method: 'POST',
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: data
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const updateCustomer = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/customers/' + request.id

  const data = ConvertJsonToFormData(request.customer)

  const parameters = {
    method: 'PATCH',
    headers: {
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: data
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getLocations = (customer_id, allotment_id) => {
  const REGISTER_API_ENDPOINT = `${Config.value('backendHost')}/api/v1/customers/${customer_id}/locations${allotment_id ? `?allotment_id=${allotment_id}` : ''}`

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getLocation = (id) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/locations/' + id

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const createLocation = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/customers/' + request.customer_id + '/locations'

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(request.location)
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const updateLocation = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/locations/' + request.id

  const parameters = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(request.location)
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const destroyLocation = (id) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/locations/' + id

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp
      throw Error(rsp.status)
    })
}

export const getAllotments = (customer_id) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/customers/' + customer_id + '/allotments'

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}
export const getAllotmentsStack = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/customers/' + request.customer_id + '/allotments_stack'

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getAllotment = (id) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/allotments/' + id

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const createAllotment = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/customers/' + request.customer_id + '/allotments'

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: request.allotment
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const updateAllotment = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/allotments/' + request.id

  const parameters = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: request.allotment
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getContractTypes = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/contract_types'

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getEnergyTypes = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/energy_types'

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getClimateZones = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/climate_zones'

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getRootsCategories = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/categories/roots'

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getCategory = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/categories/' + request.id

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const saveCategory = (request) => {
  const ENDPOINT = Config.value('backendHost') + '/api/v1/categories'

  const parameters = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(request.category)
  }

  return fetch(ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getProductTypes = (request) => {
  let REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/product_types?'

  if (request.category_id) {
    REGISTER_API_ENDPOINT += ('&category_id=' + request.category_id)
  }

  if (request.trademark_id) {
    REGISTER_API_ENDPOINT += ('&trademark_id=' + request.trademark_id)
  }

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getProducts = (request) => {
  let REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/products?'
  if (request.category_id) {
    REGISTER_API_ENDPOINT += ('&category_id=' + request.category_id)
  }

  if (request.product_type_id) {
    REGISTER_API_ENDPOINT += ('&product_type_id=' + request.product_type_id)
  }

  if (request.trademark_id) {
    REGISTER_API_ENDPOINT += ('&trademark_id=' + request.trademark_id)
  }

  const parameters = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getVisitsFromLocationID = async (location_id) => {
  const rsp = await APIAuthenticate.get(`/api/v2/visits?location_id=${location_id}`)
  return rsp.data
}

export const getVisit = async (id) => {
  const rsp = await APIAuthenticate.get(`/api/v2/visits/${id}`)
  return rsp.data
}

export const destroyVisite = (request) => {
  const REGISTER_API_ENDPOINT = Config.value('backendHost') + '/api/v1/visits/' + request.id

  const parameters = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
  }

  return fetch(REGISTER_API_ENDPOINT, parameters)
    .then(rsp => {
      if (rsp.ok) return rsp
      throw Error(rsp.status)
    })
}

export const cloneVisit = async (id, location_id) => {
  const rsp = await APIAuthenticate.post(`/api/v1/visits/${id}/clone`, {
    location_id: location_id
  })

  return rsp.data
}

export const createProduct = (request) => {
  const ENDPOINT = Config.value('backendHost') + '/api/v1/products'

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(request.data)
  }

  return fetch(ENDPOINT, params)
    .then(rsp => {
      if (rsp.ok) return rsp.json()
      throw Error(rsp.status)
    })
}

export const getBuildingFromVisitID = async (visit_id) => {
  const rsp = await APIAuthenticate.get(`/api/v2/buildings?visit_id=${visit_id}`)

  return rsp.data
}

export const destroyBuilding = async (id) => {
  const rsp = await APIAuthenticate.delete(`/api/v1/buildings/${id}`)
  return rsp.data
}

export const getRapportsApi = async (token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/reports`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const postRapportApi = async (payload, token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/reports`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(payload)
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const deleteRapportApi = async (id, token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/reports/${id}`
  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return rsp
  throw Error(rsp.status)
}

export const getImageBanksApi = async (token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v2/panoramic_images`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const getPublicImageBankApi = async (id) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v2/panoramic_images/${id}/public`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      // 'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const getImageBankApi = async (id) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v2/panoramic_images/${id}`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const postImageBankApi = async (payload, token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v2/panoramic_images`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(payload)
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const deleteImageBankApi = async (id, token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v2/panoramic_images/${id}`
  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return rsp
  throw Error(rsp.status)
}

export const getCompareRapportsApi = async (token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/compare_reports`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const postCompareRapportApi = async (payload, token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/compare_reports`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(payload)
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const deleteCompareRapportApi = async (id, token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/compare_reports/${id}`
  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return rsp
  throw Error(rsp.status)
}

export const getP2ReportsApi = async (token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/p2_reports`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const postP2ReportApi = async (payload, token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/p2_reports`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(payload)
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const deleteP2ReportApi = async (id, token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/p2_reports/${id}`
  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return rsp
  throw Error(rsp.status)
}

export const getP3ReportsApi = async (token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/p3_reports`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const postP3ReportApi = async (payload, token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/p3_reports`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(payload)
  }

  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const deleteP3ReportApi = async (id, token) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/p3_reports/${id}`
  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return rsp
  throw Error(rsp.status)
}

export const getSyncCategories = async (token, updated_at = '') => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/categories/sync?updated_at=${updated_at}`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const removeSyncCategories = async (ids) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/categories/sync_deleted`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(ids)
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const getSyncProductTypes = async (token, updated_at = '') => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/product_types/sync?updated_at=${updated_at}`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const removeSyncProductTypes = async (ids) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/product_types/sync_deleted`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(ids)
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const getSyncProducts = async (token, updated_at = '') => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/products/sync?updated_at=${updated_at}`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const removeSyncProducts = async (ids) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/products/sync_deleted`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(ids)
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const getSyncTrademarks = async (token, updated_at = '') => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/trademarks/sync?updated_at=${updated_at}`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const removeSyncTrademarks = async (ids) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/trademarks/sync_deleted`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    },
    body: JSON.stringify(ids)
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const cloneCustomer = async (token, id) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/customers/${id}/clone`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const cloneLocation = async (id) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/locations/${id}/clone`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const cloneAllotment = async (id) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/allotments/${id}/clone`
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const getLocationsAndAllotmentsFromCustomer = async (token, customer_id) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/customers/${customer_id}/locations_and_allotments`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const destroyAllotment = async (id) => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/allotments/${id}`
  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return rsp
  throw Error(rsp.status)
}

export const getOffices = async () => {
  const ENDPOINT = `${Config.value('backendHost')}/api/v1/offices`
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + getCookie('access_token')
    }
  }
  const rsp = await fetch(ENDPOINT, params)
  if (rsp.ok) return await rsp.json()
  throw Error(rsp.status)
}

export const getProductsVisitsByVisitID = async (visitId) => {
  const rsp = await APIAuthenticate.get(`/api/v2/product_visits/${visitId}`)

  return rsp.data
}
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  ButtonGroup,
  Stack,
  ImageListItem,
  ImageList,
} from "@mui/material";
import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState,
} from "react";
import { useWatch, Controller, useFieldArray } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import Config from "../../config/Config";
import * as R from "ramda";
import { v4 as uuidv4 } from "uuid";
import { renameFile } from "../../utils/JsonTools";
import { useSelector } from "react-redux";
import { getFile } from "../../services/StoreFile";
import { isReadOnlySelector } from "../../reducers/MeReducer";

const year = moment().year();
const years = Array.from(
  new Array(50),
  (val, index) => year - 49 + index
).reverse();

const ImageField = ({ field: { value }, onDelete, index }) => {
  const [imgUrl, setImgUrl] = useState(null);
  const isReadOnly = useSelector(isReadOnlySelector)
  useEffect(() => {
    (async () => {
      if (R.has("_destroy", value) && value._destroy) {
        setImgUrl(null);
      } else if (value.file instanceof File) {
        setImgUrl(URL.createObjectURL(value.file));
      } else if (
        typeof value.file === "string" &&
        value.file.startsWith("local://")
      ) {
        const localImg = await getFile(value.file);
        setImgUrl(localImg.url);
      } else {
        setImgUrl(value.file);
      }
    })();
  }, [value]);

  if (R.has("_destroy", value) && value._destroy) return null;

  return (
    <ImageListItem style={{ position: "relative" }}>
      <img loading="lazy" src={imgUrl} />
      {
        !isReadOnly &&
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "#DC143C",
              borderBottomRightRadius: "50%",
            }}
          >
            <CloseIcon
              onClick={() => {
                onDelete(index, value);
              }}
              color="inherit"
            />
          </div>
      }
    </ImageListItem>
  );
};

const ProductVisitImages = ({ control, index }) => {
  const inputRef = useRef(document.createElement("input"));
  const isReadOnly = useSelector(isReadOnlySelector)

  const { fields, append, remove, update } = useFieldArray({
    name: `products_visits_attributes.${index}.images_attributes`,
    control: control,
  });

  const onImageAdded = useCallback((e) => {
    const file = e.target.files[0];

    append({
      id: uuidv4(),
      file: renameFile(file),
    });
  }, []);

  const onDelete = useCallback(
    (index, item) => {
      if (item.file instanceof File) {
        remove(index);
        return;
      }

      update(index, {
        ...item,
        _destroy: true,
      });
    },
    [update]
  );

  useEffect(() => {
    inputRef.current.type = "file";
    inputRef.current.accept = "image/*";
    inputRef.current.onchange = onImageAdded;
  }, [onImageAdded]);

  const showInputFile = useCallback(() => {
    inputRef.current.click();
  }, []);

  return (
    <Stack spacing={2}>
      {
        !isReadOnly &&
          <Button variant="contained" color="secondary" onClick={showInputFile}>
            Ajouter une image
          </Button>
      }
      <ImageList cols={6}>
        {fields.map((item, imgIndex) => {
          return (
            <Controller
              key={item.id}
              control={control}
              name={`products_visits_attributes.${index}.images_attributes.${imgIndex}`}
              render={({ ...field }) => (
                <ImageField {...field} onDelete={onDelete} index={imgIndex} />
              )}
            />
          );
        })}
      </ImageList>
    </Stack>
  );
};

const ProductVisit = ({ index, control, onClone, onDelete, readonly }) => {
  const productVisit = useWatch({
    name: `products_visits_attributes.${index}`,
    control: control,
  });
  const { product, category_name, trademark_name, category_ids } = productVisit;
  const secondCategoryId = category_ids[1];
  const secondCategory = useSelector(
    (state) => state.category[secondCategoryId]
  );
  const measure_name =
    product.measure && product.measure.name ? product.measure.name : "Unité";
  const measure_unit =
    product.measure && product.measure.unit
      ? "(" + product.measure.unit + ")"
      : "";

  return (
    <Paper
      style={{
        padding: "10px",
        borderTop: "4px solid",
        borderTopColor: secondCategory?.color || "black",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          <Typography
            variant="span"
            component="h5"
            style={{ paddingLeft: "5px" }}
          >
            {category_name}-{product.trademark_name}-{product.product_type_name}
            -{product.name}
          </Typography>
        </Grid>
        {
          !readonly &&
            <Grid item xs={12} sm={12} md={4} sx={{ justifyContent: "flex-end" }}>
              <ButtonGroup style={{ float: "right" }}>
                <Button
                  onClick={() => {
                    onClone(productVisit);
                  }}
                  variant="contained"
                  color="info"
                  size="small"
                >
                  Dupliquer
                </Button>
                <Button
                  onClick={() => {
                    onDelete(index, productVisit);
                  }}
                  variant="contained"
                  color="error"
                  size="small"
                >
                  Supprimer
                </Button>
              </ButtonGroup>
            </Grid>
        }
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} style={{ padding: "5px" }}>
          <Stack>
            <div className="form-group">
              <label style={{ fontSize: "11px" }}>
                {measure_name} {measure_unit}
              </label>
              <Controller
                name={`products_visits_attributes.${index}.power`}
                control={control}
                render={({ field }) => (
                  <input
                    className="form-control form-control-sm"
                    type="number"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="form-group">
              <label style={{ fontSize: "11px" }}>Année</label>
              <Controller
                name={`products_visits_attributes.${index}.age`}
                control={control}
                render={({ field }) => (
                  <select className="form-control form-control-sm" {...field}>
                    <option></option>
                    {years.map((y) => {
                      return (
                        <option key={y} value={y}>
                          {y}
                        </option>
                      );
                    })}
                  </select>
                )}
              />
            </div>
            <div className="form-group">
              <label style={{ fontSize: "11px" }}>Avis qualitatif</label>
              <Controller
                name={`products_visits_attributes.${index}.opinion`}
                control={control}
                render={({ field }) => (
                  <select className="form-control form-control-sm" {...field}>
                    <option></option>
                    <option value="bon">Bon</option>
                    <option value="moyen">Moyen</option>
                    <option value="mauvais">Mauvais</option>
                    <option value="hs">Hors-service</option>
                  </select>
                )}
              />
            </div>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={6} style={{ padding: "5px" }}>
          <div className="form-group">
            <label style={{ fontSize: "11px" }}>Commentaire</label>
            <Controller
              name={`products_visits_attributes.${index}.comment`}
              control={control}
              render={({ field }) => (
                <textarea
                  className="form-control form-control-sm product-visit-text-area"
                  {...field}
                ></textarea>
              )}
            />
          </div>
        </Grid>
      </Grid>
      <Box>
        <ProductVisitImages control={control} index={index} />
      </Box>
    </Paper>
  );
};

export default ProductVisit;

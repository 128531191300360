import { call, put } from 'redux-saga/effects'
import * as types from '../actions'
import { getContractTypes } from '../services/Api'


export function* contractTypesSaga(payload) {
  try {
    const response = yield call(getContractTypes, payload)
    yield put({ type: types.GET_CONTRACT_TYPES_SUCCESS, response })

  } catch (error) {
    yield put({ type: types.GET_CONTRACT_TYPES_ERROR, error })
  }
}

import moment from 'moment'
import * as R from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { Alert } from 'react-bootstrap'
import { connect, useDispatch } from 'react-redux'
import { allotmentsAction } from '../../actions/AllotmentsActions'
import { locationsAction } from '../../actions/LocationsActions'
import { buildLocationsKey } from '../../reducers/LocationsReducer'
import AllotmentsCheckbox from './AllotmentsCheckbox'
import LocationsCheckbox from './LocationsCheckbox'

const AllotmentsSelector = R.compose(
    R.uniqBy(R.prop('id')),
    R.reject(R.isNil),
    R.map(R.prop('allotment')),
    R.reject(R.propEq('visits', [])),
    R.defaultTo([])
)

const LocationsFilterSelector = (locations, allotment_id) => {
    return R.compose(
        R.reject(R.propEq('visits', [])),
        R.filter(R.propEq('allotment_id', allotment_id)),
        R.defaultTo([])
    )(locations)
}

const FindLastVisits = (locations) => {
    const allotment_ids = R.map(R.prop('id'), AllotmentsSelector(locations))
    const _ = [...allotment_ids, null]
    const values = _.reduce((prev, allotment_id) => {
        const _locations = LocationsFilterSelector(locations, allotment_id)
        R.forEach((_location) => {
            const visit_id = R.compose(
                R.head,
                R.sort((a, b) => {
                    const aDate = moment(a.seen_at).format('YYYYMMDD')
                    const bDate = moment(b.seen_at).format('YYYYMMDD')
                    return bDate - aDate
                }),
                R.prop('visits')
            )(_location)
            prev.push(visit_id)
        }, _locations)
        return prev
    }, [])

    return R.map(R.prop('id'), values)
}

const LocationsForm = ({ customer_id, setValues, locations, error }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(locationsAction(customer_id, undefined))
        dispatch(allotmentsAction(customer_id))
        dispatch(allotmentsAction(customer_id, true))
        setValues('visit_ids', [])
    }, [customer_id])

    const allotments = useMemo(() => {
        return AllotmentsSelector(locations)
    }, [locations])

    const locationsWithoutAllotments = useMemo(() => {
        return LocationsFilterSelector(locations, null)
    }, [locations])

    useEffect(() => {
        setValues('visit_ids', FindLastVisits(locations))
    }, [locations])

    return (
        <div className='locationsFormContainer'>
            {
                error &&
                <Alert variant='danger'>{error}</Alert>
            }
            {
                allotments.map((allotment) => {
                    return <AllotmentsCheckbox key={allotment.id} locations={LocationsFilterSelector(locations, allotment.id)} allotment={allotment} />
                })
            }
            <div>
                <LocationsCheckbox locations={locationsWithoutAllotments} />
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    const allotments_ids = R.compose(
        R.defaultTo([]),
        R.path(['allotments', 'ids', props.customer_id, 'allotment_ids'])
    )(state)
    const location_ids = R.compose(
        R.defaultTo([]),
        R.path(['locations', 'ids', props.customer_id, 'location_ids'])
    )(state)
    const location_with_allotments_ids = R.compose(
        R.flatten,
        R.map((x) => {
            return R.compose(
                R.defaultTo([]),
                R.path(['locations', 'ids', buildLocationsKey(props.customer_id, x), 'location_ids'])
            )(state)
        })
    )(allotments_ids)
    const locations = R.compose(
        R.reject(R.isNil),
        R.map(x => {
            return R.path(['locations', 'entities', x], state)
        })
    )(R.concat(location_with_allotments_ids, location_ids))
    return {
        locations: locations,
    }
}

export default connect(mapStateToProps)(LocationsForm)
import { TextField as TextFieldMaterial } from '@mui/material'
import { useField } from 'formik'
import React from 'react'

const TextField = ({ ...props }) => {
    const [field, meta, helpers] = useField(props)

    return (
        <TextFieldMaterial
            error={!!meta.error}
            helperText={meta.error}
            {...props}
            {...field}
        />
    )
}

export default TextField
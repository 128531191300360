import * as R from 'ramda'
import { createSelector } from 'reselect'
import * as types from '../actions'

// Selectors

export const productsSelector = state => state.products

export const productsFilterSelector = (trademark_id, product_type_id, category_id) => {
  return createSelector(
    productsSelector,
    products => R.compose(
      R.filter((item) => {
        if (R.has('product_type_id', item) && R.has('trademark_id', item) && R.has('category_id', item) && R.has('id', item)) {
          return item.product_type_id === product_type_id && item.trademark_id === trademark_id && item.category_id === category_id
        }
        return false
      }),
      R.values
    )(products)
  )
}
export default function (state = [], action) {
  const response = action.response
  switch (action.type) {
    case types.GET_PRODUCTS:
      return {
        ...state,
        meta: {
          category_id: action.category_id,
          product_type_id: action.product_type_id,
          trademark_id: action.trademark_id
        }
      }
    case types.GET_PRODUCTS_SUCCESS:
      return R.mergeRight(state, R.reduce((prev, current) => {
        prev[current.id] = current
        return prev;
      }, {}, response))
    case types.GET_PRODUCTS_ERROR:
      return state
    case types.RESET_PRODUCTS:
      return state
    case types.SET_PRODUCT_FROM_SYNC:
      return R.mergeRight(state, action.payload)
    case types.SET_PRODUCT_FROM_SYNC_DELETION:
      return R.omit(action.payload, state)
    case types.PRODUCT_ADD_REQUEST:
      return R.mergeRight(state, { [action.payload.id]: action.payload })
    case types.PRODUCT_ADD_REQUEST_SUCCESS:
      return R.mergeRight(state, { [action.payload.id]: action.payload })
    default:
      return state
  }
}

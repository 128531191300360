import * as types from '../actions'

export default function (state = [], action) {
  const response = action.response
  switch (action.type) {
    case types.GET_CLIMATE_ZONES_SUCCESS:
      return { ...state, response }
    case types.GET_CLIMATE_ZONES_ERROR:
      return { ...state }
    default:
      return state
  }
}

import { useField } from 'formik'
import * as R from 'ramda'
import React, { useCallback, useMemo } from 'react'
import VisitsCheckbox from './VisitsCheckbox'

const VisitsIdsSelector = R.compose(
    R.map(R.prop('id')),
    R.prop('visits')
)

const LocationCheckbox = ({ location }) => {
    const [field, meta, helpers] = useField('visit_ids')
    const visitsIds = useMemo(() => {
        return VisitsIdsSelector(location)
    }, [location])
    const onChange = useCallback((e) => {
        const value = e.target.checked

        if (value) {
            helpers.setValue(
                R.compose(
                    R.uniq,
                    R.concat(visitsIds)
                )(field.value)
            )
        } else {
            helpers.setValue(R.without(visitsIds, field.value))
        }
    }, [field, visitsIds])
    const checked = R.any((i) => visitsIds.includes(i), field.value)

    return (
        <div>
            <div className='rapportsCheckboxContainer'>
                <input checked={checked} onChange={onChange} type='checkbox' id={`locations-${location.id}`} />
                <label htmlFor={`locations-${location.id}`}>{location.label}</label>
            </div>
            <VisitsCheckbox visits={location.visits} />
        </div>
    )
}

const LocationsCheckbox = ({ locations }) => {
    return (
        <div className='rapportsLocationsContainer'>
            {
                locations.map((location) => {
                    return (
                        <LocationCheckbox key={location.id} location={location} />
                    )
                })
            }
        </div>
    )
}

export default LocationsCheckbox
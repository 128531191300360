import { Badge } from '@mui/material'
import { Formik } from 'formik'
import * as R from 'ramda'
import { propEq } from 'ramda'
import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useMatch } from 'react-router-dom'
import Redirect from '../../components/Redirect'
import { flashMessage } from 'redux-flash'
import { object, string } from 'yup'
import { updateVisitAction } from '../../actions/VisitsActions'
import { confirm } from '../../components/Confirms/ConfirmAction'
import ScreenTitle from '../../components/ScreenTitle'
import { saveJSONFileInStorage, saveJSONInStorage } from '../../utils/JsonTools'
import BuildingForm from './FormContainer'
import { useParams, useNavigate } from 'react-router-dom'
import { BuildingsActions } from '../../reducers/BuildingsReducer'
import { isReadOnlySelector } from '../../reducers/MeReducer'

const validation = object().shape({
    name: string().required('La champ est obligatoire')
})

const BuildingsEditContainer = ({ }) => {
    const formikRef = useRef(null)
    const { id, building_id } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isReadOnly = useSelector(isReadOnlySelector)

    const visit = useSelector(state => state.visits.entities[id])
    const building = useSelector(state => state.buildings.entities[building_id])
    const category = useSelector(state => state.category[building.category_id])

    const title = useMemo(() => {
        if (visit && category) {
            return `Batiments - ${visit.location_attributes.label} - ${category.name}`
        }
        return `Batiments`
    }, [visit, category])

    const onSubmit = useCallback(async (values, formikBag) => {
        formikBag.setSubmitting(true)
        const valueInLocalForage = await saveJSONFileInStorage(values)
        await saveJSONInStorage('building',values)
        dispatch(BuildingsActions.updateBuildingByVisitAndCategory(valueInLocalForage, { visit_id: id, building_id }))
        formikBag.setValues(valueInLocalForage)
        formikBag.setSubmitting(false)
        dispatch(flashMessage('Batiment mis à jour'))
    }, [])

    const onGoBack = useCallback(
        async (e) => {
            e.preventDefault();
            navigate(`/visites/${id}/categorie/${building.category_id}/batiments`)
        },
        [formikRef, building],
    )

    return (
        <Formik
            innerRef={formikRef}
            initialValues={building}
            onSubmit={onSubmit}
            validationSchema={validation}
        >
            {
                ({ dirty, isValid }) => (
                    <div>
                        <ScreenTitle onClickBack={onGoBack} dirty={false} back={true} button='Retour' href={`/visites/${id}`} title={title}>
                            <div>
                                <Badge color="secondary" badgeContent={0}>
                                    <Link className="btn btn-success btn-sm" p={2} m={1} size="small" variant="contained" to={`/visites/${id}/batiments/${building_id}/visits`}>
                                        Equipements
                                    </Link>
                                </Badge>
                            </div>
                        </ScreenTitle>
                        <BuildingForm isValid={isValid} readonly={isReadOnly} />
                    </div>
                )
            }
        </Formik>
    )


    // const formikRef = useRef(null)
    // const parentMatch = useMatch('/visites/:visit_id')
    // const { params: { building_id } } = match
    // const { params: { visit_id } } = parentMatch
    // const dispatch = useDispatch()

    // const { visit, category, isOffline, building } = useSelector((state) => {
    //     const visit = R.path(['visits', 'entities', visit_id], state)
    //     const building = R.compose(
    //         R.find(propEq('id', building_id)),
    //         R.defaultTo([]),
    //         R.prop('buildings_attributes')
    //     )(visit)

    //     const category = R.path(['category', R.prop('category_id', building)], state)

    //     return {
    //         visit: visit,
    //         building,
    //         category,
    //         isOffline: !state.offline.online
    //     }
    // })
    // const title = useMemo(() => {
    //     if (visit && category) {
    //         return `Batiments - ${visit.location_attributes.label} - ${category.name}`
    //     }
    //     return `Batiments`
    // }, [visit, category])

    // const onSubmit = useCallback(async (value, formikBag) => {
    //     formikBag.setSubmitting(true)
    //     const valueInLocalForage = await saveJSONFileInStorage(value)
    //     const buildingIndex = R.compose(
    //         R.findIndex(R.propEq('id', value.id)),
    //         R.defaultTo([]),
    //         R.path(['buildings_attributes'])
    //     )(visit)
    //     const buildings = R.compose(
    //         R.update(buildingIndex, valueInLocalForage),
    //         R.defaultTo([]),
    //         R.path(['buildings_attributes'])
    //     )(visit)

    //     const newVisit = R.mergeDeepLeft({
    //         buildings_attributes: buildings
    //     }, visit)
    //     dispatch(updateVisitAction(visit_id, newVisit))
    //     formikBag.setValues(valueInLocalForage)
    //     formikBag.setSubmitting(false)
    //     dispatch(flashMessage('Batiment mis à jour'))
    // }, [building, visit, isOffline])

    // const onGoBack = useCallback(
    //     async (e) => {
    //         e.preventDefault();
    //         // if (formikRef.current.dirty) {
    //         //     if (!await confirm("Des éléments dans le formulaire n'ont pas été sauvegardés, êtes-vous sur de vouloir retourner sur la page d'avant ?")) {
    //         //         return
    //         //     }
    //         // }
    //         history.replace(`/visites/${visit_id}/categorie/${building.category_id}/batiments`)
    //     },
    //     [formikRef, building],
    // )

    // if (building === null) return <Redirect to={'/dashboard'} />

    // return (
    //     <Formik
    //         innerRef={formikRef}
    //         initialValues={building}
    //         onSubmit={onSubmit}
    //         validationSchema={validation}
    //     >
    //         {
    //             ({ dirty, isValid }) => (
    //                 <div>
    //                     <ScreenTitle onClickBack={onGoBack} dirty={false} back={true} button='Retour' href={`/visites/${visit_id}`} title={title}>
    //                         <div>
    //                             <Badge color="secondary" badgeContent={0}>
    //                                 <Link className="btn btn-success btn-sm" p={2} m={1} size="small" variant="contained" to={`/visites/${visit_id}/batiments/${building_id}/visits`}>
    //                                     Equipements
    //                                 </Link>
    //                             </Badge>
    //                         </div>
    //                     </ScreenTitle>
    //                     <BuildingForm isValid={isValid} />
    //                 </div>
    //             )
    //         }
    //     </Formik>
    // )
}

export default BuildingsEditContainer
import React from 'react'
import ScreenTitle from '../../components/ScreenTitle'
import FormContainer from './FormContainer'

const P3ReportCreateContainer = () => {
    return (
        <div>
            <ScreenTitle back={true} button='Retour' href='/chiffrages-p2' title="Création d'un rapport P3" />
            <FormContainer />
        </div>
    )
}

export default P3ReportCreateContainer
import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { productTypesFilterSelector } from "../../reducers/ProductTypesReducer";
import * as R from "ramda";
import ProductTypeBox from "../../components/ProductTypeBox";
import { useWatch } from "react-hook-form";

export const ProductTypesSelector = ({
  onPress,
  trademark_id,
  category_id,
  control,
}) => {
  const entities = useSelector(
    productTypesFilterSelector(category_id, trademark_id)
  );

  const productVisits = useWatch({
    name: "products_visits_attributes",
    control: control,
  });

  const productTypes = useMemo(() => {
    return R.sort((a, b) => {
      const nameA = a.name || "";
      const nameB = b.name || "";
      return nameA.localeCompare(nameB, undefined, { numeric: true });
    })(entities);
  }, [entities]);
  return (
    <div>
      <Box display="flex" flexDirection="column" justifyContent="center" p={2}>
        {productTypes.map((productType) => {
          const badge = productVisits.filter(
            (x) => x.product.product_type_id === productType.id
          ).length;
          return (
            <ProductTypeBox
              key={productType.id}
              count={badge}
              product_type={productType}
              onPress={() =>
                onPress({
                  type: "PRODUCT_TYPE",
                  object_id: productType.id,
                  index: 0,
                })
              }
            />
          );
        })}
      </Box>
    </div>
  );
};

export default ProductTypesSelector;

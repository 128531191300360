import { useField } from 'formik'
import React from 'react'

const DateField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props)
    return (
        <div className="form-group" style={{ marginBottom: "5px" }}>
            <label style={{ fontSize: "11px" }} className={(meta.touched && meta.error) && 'text-danger'}>
                {label}
                {meta.touched && ((meta.error && <span className="text-danger">&nbsp; -- &nbsp;{meta.error}</span>))}

            </label>

            <input
                label={label}
                variant="outlined"
                type="date"
                className={"form-control form-control-sm " + ((meta.touched && meta.error) && "is-invalid")}
                placeholder={label}
                style={{ width: '100%' }}
                error={meta.touched && meta.error}
                {...field}
            />
        </div>
    )
}

export default DateField
import { Badge } from '@mui/material'
// UI Elements
import Button from '@mui/material/Button'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { compose } from 'redux'


const ProductTypeBox = ({ product_type, onPress, count, size }) => {
  const location = useLocation()
  const isSelected = location.pathname.split('/').includes(`p${product_type.id.toString()}`)
  const color = isSelected ? 'disabled' : 'primary'
  const style = (size && size === 'small') ? { width: "100%" } : { width: "100%" }
  
  return (
    <div style={{ marginRight: '10px', marginTop: '10px' }}>
      <Badge color="primary" badgeContent={count} style={{ width: '100%' }}>
        <Button color={color} onClick={onPress} p={2} m={1} boxShadow={1} variant="contained" style={style} size="small">
          {product_type.name}
        </Button>
      </Badge>
    </div>
  )
}

export default ProductTypeBox

import * as R from 'ramda'
import { saveFile } from '../services/StoreFile'
import { saveJSON } from '../services/StoreJSON'
import { v4 as uuidv4 } from 'uuid'

export const saveJSONInStorage = async (type, jsonObject) =>
{
    const item = await saveJSON(type, jsonObject)
    return item.id
}

export const saveJSONFileInStorage = async (jsonObject) => {
    if (jsonObject && typeof jsonObject === 'object' && Array.isArray(jsonObject)) {
        return await Promise.all(R.map(async (x) => {
            return await saveJSONFileInStorage(x)
        }, jsonObject))
    } else if (jsonObject && typeof jsonObject === 'object' && !(jsonObject instanceof Date) && !(jsonObject instanceof File)) {
        // OBJECT
        const _values = Object.values(jsonObject)
        const _keys = Object.keys(jsonObject)

        const _awaiter = await Promise.all(R.map(async (x) => {
            return await saveJSONFileInStorage(x)
        }, _values))

        return _keys.reduce((acc, v, i) => {
            acc[v] = _awaiter[i]
            return acc
        }, {})
    } else {
        if (jsonObject && jsonObject instanceof Blob) {
            const item = await saveFile(jsonObject)
            return item.id
        } else {
            return jsonObject
        }
    }
}

export const renameFile = (file) => {
    if (!file) return null
    const extension = file.name.split('.').pop()
    return new File([file], `${uuidv4()}.${extension}`)
}
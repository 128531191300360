import {
    DELETE_P2_REPORTS,

    DELETE_P2_REPORTS_ERROR, DELETE_P2_REPORTS_SUCCESS, GET_P2_REPORTS,
    GET_P2_REPORTS_ERROR,
    GET_P2_REPORTS_SUCCESS,
    POST_P2_REPORTS,
    POST_P2_REPORTS_ERROR,
    POST_P2_REPORTS_SUCCESS
} from '../actions'

export const getP2Reports = () => {
    return {
        type: GET_P2_REPORTS
    }
}

export const getP2ReportsSuccess = (payload) => {
    return {
        type: GET_P2_REPORTS_SUCCESS,
        payload
    }
}

export const getP2ReportsError = () => {
    return {
        type: GET_P2_REPORTS_ERROR
    }
}

export const postP2Report = (payload) => {
    return {
        type: POST_P2_REPORTS,
        payload
    }
}

export const postP2ReportSuccess = (payload) => {
    return {
        type: POST_P2_REPORTS_SUCCESS,
        payload: payload
    }
}

export const postP2ReportError = () => {
    return {
        type: POST_P2_REPORTS_ERROR,
    }
}

export const deleteP2Report = (payload) => {
    return {
        type: DELETE_P2_REPORTS,
        payload
    }
}

export const deleteP2ReportSuccess = (payload) => {
    return {
        type: DELETE_P2_REPORTS_SUCCESS,
        payload: payload
    }
}

export const deleteP2ReportError = () => {
    return {
        type: DELETE_P2_REPORTS_ERROR,
    }
}
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import FuseJS from "fuse.js";
import * as R from "ramda";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
//Actions
import {
  allotmentsAction,
  cloneAllotmentAction,
  destroyAllotmentAction,
} from "../actions/AllotmentsActions";
import { customerAction } from "../actions/CustomersActions";
import Allotment from "../components/Allotment";
import { confirm } from "../components/Confirms/ConfirmDestroy";
// UI Elements
import { useNavigate, useParams } from "react-router";
import ScreenTitle from "../components/ScreenTitle";
import { isReadOnlySelector } from "../reducers/MeReducer";

const searchOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: false,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name", "comment"],
};

const selectEntities = (state) => state.allotments.entities;
const selectEntitiesIds = (customer_id) => (state) => {
  const { waitForSync = [], allotment_ids = [] } =
    state.allotments.ids[customer_id] || {};

  return R.uniq([...allotment_ids, ...waitForSync]);
};
const sortByNameCaseInsensitive = R.sortBy(
  R.compose(R.toLower, R.prop("name"))
);

const selectAllotments = (customer_id) =>
  createSelector(
    selectEntitiesIds(customer_id),
    selectEntities,
    (ids, entities) => {
      return R.compose(
        sortByNameCaseInsensitive,
        R.map((x) => entities[x])
      )(ids);
    }
  );

const AllotmentsContainer = ({}) => {
  const fuseJSRef = useRef(new FuseJS([], searchOptions));
  const { customer_id } = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const isReadOnly = useSelector(isReadOnlySelector)

  useEffect(() => {
    dispatch(allotmentsAction(customer_id));
    dispatch(customerAction(customer_id));
  }, [customer_id]);

  const customer = useSelector(
    (state) => state.customers.entities[customer_id]
  );
  const loading = useSelector((state) => state.allotments.fetching);
  const entities = useSelector(selectAllotments(customer_id));

  useEffect(() => {
    fuseJSRef.current.setCollection(entities);
  }, [entities]);

  const allotments = useMemo(() => {
    if (search === "") return entities;
    const filters = fuseJSRef.current.search(search);
    return R.map((x) => x.item, filters);
  }, [entities, search]);

  const title = useMemo(() => {
    if (!customer) return "Lots";
    return `Lots - ${customer.name}`;
  }, []);

  const onClone = useCallback((allotment) => {
    dispatch(cloneAllotmentAction(customer_id, allotment.id));
  }, []);

  const onDestroy = useCallback(async (allotment) => {
    if (
      await confirm(`Êtes-vous sur de vouloir supprimer ${allotment.name} ?`)
    ) {
      dispatch(destroyAllotmentAction(customer_id, allotment.id));
    }
  }, []);

  return (
    <div>
      <ScreenTitle
        loading={loading}
        onSearch={setSearch}
        back={true}
        button="Retour"
        href={"/dashboard"}
        title={title}
      />
      <Grid container spacing={1}>
        {allotments.map((allotment) => (
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Allotment
              readonly={isReadOnly}
              key={allotment.id}
              onDestroy={onDestroy}
              onClone={onClone}
              data={allotment}
              size={4}
            />
          </Grid>
        ))}
      </Grid>
      {
        !isReadOnly &&
        <Fab
          onClick={() => {
            navigation(`/customers/${customer_id}/lots_new`);
          }}
          color="primary"
          aria-label="add"
          className="fab-add"
        >
          <AddIcon />
        </Fab>
      }
    </div>
  );
};

export default AllotmentsContainer;

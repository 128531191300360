import { object, string } from 'yup'

export const validationSchema = object().shape({
    name: string().required('Le nom est requis'),
    comment: string()
})

export const initialValues = {
    name: '',
    comment: ''
}


import {
    DELETE_COMPARE_RAPPORTS,

    DELETE_COMPARE_RAPPORTS_ERROR, DELETE_COMPARE_RAPPORTS_SUCCESS, GET_COMPARE_RAPPORTS,
    GET_COMPARE_RAPPORTS_ERROR,
    GET_COMPARE_RAPPORTS_SUCCESS,
    POST_COMPARE_RAPPORTS,
    POST_COMPARE_RAPPORTS_ERROR,
    POST_COMPARE_RAPPORTS_SUCCESS
} from '../actions'

export const getCompareRapports = () => {
    return {
        type: GET_COMPARE_RAPPORTS
    }
}

export const getCompareRapportsSuccess = (payload) => {
    return {
        type: GET_COMPARE_RAPPORTS_SUCCESS,
        payload
    }
}

export const getCompareRapportsError = () => {
    return {
        type: GET_COMPARE_RAPPORTS_ERROR
    }
}

export const postCompareRapport = (payload) => {
    return {
        type: POST_COMPARE_RAPPORTS,
        payload
    }
}

export const postCompareRapportSuccess = (payload) => {
    return {
        type: POST_COMPARE_RAPPORTS_SUCCESS,
        payload: payload
    }
}

export const postCompareRapportError = () => {
    return {
        type: POST_COMPARE_RAPPORTS_ERROR,
    }
}

export const deleteCompareRapport = (payload) => {
    return {
        type: DELETE_COMPARE_RAPPORTS,
        payload
    }
}

export const deleteCompareRapportSuccess = (payload) => {
    return {
        type: DELETE_COMPARE_RAPPORTS_SUCCESS,
        payload: payload
    }
}

export const deleteCompareRapportError = () => {
    return {
        type: DELETE_COMPARE_RAPPORTS_ERROR,
    }
}
import { put, call, select } from 'redux-saga/effects'
import { doApplicationStartupSuccess } from '../actions/ApplicationsActions'
import { loginUserSuccessAction } from '../actions/LoginActions'
import { getCookie } from '../utils/Cookies'
import localforage from 'localforage'
import { isEmpty } from 'ramda'
import moment from 'moment'

const REGEX_LOCAL = /^(local):\/\/[^\s$.?#].[^\s]*$/


export function* doStartupApplication() {
    if (getCookie('refresh_token')) {
        yield put(loginUserSuccessAction())
    }
    yield put(doApplicationStartupSuccess())

    const offlinePayload = yield select((state) => state.offline.outbox)

    // Cleanup only if offline queue is empty and the image is 7 days old
    if (isEmpty(offlinePayload)) {
        const keys = yield call([localforage, 'keys'])

        for (const item of keys) {
            if (item.match(REGEX_LOCAL)) {
                const file = yield call([localforage, 'getItem'], item)
                if (file instanceof File) {
                    const timeUpdated = moment(file.lastModified)
                    if (timeUpdated.isAfter(moment().add(7, 'days'))) {
                        yield call([localforage, 'removeItem'], item)
                    }
                }
            }
        }
    }
}
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import * as R from "ramda";
import { createReducer } from "reduxsauce";
import {
  CLONE_ALLOTMENT,
  CLONE_ALLOTMENT_ERROR,
  CLONE_ALLOTMENT_SUCCESS,
  CREATE_ALLOTMENT,
  CREATE_ALLOTMENT_ERROR,
  CREATE_ALLOTMENT_SUCCESS,
  DESTROY_ALLOTMENT,
  DESTROY_ALLOTMENT_ERROR,
  DESTROY_ALLOTMENT_SUCCESS,
  GET_ALLOTMENT,
  GET_ALLOTMENTS,
  GET_ALLOTMENTS_ERROR,
  GET_ALLOTMENTS_SUCCESS,
  GET_ALLOTMENT_ERROR,
  GET_ALLOTMENT_SUCCESS,
  UPDATE_ALLOTMENT,
  UPDATE_ALLOTMENT_ERROR,
  UPDATE_ALLOTMENT_SUCCESS,
} from "../actions";

const intialValues = {
  entities: {},
  ids: {},
  fetching: false,
  error: null,
  versions: {},
};

const AllotmentsSlice = createSlice({
  name: "Allotments",
  initialState: intialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GET_ALLOTMENTS, (state, action) => {
        const {
          payload: { customer_id },
        } = action;

        state.fetching = true;
        state.ids[customer_id] ||= {
          waitForSync: [],
          allotment_ids: [],
        };
      })
      .addCase(GET_ALLOTMENTS_SUCCESS, (state, action) => {
        const {
          payload,
          meta: { customer_id },
        } = action;

        state.fetching = false;
        state.ids[customer_id].allotment_ids = payload.map((x) => x.id);
        payload.forEach((item) => {
          if (state.entities[item.id]) {
            const itemState = state.entities[item.id];
            if (moment(itemState.updated_at).isAfter(item.updated_at)) {
              state.entities[item.id] = item;
            }
          } else {
            state.entities[item.id] = item;
          }
        });
      })
      .addCase(GET_ALLOTMENTS_ERROR, (state) => {
        state.fetching = false;
      })
      .addCase(GET_ALLOTMENT, (state) => {
        state.fetching = true;
      })
      .addCase(GET_ALLOTMENT_ERROR, (state, action) => {
        state.fetching = false;
      })
      .addCase(GET_ALLOTMENT_SUCCESS, (state, action) => {
        const {
          payload,
          meta: { customer_id, id },
        } = action;

        state.entities[payload.id] = payload;
      })
      .addCase(CREATE_ALLOTMENT, (state, action) => {
        const {
          payload,
          meta: { customer_id, id, version },
        } = action;

        state.entities[payload.id] = payload;
        state.ids[customer_id].waitForSync.push(payload.id);
      })
      .addCase(CREATE_ALLOTMENT_SUCCESS, (state, action) => {
        const {
          payload,
          meta: { customer_id, id, version },
        } = action;

        state.ids[customer_id].allotment_ids.push(payload.id);
        state.entities[payload.id] = payload;
        const index = state.ids[customer_id].waitForSync.findIndex(
          (x) => x === payload.id
        );
        if (index >= 0) {
          state.ids[customer_id].waitForSync.splice(index, 1);
        }
      })
      .addCase(UPDATE_ALLOTMENT, (state, action) => {
        const {
          payload,
          meta: { customer_id, id, version },
        } = action;

        state.entities[payload.id] = payload;
      })
      .addCase(UPDATE_ALLOTMENT_SUCCESS, (state, action) => {
        const {
          payload,
          meta: { customer_id, id, version },
        } = action;

        state.entities[payload.id] = payload;
      })
      .addCase(CLONE_ALLOTMENT, (state) => {
        state.fetching = true;
      })
      .addCase(CLONE_ALLOTMENT_ERROR, (state) => {
        state.fetching = false;
      })
      .addCase(CLONE_ALLOTMENT_SUCCESS, (state, action) => {
        const {
          payload,
          meta: { customer_id },
        } = action;

        state.fetching = false;
        state.entities[payload.id] = payload;
        state.ids[customer_id].allotment_ids.push(payload.id);
      })
      .addCase(DESTROY_ALLOTMENT, (state) => {
        state.fetching = true;
      })
      .addCase(DESTROY_ALLOTMENT_ERROR, (state) => {
        state.fetching = false;
      })
      .addCase(DESTROY_ALLOTMENT_SUCCESS, (state, action) => {
        const {
          payload: { customer_id, id },
        } = action;

        state.fetching = false;
        if (state.entities[id]) {
          delete state.entities[id];
        }
        const index = state.ids[customer_id].allotment_ids.findIndex(
          (x) => x === id
        );

        if (index >= 0) {
          state.ids[customer_id].allotment_ids.splice(index, 1);
        }
      });
  },
});

export default AllotmentsSlice.reducer;

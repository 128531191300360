export const APPLICATION_STARTUP = '@@APPLICATION_STARTUP'
export const APPLICATION_STARTUP_SUCCESS = '@@APPLICATION_STARTUP_SUCCESS'

export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const LOGOUT_REQUEST = '@LOGOUT_REQUEST'

export const LOGIN_START_SYNC = 'LOGIN_START_SYNC'

export const GET_ME = 'GET_ME'
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS'
export const GET_ME_ERROR = 'GET_ME_ERROR'

export const UPDATE_ME = 'UPDATE_ME'
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS'
export const UPDATE_ME_ERROR = 'UPDATE_ME_ERROR'

export const GET_TRADEMARKS = 'GET_TRADEMARKS'
export const GET_TRADEMARKS_SUCCESS = 'GET_TRADEMARKS_SUCCESS'
export const GET_TRADEMARKS_ERROR = 'GET_TRADEMARKS_ERROR'
export const DESTROY_TRADEMARKS = 'DESTROY_TRADEMARKS'
export const DESTROY_TRADEMARKS_SUCCESS = 'DESTROY_TRADEMARKS_SUCCESS'
export const DESTROY_TRADEMARKS_ERROR = 'DESTROY_TRADEMARKS_ERROR'
export const SET_TRADEMARKS_FROM_SYNC = 'SET_TRADEMARKS_FROM_SYNC'
export const SET_TRADEMARKS_FROM_SYNC_DELETION = 'SET_TRADEMARKS_FROM_SYNC_DELETION'

export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS'
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR'

export const GET_CUSTOMER = 'GET_CUSTOMER'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR'

export const CLONE_CUSTOMER = 'CLONE_CUSTOMER'
export const CLONE_CUSTOMER_SUCCESS = 'CLONE_CUSTOMER_SUCCESS'
export const CLONE_CUSTOMER_ERROR = 'CLONE_CUSTOMER_ERROR'

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR'

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR'

export const GET_VISITS = 'GET_VISITS'
export const GET_VISITS_SUCCESS = 'GET_VISITS_SUCCESS'
export const GET_VISITS_ERROR = 'GET_VISITS_ERROR'

export const RESET_VISIT = 'RESET_VISIT'

export const GET_VISIT = 'GET_VISIT'
export const GET_VISIT_SUCCESS = 'GET_VISIT_SUCCESS'
export const GET_VISIT_ERROR = 'GET_VISIT_ERROR'

export const DESTROY_VISIT = 'DESTROY_VISIT'
export const DESTROY_VISIT_SUCCESS = 'DESTROY_VISIT_SUCCESS'
export const DESTROY_VISIT_ERROR = 'DESTROY_VISIT_ERROR'

export const UPDATE_VISIT = 'UPDATE_VISIT'
export const UPDATE_VISIT_SUCCESS = 'UPDATE_VISIT_SUCCESS'
export const UPDATE_VISIT_ERROR = 'UPDATE_VISIT_ERROR'

export const CREATE_VISIT = 'CREATE_VISIT'
export const CREATE_VISIT_SUCCESS = 'CREATE_VISIT_SUCCESS'
export const CREATE_VISIT_ERROR = 'CREATE_VISIT_ERROR'

export const CLONE_VISIT = 'CLONE_VISIT'
export const CLONE_VISIT_SUCCESS = 'CLONE_VISIT_SUCCESS'
export const CLONE_VISIT_ERROR = 'CLONE_VISIT_ERROR'

export const GET_ENERGY_TYPES = 'GET_ENERGY_TYPES'
export const GET_ENERGY_TYPES_SUCCESS = 'GET_ENERGY_TYPES_SUCCESS'
export const GET_ENERGY_TYPES_ERROR = 'GET_ENERGY_TYPES_ERROR'

export const GET_CLIMATE_ZONES = 'GET_CLIMATE_ZONES'
export const GET_CLIMATE_ZONES_SUCCESS = 'GET_CLIMATE_ZONES_SUCCESS'
export const GET_CLIMATE_ZONES_ERROR = 'GET_CLIMATE_ZONES_ERROR'

export const GET_CONTRACT_TYPES = 'GET_CONTRACT_TYPES'
export const GET_CONTRACT_TYPES_SUCCESS = 'GET_CONTRACT_TYPES_SUCCESS'
export const GET_CONTRACT_TYPES_ERROR = 'GET_CONTRACT_TYPES_ERROR'

export const GET_ROOTS_CATEGORIES = 'GET_ROOTS_CATEGORIES'
export const GET_ROOTS_CATEGORIES_SUCCESS = 'GET_ROOTS_CATEGORIES_SUCCESS'
export const GET_ROOTS_CATEGORIES_ERROR = 'GET_ROOTS_CATEGORIES_ERROR'

export const GET_CATEGORY = 'GET_CATEGORY'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR'

export const SET_CATEGORY_FROM_SYNC = 'SET_CATEGORY_FROM_SYNC'
export const SET_CATEGORY_FROM_SYNC_DELETION = 'SET_CATEGORY_FROM_SYNC_DELETION'

export const SAVE_CATEGORY = 'SAVE_CATEGORY'
export const SAVE_CATEGORY_SUCCESS = 'SAVE_CATEGORY_SUCCESS'
export const SAVE_CATEGORY_ERROR = 'SAVE_CATEGORY_ERROR'

export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES'
export const GET_PRODUCT_TYPES_SUCCESS = 'GET_PRODUCT_TYPES_SUCCESS'
export const GET_PRODUCT_TYPES_ERROR = 'GET_PRODUCT_TYPES_ERROR'

export const SET_PRODUCT_TYPES_FROM_SYNC = 'SET_PRODUCT_TYPES_FROM_SYNC'
export const SET_PRODUCT_TYPES_FROM_SYNC_DELETION = 'SET_PRODUCT_TYPES_FROM_SYNC_DELETION'

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'

export const SET_PRODUCT_FROM_SYNC = 'SET_PRODUCT_FROM_SYNC'
export const SET_PRODUCT_FROM_SYNC_DELETION = 'SET_PRODUCT_FROM_SYNC_DELETION'

export const ADD_PRODUCT_VISIT = 'ADD_PRODUCT_VISIT'
export const NEW_PRODUCT_VISIT = 'NEW_PRODUCT_VISIT'
export const TOGGLE_PRODUCT_VISIT_POPUP = 'TOGGLE_PRODUCT_VISIT_POPUP'
export const UPDATE_PRODUCT_VISIT = 'UPDATE_PRODUCT_VISIT'
export const CLEAN_PRODUCT_VISIT = 'CLEAN_PRODUCT_VISIT'

export const SET_CATEGORY = 'SET_CATEGORY'
export const RESET_CATEGORY = 'RESET_CATEGORY'

export const SET_CURRENT_TRADEMARK = 'SET_CURRENT_TRADEMARK'
export const RESET_CURRENT_TRADEMARK = 'RESET_CURRENT_TRADEMARK'

export const SET_CURRENT_PRODUCT_TYPE = 'SET_CURRENT_PRODUCT_TYPE'
export const RESET_CURRENT_PRODUCT_TYPE = 'RESET_CURRENT_PRODUCT_TYPE'

export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT'
export const RESET_CURRENT_PRODUCT = 'RESET_CURRENT_PRODUCT'

export const RESET_PRODUCTS = 'RESET_PRODUCTS'
export const RESET_PRODUCT_TYPES = 'RESET_PRODUCT_TYPES'

export const RESET_LOCATION = 'RESET_LOCATION'

export const GET_LOCATION = 'GET_LOCATION'
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS'
export const GET_LOCATION_ERROR = 'GET_LOCATION_ERROR'

export const CLONE_LOCATION = 'CLONE_LOCATION'
export const CLONE_LOCATION_SUCCESS = 'CLONE_LOCATION_SUCCESS'
export const CLONE_LOCATION_ERROR = 'CLONE_LOCATION_ERROR'

export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR'

export const DESTROY_LOCATION = 'DESTROY_LOCATION'
export const DESTROY_LOCATION_SUCCESS = 'DESTROY_LOCATION_SUCCESS'
export const DESTROY_LOCATION_ERROR = 'DESTROY_LOCATION_ERROR'

export const CREATE_LOCATION = 'CREATE_LOCATION'
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS'
export const CREATE_LOCATION_ERROR = 'CREATE_LOCATION_ERROR'

export const UPDATE_LOCATION = 'UPDATE_LOCATION'
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS'
export const UPDATE_LOCATION_ERROR = 'UPDATE_LOCATION_ERROR'

export const RESET_ALLOTMENT = 'RESET_ALLOTMENT'

export const GET_ALLOTMENT = 'GET_ALLOTMENT'
export const GET_ALLOTMENT_SUCCESS = 'GET_ALLOTMENT_SUCCESS'
export const GET_ALLOTMENT_ERROR = 'GET_ALLOTMENT_ERROR'

export const CLONE_ALLOTMENT = 'CLONE_ALLOTMENT'
export const CLONE_ALLOTMENT_SUCCESS = 'CLONE_ALLOTMENT_SUCCESS'
export const CLONE_ALLOTMENT_ERROR = 'CLONE_ALLOTMENT_ERROR'

export const DESTROY_ALLOTMENT = 'DESTROY_ALLOTMENT'
export const DESTROY_ALLOTMENT_SUCCESS = 'DESTROY_ALLOTMENT_SUCCESS'
export const DESTROY_ALLOTMENT_ERROR = 'DESTROY_ALLOTMENT_ERROR'

export const GET_ALLOTMENTS = 'GET_ALLOTMENTS'
export const GET_ALLOTMENTS_SUCCESS = 'GET_ALLOTMENTS_SUCCESS'
export const GET_ALLOTMENTS_ERROR = 'GET_ALLOTMENTS_ERROR'

export const GET_ALLOTMENTS_STACK = 'GET_ALLOTMENTS_STACK'
export const GET_ALLOTMENTS_STACK_SUCCESS = 'GET_ALLOTMENTS_STACK_SUCCESS'
export const GET_ALLOTMENTS_STACK_ERROR = 'GET_ALLOTMENTS_STACK_ERROR'

export const CREATE_ALLOTMENT = 'CREATE_ALLOTMENT'
export const CREATE_ALLOTMENT_SUCCESS = 'CREATE_ALLOTMENT_SUCCESS'
export const CREATE_ALLOTMENT_ERROR = 'CREATE_ALLOTMENT_ERROR'

export const UPDATE_ALLOTMENT = 'UPDATE_ALLOTMENT'
export const UPDATE_ALLOTMENT_SUCCESS = 'UPDATE_ALLOTMENT_SUCCESS'
export const UPDATE_ALLOTMENT_ERROR = 'UPDATE_ALLOTMENT_ERROR'

export const PRODUCT_ADD_REQUEST_PRODUCT_TYPE = 'PRODUCT_ADD_REQUEST_PRODUCT_TYPE'
export const PRODUCT_ADD_REQUEST_PRODUCT_TYPE_SUCCESS = 'PRODUCT_ADD_REQUEST_PRODUCT_TYPE_SUCCESS'
export const PRODUCT_ADD_REQUEST_PRODUCT_TYPE_ERROR = 'PRODUCT_ADD_REQUEST_PRODUCT_TYPE_ERROR'
export const PRODUCT_ADD_REQUEST_PRODUCT = 'PRODUCT_ADD_REQUEST_PRODUCT'
export const PRODUCT_ADD_REQUEST_PRODUCT_SUCCESS = 'PRODUCT_ADD_REQUEST_PRODUCT_SUCCESS'
export const PRODUCT_ADD_REQUEST_PRODUCT_ERROR = 'PRODUCT_ADD_REQUEST_PRODUCT_ERROR'
export const PRODUCT_ADD_REQUEST = 'PRODUCT_ADD_REQUEST'
export const PRODUCT_ADD_REQUEST_SUCCESS = 'PRODUCT_ADD_REQUEST_SUCCESS'
export const PRODUCT_ADD_REQUEST_ERROR = 'PRODUCT_ADD_REQUEST_ERROR'


export const GET_BUILDINDS = '@@GET_BUILDINDS'
export const GET_BUILDINDS_SUCCESS = '@@GET_BUILDINDS_SUCCESS'
export const GET_BUILDINDS_ERROR = '@@GET_BUILDINDS_ERROR'

export const POST_BUILDIND = '@@POST_BUILDIND'
export const POST_BUILDIND_SUCCESS = '@@POST_BUILDIND_SUCCESS'
export const POST_BUILDIND_ERROR = '@@POST_BUILDIND_ERROR'

export const PUT_BUILDIND = '@@PUT_BUILDIND'
export const PUT_BUILDIND_SUCCESS = '@@PUT_BUILDIND_SUCCESS'
export const PUT_BUILDIND_ERROR = '@@PUT_BUILDIND_ERROR'

export const DELETE_BUILDIND = '@@DELETE_BUILDIND'
export const DELETE_BUILDIND_SUCCESS = '@@DELETE_BUILDIND_SUCCESS'
export const DELETE_BUILDIND_ERROR = '@@DELETE_BUILDIND_ERROR'

export const CLONE_BUILDIND = '@@CLONE_BUILDIND'
export const CLONE_BUILDIND_SUCCESS = '@@CLONE_BUILDIND_SUCCESS'
export const CLONE_BUILDIND_ERROR = '@@CLONE_BUILDIND_ERROR'

export const GET_RAPPORTS = '@@GET_RAPPORTS'
export const GET_RAPPORTS_SUCCESS = '@@GET_RAPPORTS_SUCCESS'
export const GET_RAPPORTS_ERROR = '@@GET_RAPPORTS_ERROR'

export const POST_RAPPORTS = '@@POST_RAPPORTS'
export const POST_RAPPORTS_SUCCESS = '@@POST_RAPPORTS_SUCCESS'
export const POST_RAPPORTS_ERROR = '@@POST_RAPPORTS_ERROR'

export const DELETE_RAPPORTS = '@@DELETE_RAPPORTS'
export const DELETE_RAPPORTS_SUCCESS = '@@DELETE_RAPPORTS_SUCCESS'
export const DELETE_RAPPORTS_ERROR = '@@DELETE_RAPPORTS_ERROR'

export const GET_COMPARE_RAPPORTS = '@@GET_COMPARE_RAPPORTS'
export const GET_COMPARE_RAPPORTS_SUCCESS = '@@GET_COMPARE_RAPPORTS_SUCCESS'
export const GET_COMPARE_RAPPORTS_ERROR = '@@GET_COMPARE_RAPPORTS_ERROR'

export const POST_COMPARE_RAPPORTS = '@@POST_COMPARE_RAPPORTS'
export const POST_COMPARE_RAPPORTS_SUCCESS = '@@POST_COMPARE_RAPPORTS_SUCCESS'
export const POST_COMPARE_RAPPORTS_ERROR = '@@POST_COMPARE_RAPPORTS_ERROR'

export const DELETE_COMPARE_RAPPORTS = '@@DELETE_COMPARE_RAPPORTS'
export const DELETE_COMPARE_RAPPORTS_SUCCESS = '@@DELETE_COMPARE_RAPPORTS_SUCCESS'
export const DELETE_COMPARE_RAPPORTS_ERROR = '@@DELETE_COMPARE_RAPPORTS_ERROR'

export const GET_P2_REPORTS = '@@GET_P2_REPORTS'
export const GET_P2_REPORTS_SUCCESS = '@@GET_P2_REPORTS_SUCCESS'
export const GET_P2_REPORTS_ERROR = '@@GET_P2_REPORTS_ERROR'

export const POST_P2_REPORTS = '@@POST_P2_REPORTS'
export const POST_P2_REPORTS_SUCCESS = '@@POST_P2_REPORTS_SUCCESS'
export const POST_P2_REPORTS_ERROR = '@@POST_P2_REPORTS_ERROR'

export const DELETE_P2_REPORTS = '@@DELETE_P2_REPORTS'
export const DELETE_P2_REPORTS_SUCCESS = '@@DELETE_P2_REPORTS_SUCCESS'
export const DELETE_P2_REPORTS_ERROR = '@@DELETE_P2_REPORTS_ERROR'

export const GET_P3_REPORTS = '@@GET_P3_REPORTS'
export const GET_P3_REPORTS_SUCCESS = '@@GET_P3_REPORTS_SUCCESS'
export const GET_P3_REPORTS_ERROR = '@@GET_P3_REPORTS_ERROR'

export const POST_P3_REPORTS = '@@POST_P3_REPORTS'
export const POST_P3_REPORTS_SUCCESS = '@@POST_P3_REPORTS_SUCCESS'
export const POST_P3_REPORTS_ERROR = '@@POST_P3_REPORTS_ERROR'

export const DELETE_P3_REPORTS = '@@DELETE_P3_REPORTS'
export const DELETE_P3_REPORTS_SUCCESS = '@@DELETE_P3_REPORTS_SUCCESS'
export const DELETE_P3_REPORTS_ERROR = '@@DELETE_P3_REPORTS_ERROR'

export const REFRESH_TOKEN_REQUEST = '@@REFRESH_TOKEN_REQUEST'
export const REFRESH_TOKEN_SUCCESS = '@@REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_ERROR = '@@REFRESH_TOKEN_ERROR'

export const OFFICE_REQUEST = 'OFFICE_REQUEST'
export const OFFICE_SUCCESS = 'OFFICE_SUCCESS'
export const OFFICE_ERROR = 'OFFICE_ERROR'

export const GET_IMAGE_BANKS = '@@GET_IMAGE_BANKS'
export const GET_IMAGE_BANKS_SUCCESS = '@@GET_IMAGE_BANKS_SUCCESS'
export const GET_IMAGE_BANKS_ERROR = '@@GET_IMAGE_BANKS_ERROR'

export const CREATE_IMAGE_BANK = '@@CREATE_IMAGE_BANK'
export const CREATE_IMAGE_BANK_SUCCESS = '@@CREATE_IMAGE_BANK_SUCCESS'
export const CREATE_IMAGE_BANK_ERROR = '@@CREATE_IMAGE_BANK_ERROR'

export const UPDATE_IMAGE_BANK = '@@UPDATE_IMAGE_BANK'
export const UPDATE_IMAGE_BANK_SUCCESS = '@@UPDATE_IMAGE_BANK_SUCCESS'
export const UPDATE_IMAGE_BANK_ERROR = '@@UPDATE_IMAGE_BANK_ERROR'

export const POST_IMAGE_BANKS = '@@POST_IMAGE_BANKS'
export const POST_IMAGE_BANKS_SUCCESS = '@@POST_IMAGE_BANKS_SUCCESS'
export const POST_IMAGE_BANKS_ERROR = '@@POST_IMAGE_BANKS_ERROR'

export const GET_IMAGE_BANK = '@@GET_IMAGE_BANK'
export const GET_IMAGE_BANK_SUCCESS = '@@GET_IMAGE_BANK_SUCCESS'
export const GET_IMAGE_BANK_ERROR = '@@GET_IMAGE_BANK_ERROR'

export const GET_PUBLIC_IMAGE_BANK = '@@GET_PUBLIC_IMAGE_BANK'
export const GET_PUBLIC_IMAGE_BANK_SUCCESS = '@@GET_PUBLIC_IMAGE_BANK_SUCCESS'
export const GET_PUBLIC_IMAGE_BANK_ERROR = '@@GET_PUBLIC_IMAGE_BANK_ERROR'

export const DELETE_IMAGE_BANKS = '@@DELETE_IMAGE_BANKS'
export const DELETE_IMAGE_BANKS_SUCCESS = '@@DELETE_IMAGE_BANKS_SUCCESS'
export const DELETE_IMAGE_BANKS_ERROR = '@@DELETE_IMAGE_BANKS_ERROR'
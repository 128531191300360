import React from 'react'
import ScreenTitle from '../../components/ScreenTitle'
import FormContainer from './FormContainer'

const RapportCreateContainer = () => {
    return (
        <div>
            <ScreenTitle back={true} button='Retour' href='/rapports' title="Création d'un rapport" />
            <FormContainer />
        </div>
    )
}

export default RapportCreateContainer
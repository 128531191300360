import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { Form, Formik } from 'formik'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { object, string } from 'yup'
import { loginUserAction } from '../actions/LoginActions'
import TextField from '../components/forms/Formik/Material/TextField'
import ScreenTitle from '../components/ScreenTitle'
import { useLocation, useNavigate } from 'react-router'

const loginValidation = object().shape({
  email: string().required('Est requis').email('Doit être valide'),
  password: string().required('Est requis')
})

const LoginContainer = () => {
  const location = useLocation()
  const navigation = useNavigate()
  const dispatch = useDispatch()

  const { error, isConnected } = useSelector((state) => {
    return {
      error: state.login.error,
      isConnected: state.login.isAuthentified
    }
  })
  const onSubmit = useCallback((values, formikCtx) => {
    const { state: { from = null } } = location
    formikCtx.setSubmitting(true)
    dispatch(loginUserAction(values.email, values.password, formikCtx, from))
  }, [])

  useEffect(() => {
    if (isConnected) {
      navigation('/dashboard', { replace: true })
    }
  }, [])

  return (
    <div>
      <ScreenTitle title="Application GEMA" />
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={loginValidation}
      >
        <Form autoComplete="off">
          {
            error &&
            <>
              <Alert variant="filled" severity="error">{error}</Alert>
              <div style={{ height: '25px' }}></div>
            </>
          }
          <TextField
            variant="outlined"
            label="Email"
            name='email'
            fullWidth
          />
          <div style={{ height: '50px' }}></div>
          <TextField
            variant="outlined"
            label="Mot de passe"
            type="password"
            name='password'
            fullWidth
          />
          <div style={{ height: '50px' }}></div>
          <Button variant="contained" color="primary" type="submit">
            Connexion
          </Button>
        </Form>
      </Formik>
    </div>
  )
}

export default LoginContainer
import { flashErrorMessage, flashMessage } from "redux-flash";
import { call, put, select } from "redux-saga/effects";
import {
  cloneLocationErrorAction,
  cloneLocationSuccessAction,
  destroyLocationErrorAction,
  destroyLocationSuccessAction,
  getLocationActionError,
  getLocationActionSuccess,
  locationsActionsError,
  locationsActionsSuccess,
} from "../actions/LocationsActions";
import {
  cloneLocation,
  destroyLocation,
  getLocation,
  getLocations,
} from "../services/Api";

export function* locationsSaga(action) {
  const {
    payload: { customer_id, allotment_id },
  } = action;
  try {
    const data = yield call(getLocations, customer_id, allotment_id);
    yield put(locationsActionsSuccess(customer_id, allotment_id, data));
  } catch (error) {
    yield put(locationsActionsError(customer_id, allotment_id, error.message));
  }
}

export function* getLocationSaga(action) {
  const {
    payload: { id },
  } = action;
  try {
    const data = yield call(getLocation, id);
    yield put(getLocationActionSuccess(id, data));
  } catch (error) {
    yield put(getLocationActionError(id, error.message));
  }
}

export function* destroyLocationSaga(actions) {
  const {
    payload: { id },
    meta: { customer_id, allotment_id },
  } = actions;

  const isOnline = yield select((state) => state.offline.online);

  if (!isOnline) {
    yield put(
      flashErrorMessage(
        "Impossible de supprimer cet élément en étant en mode offline!"
      )
    );
    yield put(destroyLocationErrorAction(customer_id, allotment_id, id));
    return;
  }

  try {
    const rsp = yield call(destroyLocation, id);
    yield put(flashMessage("Elément supprimé"));
    yield put(destroyLocationSuccessAction(customer_id, allotment_id, id));
  } catch (e) {
    yield put(destroyLocationErrorAction(customer_id, allotment_id, id));
  }
}

export function* cloneLocationSaga(actions) {
  const {
    payload: { id },
    meta: { customer_id, allotment_id },
  } = actions;

  const isOnline = yield select((state) => state.offline.online);

  if (!isOnline) {
    yield put(
      flashErrorMessage(
        "Impossible de cloner cet élément en étant en mode offline!"
      )
    );
    yield put(cloneLocationErrorAction(customer_id, allotment_id, id));
    return;
  }
  try {
    const rsp = yield call(cloneLocation, id);
    yield put(cloneLocationSuccessAction(customer_id, allotment_id, rsp));
    yield put(flashMessage("Elément cloné"));
  } catch (e) {
    yield put(cloneLocationErrorAction(customer_id, allotment_id, id));
  }
}

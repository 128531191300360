import Badge from '@mui/material/Badge'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
// UI Elements
import Typography from '@mui/material/Typography'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Location.module.css'

function Location(props) {
  const { data } = props

  const edit = data.allotment_id ? `/customers/${data.customer_id}/lots/${data.allotment_id}/locations/${data.id}/edit` : `/customers/${data.customer_id}/locations/${data.id}/edit`
  const visits = data.allotment_id ? `/customers/${data.customer_id}/lots/${data.allotment_id}/locations/${data.id}/visits` : `/customers/${data.customer_id}/locations/${data.id}/visits`

  return (
    <Card className={styles.card} >
      <CardContent>
        <Typography variant="h6">
          {props.data.label}
        </Typography>

        <Typography variant="p">
          {data.allotment_id && data.allotment && data.allotment.name}
          <br />
          {props.data.street} {props.data.zip} {props.data.city}
        </Typography>
      </CardContent>
      <CardActions>
        {
          !props.readonly &&
          <>
            <button className="btn btn-sm btn-danger" onClick={() => { props.onDestroyLocation(props.data) }}>
              Supprimer
            </button>
            <Link className="btn btn-sm btn-warning ml-2" to={edit}>
              Modifier
            </Link>
            <a href='#' className="btn btn-sm btn-info ml-2" onClick={(e) => {
              e.preventDefault()
              props.onClone(props.data)
            }}>
              Dupliquer
            </a>
          </>
        }
        <Badge className='ml-2' color="secondary" badgeContent={props.data.visits_counter}>
          <Link className="btn btn-sm btn-success" to={visits}>
            Visites
          </Link>
        </Badge>
      </CardActions>
    </Card>
  )
}

export default Location

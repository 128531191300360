import * as types from './index'

export const trademarksAction = (token) => {
  return {
    type: types.GET_TRADEMARKS,
    token
  }
}

export const destroyTrademarksAction = (id) => {
  return {
    type: types.DESTROY_TRADEMARKS,
    payload: { id }
  }
}

export const destroyTrademarksSuccessActions = (id) => {
  return {
    type: types.DESTROY_TRADEMARKS_SUCCESS,
    payload: { id }
  }
}

export const destroyTrademarksFailureActions = (id) => {
  return {
    type: types.DESTROY_TRADEMARKS_ERROR,
    payload: { id }
  }
}

export const setTrademarkFromSync = (payload) => {
  return {
    type: types.SET_TRADEMARKS_FROM_SYNC,
    payload
  }
}

export const setTrademarkFromSyncDeletion = (payload) => {
  return {
    type: types.SET_TRADEMARKS_FROM_SYNC_DELETION,
    payload
  }
}
import { put, call } from "redux-saga/effects";
import { ProductsVisitsActions } from "../reducers/ProductsVisitsReducer";
import { getProductsVisitsByVisitID } from "../services/Api";

export function* getProductsVisitsFromVisit(actions) {
  const {
    payload: { visit_id },
  } = actions;

  try {
    const rsp = yield call(getProductsVisitsByVisitID, visit_id);
    yield put(
      ProductsVisitsActions.requestFromVisitIdSuccess(rsp, { visit_id })
    );
  } catch (e) {
    yield put(
      ProductsVisitsActions.requestFromVisitIdError(
        e.response.status.toString(),
        { visit_id }
      )
    );
  }
}

import {
    DELETE_P3_REPORTS,

    DELETE_P3_REPORTS_ERROR, DELETE_P3_REPORTS_SUCCESS, GET_P3_REPORTS,
    GET_P3_REPORTS_ERROR,
    GET_P3_REPORTS_SUCCESS,
    POST_P3_REPORTS,
    POST_P3_REPORTS_ERROR,
    POST_P3_REPORTS_SUCCESS
} from '../actions'

export const getP3Reports = () => {
    return {
        type: GET_P3_REPORTS
    }
}

export const getP3ReportsSuccess = (payload) => {
    return {
        type: GET_P3_REPORTS_SUCCESS,
        payload
    }
}

export const getP3ReportsError = () => {
    return {
        type: GET_P3_REPORTS_ERROR
    }
}

export const postP3Report = (payload) => {
    return {
        type: POST_P3_REPORTS,
        payload
    }
}

export const postP3ReportSuccess = (payload) => {
    return {
        type: POST_P3_REPORTS_SUCCESS,
        payload: payload
    }
}

export const postP3ReportError = () => {
    return {
        type: POST_P3_REPORTS_ERROR,
    }
}

export const deleteP3Report = (payload) => {
    return {
        type: DELETE_P3_REPORTS,
        payload
    }
}

export const deleteP3ReportSuccess = (payload) => {
    return {
        type: DELETE_P3_REPORTS_SUCCESS,
        payload: payload
    }
}

export const deleteP3ReportError = () => {
    return {
        type: DELETE_P3_REPORTS_ERROR,
    }
}
import { Button, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import * as R from 'ramda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { customersAction } from '../../actions/CustomersActions'
import { postRapport } from '../../actions/RapportsAction'
import DateField from '../../components/forms/Formik/DateField'
import SelectField from '../../components/forms/Formik/SelectField'
import TextAreaField from '../../components/forms/Formik/TextAreaField'
import TextField from '../../components/forms/Formik/TextField'
import LocationsForm from './LocationsForm'
import { OfficeRequest } from '../../actions/OfficesActions'

const initialValues = {
    customer_id: '',
    name: '',
    market_object: '',
    visit_ids: [],
    date: null,
    office_id: null
}

const schemaValidation = Yup.object().shape({
    date: Yup.date('').required('doit être renseigné'),
    customer_id: Yup.string().required('doit être renseigné'),
    name: Yup.string().required('doit être renseigné'),
    visit_ids: Yup.array().min(1, 'Une visite doit être cochée'),
    office_id: Yup.string('doit être renseigné').required('doit être renseigné'),
})

const FormContainer = () => {
    const dispatch = useDispatch()
    const { customers_ids, customers_entities, offices } = useSelector((state) => {
        return {
            customers_entities: state.customers.entities,
            customers_ids: state.customers.customers_ids,
            offices: state.offices.data
        }
    })

    const customers = useMemo(() => {
        return R.map((x) => {
            return customers_entities[x]
        }, customers_ids)

    }, [customers_entities, customers_ids])

    useEffect(() => {
        dispatch(customersAction())
        dispatch(OfficeRequest())
    }, [])

    const onSubmit = useCallback((values) => {
        dispatch(postRapport(values))
    }, [])

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={schemaValidation}
        >
            {
                ({ handleSubmit, values, ...props }) => {
                    return (
                        <Form autoComplete="off">
                            <Grid container spacing={0}>
                                <Grid item xs={6}>
                                    <DateField name='date' label='Date' />
                                    <SelectField label='Client' name='customer_id'>
                                        <option value=''>Selectionner...</option>
                                        {
                                            customers.map((customer) => {
                                                return (
                                                    <option value={customer.id.toString()}>{customer.name}</option>
                                                )
                                            })
                                        }
                                    </SelectField>
                                    <SelectField label='Bureau' name='office_id'>
                                        <option value=''>Selectionner...</option>
                                        {
                                            offices.map((office) => {
                                                return (
                                                    <option value={office.id.toString()}>{office.label}</option>
                                                )
                                            })
                                        }
                                    </SelectField>
                                    <TextField name='name' label='Titre du rapport' />
                                    <TextAreaField name='market_object' label='Objet du marché' />
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                        <Button onClick={handleSubmit} variant="contained" color="primary" type="submit">
                                            Créer
                                        </Button>
                                    </Grid>
                                </Grid>
                                {
                                    values.customer_id !== '' &&
                                    <Grid item xs={6}>
                                        <LocationsForm error={R.path(['errors', 'visit_ids'], props)} setValues={props.setFieldValue} customer_id={values.customer_id} />
                                    </Grid>
                                }
                            </Grid>
                        </Form>
                    )
                }
            }
        </Formik>
    )
}

export default FormContainer
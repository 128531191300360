import { flashErrorMessage, flashMessage } from "redux-flash";
import { call, put, select } from 'redux-saga/effects';
import {
  allotmentsActionError, allotmentsActionSuccess, cloneAllotmentErrorAction,
  cloneAllotmentSuccessAction,




  destroyAllotmentActionError,
  destroyAllotmentActionSuccess, getAllotmentActionError, getAllotmentActionSuccess
} from '../actions/AllotmentsActions';
import { locationsAction } from '../actions/LocationsActions';
import { cloneAllotment, destroyAllotment, getAllotment, getAllotments } from '../services/Api';


export function* allotmentsSaga(action) {
  const { payload: { customer_id, load_location } } = action

  try {
    const data = yield call(getAllotments, customer_id)
    yield put(allotmentsActionSuccess(customer_id, data))
    if (load_location) {
      yield* data.map((x) => put(locationsAction(customer_id, x.id)))
    }
  } catch (error) {
    yield put(allotmentsActionError(customer_id, error.message))
  }
}

export function* getAllotmentSaga(action) {
  const { payload: { customer_id, id } } = action
  try {
    const data = yield call(getAllotment, id)
    yield put(getAllotmentActionSuccess(customer_id, id, data))
  } catch (error) {
    yield put(getAllotmentActionError(customer_id, id, error.message))
  }
}

export function* cloneAllotmentSaga(action) {
  const { payload: { id }, meta: { customer_id } } = action

  const isOnline = yield select((state) => state.offline.online)

  if (!isOnline) {
    yield put(flashErrorMessage('Impossible de cloner cet élément en étant en mode offline!'))
    yield put(cloneAllotmentErrorAction(customer_id, id, null))
    return
  }

  try {
    const rsp = yield call(cloneAllotment, id)
    yield put(cloneAllotmentSuccessAction(customer_id, id, rsp))
    yield put(flashMessage('Elément cloné'))
  } catch (e) {
    yield put(cloneAllotmentErrorAction(customer_id, id, e.message))
  }
}

export const destroyAllotmentSaga = function* (action) {
  const { payload: { customer_id, id } } = action

  const isOnline = yield select((state) => state.offline.online)

  if (!isOnline) {
    yield put(flashErrorMessage('Impossible de supprimer cet élément en étant en mode offline!'))
    yield put(destroyAllotmentActionError(customer_id, id))
    return
  }
  try {
    yield call(destroyAllotment, id)
    yield put(destroyAllotmentActionSuccess(customer_id, id))
    yield put(flashMessage('Elément supprimé'))
  } catch (e) {
    yield put(destroyAllotmentActionError(customer_id, id))
  }
}
import { Formik } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { flashMessage } from "redux-flash";
import { v4 as uuidv4 } from "uuid";
import { getAllotmentAction } from "../../../actions/AllotmentsActions";
import { customerAction } from "../../../actions/CustomersActions";
import { getLocationAction } from "../../../actions/LocationsActions";
import { createVisitAction } from "../../../actions/VisitsActions";
import VisitForm from "../../../components/forms/VisitForm";
import ScreenTitle from "../../../components/ScreenTitle";
import { initialValues, validationSchema } from "../../../Models/Visit";
import { saveJSONFileInStorage, saveJSONInStorage } from "../../../utils/JsonTools";
import { VisitsActions } from "../../../reducers/VisitsReducer";
import * as R from "ramda";

const CreateVisitContainer = ({}) => {
  const navigation = useNavigate();
  const { customer_id, allotment_id, location_id } = useParams();
  const dispatch = useDispatch();

  const customer = useSelector(
    (state) => state.customers.entities[customer_id]
  );
  const allotment = useSelector(
    (state) => state.allotments.entities[allotment_id]
  );
  const location = useSelector(
    (state) => state.locations.entities[location_id]
  );

  const { contract_types, energy_types, climate_zones } = useSelector(
    (state) => {
      return {
        contract_types: R.compose(
          R.defaultTo([]),
          R.path(["contract_types", "response"])
        )(state),
        energy_types: R.compose(
          R.defaultTo([]),
          R.path(["energy_types", "response"])
        )(state),
        climate_zones: R.compose(
          R.defaultTo([]),
          R.path(["climate_zones", "response"])
        )(state),
      };
    }
  );

  useEffect(() => {
    dispatch(customerAction(customer_id));
    dispatch(getLocationAction(location_id));
    if (allotment_id) {
      dispatch(getAllotmentAction(customer_id, allotment_id));
    }
  }, [customer_id, allotment_id, location_id]);

  const title = useMemo(() => {
    if (customer && allotment && location) {
      return `Nouvelle visite - ${customer.name} - ${allotment.name} - ${location.label}`;
    } else if (customer && location) {
      return `Nouvelle visite - ${customer.name} - ${location.label}`;
    } else if (customer) {
      return `Nouvelle visite - ${customer.name}`;
    }
    return "Nouvelle visite";
  }, []);

  const onSubmit = useCallback(
    async (values, formikCtx) => {
      formikCtx.setSubmitting(true);
      const contract_type = contract_types.find(
        (x) => x.id === values.contract_type_id
      );
      const energy_type = energy_types.find(
        (x) => x.id === values.energy_type_id
      );
      const climate_zone = climate_zones.find(
        (x) => x.id === values.climate_zone_id
      );
      const newValues = await saveJSONFileInStorage({
        ...values,
        id: uuidv4(),
        location_attributes: location,
        contract_type: contract_type,
        energy_type: energy_type,
        climate_zone: climate_zone,
      });
      await saveJSONInStorage('visit', newValues)
      dispatch(VisitsActions.createVisit(newValues, { location_id }));
      formikCtx.setSubmitting(false);
      dispatch(flashMessage("La visite a été crée."));
      navigation(`/visites/${newValues.id}/edition`);
    },
    [location]
  );

  const backUrl = useMemo(() => {
    if (allotment_id) {
      return `/customers/${customer_id}/lots/${allotment_id}/locations/${location_id}/visits`;
    }
    return `/customers/${customer_id}/locations/${location_id}/visits`;
  }, [customer_id, allotment_id, location_id]);

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={{
          ...initialValues,
          location_id: location_id,
          location_attributes: location,
        }}
      >
        {({ dirty, isValid }) => {
          return (
            <>
              <ScreenTitle
                dirty={dirty}
                back={true}
                button="Retour"
                href={backUrl}
                title={title}
                subtitle={""}
              />
              <VisitForm isValid={isValid} />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateVisitContainer;

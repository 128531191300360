import {
  Button,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FuseJS from "fuse.js";
import moment from "moment";
import * as R from "ramda";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteRapport, getRapports } from "../actions/RapportsAction";
import { confirm } from "../components/Confirms/ConfirmDestroy";
import ScreenTitle from "../components/ScreenTitle";
import { useNavigate } from "react-router";
import { isReadOnlySelector } from "../reducers/MeReducer";

const searchOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: false,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    "user_fullname",
    "name",
    "market_object",
    "customer.name",
    "customer.city",
    "customer.address",
    "customer.comment",
    "customer.zip_code",
  ],
};

const RapportContainer = ({}) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const fuseJSRef = useRef(new FuseJS([], searchOptions));
  const isReadOnly = useSelector(isReadOnlySelector)

  const [search, setValues] = useState("");
  useEffect(() => {
    dispatch(getRapports());
  }, []);

  const { rapports } = useSelector((state) => {
    return {
      rapports: state.rapports.payload,
    };
  });

  useEffect(() => {
    fuseJSRef.current.setCollection(rapports);
  }, [rapports]);

  useEffect(() => {
    const count = R.compose(
      R.length,
      R.filter((x) => !x),
      R.uniq,
      R.map(R.prop("is_generate"))
    )(rapports);

    if (count === 0) return;
    const idInterval = setInterval(() => {
      dispatch(getRapports());
    }, 5000);

    return () => {
      clearInterval(idInterval);
    };
  }, [rapports]);

  const onDestroy = useCallback(async (item) => {
    if (await confirm(`Êtes-vous sûr de vouloir supprimer ce rapport ?`)) {
      dispatch(deleteRapport(item.id));
    }
  }, []);

  const rapportFiltered = useMemo(() => {
    if (search === "") return rapports;

    return fuseJSRef.current.search(search).map((x) => x.item);
  }, [search, rapports]);

  return (
    <div>
      <ScreenTitle onSearch={setValues} title="Gestion des rapports" />
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Généré le</TableCell>
              <TableCell>Généré par</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>PDF</TableCell>
              <TableCell>XLS</TableCell>
              <TableCell size="small" align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rapportFiltered.map((rapport) => (
              <TableRow key={rapport.id}>
                <TableCell>
                  {moment(rapport.created_at).format("DD-MM-YYYY HH:mm")}
                </TableCell>
                <TableCell>{rapport.user_fullname}</TableCell>
                <TableCell>
                  {rapport.date && moment(rapport.date).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell>{rapport.customer.name}</TableCell>
                {!rapport.is_generate && (
                  <TableCell>En cours de génération...</TableCell>
                )}
                {rapport.is_generate && (
                  <TableCell>
                    <a target="_blank" href={rapport.pdf_url}>
                      {rapport.pdf_name}
                    </a>
                  </TableCell>
                )}
                {rapport.is_generate && (
                  <TableCell>
                    <a target="_blank" href={rapport.xls_url}>
                      {rapport.xls_name}
                    </a>
                  </TableCell>
                )}

                <TableCell size="small" align="right">
                  {
                    !isReadOnly &&
                      <Button
                        onClick={() => onDestroy(rapport)}
                        variant="contained"
                        color="secondary"
                      >
                        Supprimer
                      </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {
        !isReadOnly &&
          <Fab
            onClick={() => navigation("/rapports/create")}
            color="primary"
            aria-label="add"
            className="fab-add"
          >
            <AddIcon />
          </Fab>
      }
    </div>
  );
};

export default RapportContainer;

import { flashErrorMessage, flashMessage } from "redux-flash";
import { call, put, select, race, take } from "redux-saga/effects";
import {
  destroyVisitErrorAction,
  destroyVisitSuccessAction,
} from "../actions/VisitsActions";
import { destroyVisite, getVisits } from "../services/Api";
import { getCookie } from "../utils/Cookies";
import { getVisitsFromLocationID, cloneVisit, getVisit } from "../services/Api";
import { VisitsActions } from "../reducers/VisitsReducer";
import { BuildingsActions } from "../reducers/BuildingsReducer";
import { ProductsVisitsActions } from "../reducers/ProductsVisitsReducer";

export function* getVisitsFromLocationIDSaga(action) {
  const {
    payload: { customer_id, location_id, allotment_id },
  } = action;

  try {
    const rsp = yield call(getVisitsFromLocationID, location_id);
    yield put(
      VisitsActions.requestVisitsFromLocationSuccess(rsp, action.payload)
    );
  } catch (e) {
    yield put(
      VisitsActions.requestsVisitsFromLocationError(e.message, action.payload)
    );
  }
}

export function* destroyVisitsSaga(action) {
  const {
    payload: { id, location_id, customer_id, allotment_id },
  } = action;

  const isOnline = yield select((state) => state.offline.online);

  if (!isOnline) {
    yield put(
      flashErrorMessage(
        "Impossible de supprimer cet élément en étant en mode offline!"
      )
    );
    yield put(
      destroyVisitErrorAction(id, customer_id, location_id, allotment_id)
    );
    return;
  }
  try {
    const rsp = yield call(destroyVisite, {
      token: getCookie("access_token"),
      id,
    });
    yield put(
      destroyVisitSuccessAction(id, customer_id, location_id, allotment_id)
    );
    yield put(flashMessage("Elément supprimé"));
  } catch (e) {
    yield put(
      destroyVisitErrorAction(id, customer_id, location_id, allotment_id)
    );
  }
}

export function* cloneVisitByIdSaga(actions) {
  const isOnline = yield select((state) => state.offline.online);

  if (!isOnline) {
    yield put(
      flashErrorMessage(
        "Impossible de cloner cet élément en étant en mode offline!"
      )
    );
    return;
  }

  const {
    payload: { id, location_id },
  } = actions;

  try {
    const rsp = yield call(cloneVisit, id, location_id);
    yield put(VisitsActions.cloneVisitFromIdSuccess(rsp, actions.payload));
    yield put(flashMessage("Elément dupliqué"));
  } catch (e) {}
}

export function* getVisitByID(actions) {
  const {
    payload: { id },
  } = actions;
  try {
    const visitRsp = yield call(getVisit, id);

    // Load Building && ProductVisit

    yield put(BuildingsActions.requestBuildingsFromVisit({ visit_id: id }));
    yield race({
      success: take(BuildingsActions.requestBuildingsFromVisitSuccess.type),
      error: take(BuildingsActions.requestBuildingsFromVisitError.type),
    });
    yield put(ProductsVisitsActions.requestFromVisitId({ visit_id: id }));
    yield race({
      success: take(ProductsVisitsActions.requestFromVisitIdSuccess.type),
      error: take(ProductsVisitsActions.requestFromVisitIdError.type),
    });
    yield put(VisitsActions.requestVisitByIdSuccess(visitRsp));
  } catch (e) {
    if ("isAxiosError" in e) {
      yield put(
        VisitsActions.requestVisitByIdError(e.response.status.toString())
      );
    }
  }
}

import {
  Button,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FuseJS from "fuse.js";
import moment from "moment";
import * as R from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCompareRapport,
  getCompareRapports,
} from "../actions/CompareRapportsAction";
import { confirm } from "../components/Confirms/ConfirmDestroy";
import ScreenTitle from "../components/ScreenTitle";
import { useNavigate } from "react-router";
import { isReadOnlySelector } from "../reducers/MeReducer";

const searchOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: false,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    "user_fullname",
    "name",
    "market_object",
    "customer.name",
    "customer.city",
    "customer.address",
    "customer.comment",
    "customer.zip_code",
  ],
};

const CompareRapportContainer = ({}) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [{ search, value }, setValues] = useState({ search: [], value: "" });
  useEffect(() => {
    dispatch(getCompareRapports());
  }, []);
  const isReadOnly = useSelector(isReadOnlySelector)

  const { compare_rapports } = useSelector((state) => {
    return {
      compare_rapports: state.compare_rapports.payload,
    };
  });

  useEffect(() => {
    const count = R.compose(
      R.length,
      R.filter((x) => !x),
      R.uniq,
      R.map(R.prop("is_generate"))
    )(compare_rapports);

    if (count === 0) return;
    const idInterval = setInterval(() => {
      dispatch(getCompareRapports());
    }, 5000);

    return () => {
      clearInterval(idInterval);
    };
  }, [compare_rapports]);

  const onDestroy = useCallback(async (item) => {
    if (await confirm(`Êtes-vous sûr de vouloir supprimer ce rapport ?`)) {
      dispatch(deleteCompareRapport(item.id));
    }
  }, []);

  useEffect(() => {
    const fuseJS = new FuseJS(compare_rapports, searchOptions);
    setValues((oldState) => {
      if (oldState.value === "")
        return { ...oldState, search: compare_rapports };

      return {
        ...oldState,
        search: fuseJS.search(oldState.value).map((x) => x.item),
      };
    });
  }, [compare_rapports]);

  const onSearch = useCallback(
    (_value) => {
      const fuseJS = new FuseJS(compare_rapports, searchOptions);
      setValues((oldState) => {
        if (_value === "") return { value: _value, search: compare_rapports };

        return {
          value: _value,
          search: fuseJS.search(oldState.value).map((x) => x.item),
        };
      });
    },
    [compare_rapports]
  );

  return (
    <div>
      <ScreenTitle
        onSearch={onSearch}
        title="Gestion des rapports comparatifs"
      />
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Généré le</TableCell>
              <TableCell>Généré par</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>PDF</TableCell>
              <TableCell size="small" align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search.map((compare_rapport) => (
              <TableRow key={compare_rapport.id}>
                <TableCell>
                  {moment(compare_rapport.created_at).format(
                    "DD-MM-YYYY HH:mm"
                  )}
                </TableCell>
                <TableCell>{compare_rapport.user_fullname}</TableCell>
                <TableCell>
                  {compare_rapport.date &&
                    moment(compare_rapport.date).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell>{compare_rapport.customer.name}</TableCell>
                {!compare_rapport.is_generate && (
                  <TableCell>En cours de génération...</TableCell>
                )}
                {compare_rapport.is_generate && (
                  <TableCell>
                    <a target="_blank" href={compare_rapport.pdf_url}>
                      {compare_rapport.pdf_name}
                    </a>
                  </TableCell>
                )}

                <TableCell size="small" align="right">
                  {
                    !isReadOnly &&
                      <Button
                        onClick={() => onDestroy(compare_rapport)}
                        variant="contained"
                        color="secondary"
                      >
                        Supprimer
                      </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {
        !isReadOnly &&
          <Fab
            onClick={() => navigation("/comparaison_rapports/create")}
            color="primary"
            aria-label="add"
            className="fab-add"
          >
            <AddIcon />
          </Fab>
      }
    </div>
  );
};

export default CompareRapportContainer;

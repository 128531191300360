import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useCallback } from "react";
import ScreenTitle from "../components/ScreenTitle";
import { useDispatch, useSelector } from "react-redux";
import { flashMessage } from "redux-flash";

const TYPES_TO_STRING = {
  CREATE_LOCATION: "Création d'un site",
  CREATE_CUSTOMER: "Création d'un client",
  CREATE_ALLOTMENT: "Création d'un Lot",
  "Visits.createVisit": "Création d'une visite",
  "Buildings.createBuildingByVisitAndCategory": "Création d'un batiment",
  "ProductsVisits.syncProductVisit":
    "Création/Mise à jour d'un produit dans une visite",
  UPDATE_CUSTOMER: "Mise à jour d'un client",
  UPDATE_ALLOTMENT: "Mise à jour d'un lot",
  UPDATE_LOCATION: "Mise à jour d'un site",
  "Visits.updateVisitByID": "Mise à jour d'une visite",
  "Buildings.updateBuildingByVisitAndCategory": "Mise à jour d'un batiment",
  PRODUCT_ADD_REQUEST: "Création d'un produit",
};

const ItemTypeToString = (type) => {
  return TYPES_TO_STRING[type] || type;
};

const OfflineItem = ({ item }) => {
  const dispatch = useDispatch();
  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(JSON.stringify(item));
    dispatch(flashMessage("La donnée a été copiée."));
  }, [item]);
  return (
    <TableRow>
      <TableCell>{ItemTypeToString(item.type)}</TableCell>
      <TableCell>{item.meta.createdAt}</TableCell>
      <TableCell>
        <Button onClick={onCopy} color="warning" variant="contained">
          Copier la donnée
        </Button>
      </TableCell>
    </TableRow>
  );
};

const OfflineList = () => {
  const offlinesList = useSelector((state) => state.offline.outbox);
  const loading = useSelector((state) => state.offline.busy);

  return (
    <div>
      <ScreenTitle
        loading={loading}
        back
        href="/dashboard"
        title="Offline"
        button="Retour"
      />
      <Grid container spacing={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Date de création</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offlinesList.map((item) => (
              <OfflineItem key={item.meta.transaction} item={item} />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </div>
  );
};

export default OfflineList;

import { createConsumer } from "@rails/actioncable"
import { configureStore } from '@reduxjs/toolkit'
import { createOffline } from '@redux-offline/redux-offline'
import offlineConfig from '@redux-offline/redux-offline/lib/defaults'
import Cookies from 'js-cookie'
import localForage from 'localforage'
import { combineReducers, } from 'redux'
import { middleware as flashMiddleware, reducer as flashReducer } from 'redux-flash'
import logger from 'redux-logger'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import { resettableReducer } from 'reduxsauce'
import { LOGOUT_REQUEST } from '../actions'
import { doApplicationStartup } from '../actions/ApplicationsActions'
import Config from '../config/Config'
import allotments from '../reducers/AllotmentsReducer'
import ApplicationsReducer from '../reducers/ApplicationsReducer'
import category from '../reducers/CategoryReducer'
import climate_zones from '../reducers/ClimateZonesReducer'
import contract_types from '../reducers/ContractTypesReducer'
import customers from '../reducers/CustomersReducer'
import energy_types from '../reducers/EnergyTypesReducer'
import locations from '../reducers/LocationsReducer'
import login from '../reducers/LoginReducer'
import me from '../reducers/MeReducer'
import notistackReducer from '../reducers/notistackReducer'
import { reducer as ProductAddReducer } from '../reducers/ProductAddReducer'
import products from '../reducers/ProductsReducer'
import product_types from '../reducers/ProductTypesReducer'
import { reducer as RapportsReducer } from '../reducers/RapportsReducer'
import { reducer as ImageBanksReducer } from '../reducers/ImageBanksReducer'
import { reducer as CompareRapportsReducer } from '../reducers/CompareRapportsReducer'
import { reducer as P2ReportsReducer } from '../reducers/P2ReportsReducer'
import { reducer as P3ReportsReducer } from '../reducers/P3ReportsReducer'
import trademarks from '../reducers/TrademarksReducer'
import visits from '../reducers/VisitsReducer'
import rootSaga from '../sagas'
import { customDiscard } from '../services/CustomDiscard'
import CustomEffect from '../services/CustomEffect'
import customQueue from '../services/customQueue'
import customerRetry from '../services/CustomRetry'
import OfficesReducer from '../reducers/OfficesReducer'
import { BuildingsReducer } from '../reducers/BuildingsReducer'
import { ProductsVisitsReducer } from '../reducers/ProductsVisitsReducer'

const ressetable = resettableReducer(LOGOUT_REQUEST)
const actionCable = createConsumer(`${Config.value('backendHost')}/cable`)

// ReduxOffline

const customOfflineConfig = {
  ...offlineConfig,
  detectNetwork: (callback) => {
    let isWSOnline = true
    let isNetworkOnline = window.navigator.onLine

    window.addEventListener('offline', (e) => {
      isNetworkOnline = false
      callback({ online: false })
    });

    window.addEventListener('online', (e) => {
      isNetworkOnline = true
      if (isNetworkOnline && isWSOnline) {
        callback({ online: true })
      }
    });


    actionCable.subscriptions.create({
      channel: 'PingChannel'
    }, {
      connected: () => {
        isWSOnline = true
        if (isNetworkOnline && isWSOnline) {
          callback({ online: true })
        }
      },
      disconnected: () => {
        isWSOnline = false
        callback({ online: false })
      }
    })
  }
}
const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore
} = createOffline({
  ...customOfflineConfig,
  effect: CustomEffect,
  queue: customQueue,
  discard: customDiscard,
  retry: customerRetry,
  persist: false
})

// Reducers

const reducers = combineReducers({
  applications: ApplicationsReducer,
  notistack: notistackReducer,
  login: ressetable(login),
  me: ressetable(me),
  trademarks,
  customers,
  visits,
  contract_types,
  energy_types,
  climate_zones,
  category,
  product_types,
  products,
  locations,
  allotments,
  flash: flashReducer,
  product_add: ProductAddReducer,
  rapports: RapportsReducer,
  offices: OfficesReducer,
  p2_reports: P2ReportsReducer,
  p3_reports: P3ReportsReducer,
  compare_rapports: CompareRapportsReducer,
  buildings: BuildingsReducer,
  products_visits: ProductsVisitsReducer,
  image_banks: ImageBanksReducer
})

/* Redux-Persist */

const configPersist = {
  key: 'root',
  storage: localForage,
  blacklist: ['router', 'form', 'flash', 'notistack', 'applications', 'login']
}
const rootReducer = persistReducer(configPersist, offlineEnhanceReducer(reducers))

/* Saga */
const sagaMiddleware = createSagaMiddleware()

/* Create Store */

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })

    middlewares.push(sagaMiddleware)
    middlewares.push(flashMiddleware())
    middlewares.push(offlineMiddleware)

    if (process.env.NODE_ENV === 'development') {
      middlewares.push(logger)
    }

    return middlewares
  },
  enhancers: (storeEnhancers) => {
    return [offlineEnhanceStore, ...storeEnhancers]
  },

})

/* Redux-Persist + Store */

export const persistor = persistStore(store, { manualPersist: true }, () => {
  store.dispatch(doApplicationStartup())
})

const currentVersion = 'v1.0.2'

const gemaVersion = window.localStorage.getItem('GEMA-VERSION')
if (gemaVersion !== currentVersion) {
  Cookies.remove('access_token')
  Cookies.remove('refresh_token')
  persistor.purge()
  persistor.persist()
  window.localStorage.setItem('GEMA-VERSION', currentVersion)
} else {
  persistor.persist()
}

/* Run saga */

sagaMiddleware.run(rootSaga)

import { Formik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { flashMessage } from "redux-flash";
import VisitForm from "../../../components/forms/VisitForm";
import ScreenTitle from "../../../components/ScreenTitle";
import { validationSchema } from "../../../Models/Visit";
import { saveJSONFileInStorage, saveJSONInStorage } from "../../../utils/JsonTools";
import CategoriesRootList from "./CategoriesRootList";
import { VisitsActions } from "../../../reducers/VisitsReducer";
import { isReadOnlySelector } from "../../../reducers/MeReducer";

const UpdateVisitContainer = ({}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const entities = useSelector((state) => state.visits.entities[id]);

  const onSubmit = useCallback(async (values, formikCtx) => {
    formikCtx.setSubmitting(true);
    const newValues = await saveJSONFileInStorage(values);
    await saveJSONInStorage('visit', values);
    dispatch(VisitsActions.updateVisitByID(id, newValues));
    formikCtx.setValues(newValues);
    formikCtx.setSubmitting(false);
    dispatch(flashMessage("La visite a été mis à jour."));
  }, []);

  const isReadOnly = useSelector(isReadOnlySelector)

  const backUrl = useMemo(() => {
    if (entities) {
      const {
        location_attributes: { customer_id, allotment_id },
        location_id,
      } = entities;
      if (allotment_id) {
        return `/customers/${customer_id}/lots/${allotment_id}/locations/${location_id}/visits`;
      }
      return `/customers/${customer_id}/locations/${location_id}/visits`;
    }
    return `/dashboard`;
  }, []);

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={entities}
      >
        {({ dirty, isValid }) => (
          <>
            <ScreenTitle
              dirty={dirty}
              back={true}
              button="Retour"
              href={backUrl}
              title={"Edition visite"}
              subtitle={""}
            />
            <VisitForm isValid={isValid} readonly={isReadOnly} />
          </>
        )}
      </Formik>
      <CategoriesRootList visit_id={id} />
    </div>
  );
};

export default UpdateVisitContainer;

import * as R from 'ramda'
import { createReducer } from 'reduxsauce'
import {
    DELETE_COMPARE_RAPPORTS,

    DELETE_COMPARE_RAPPORTS_ERROR, DELETE_COMPARE_RAPPORTS_SUCCESS, GET_COMPARE_RAPPORTS,

    GET_COMPARE_RAPPORTS_ERROR, GET_COMPARE_RAPPORTS_SUCCESS,



    POST_COMPARE_RAPPORTS, POST_COMPARE_RAPPORTS_ERROR,
    POST_COMPARE_RAPPORTS_SUCCESS
} from '../actions'


const INITIAL_STATE = {
    payload: [],
    fetching: true,
    error: null,
    fetchingNew: false,
    fetchingDeletion: false
}


const getCompareRapportRequest = (state) => {
    return {
        ...state,
        fetching: true
    }
}

const getCompareRapportSuccess = (state, actions) => {
    return {
        ...state,
        fetching: false,
        payload: actions.payload
    }
}

const getCompareRapportError = (state, actions) => {
    return {
        ...state,
        fetching: false
    }
}

const postCompareRapportRequest = (state, actions) => {
    return {
        ...state,
        fetchingNew: true
    }
}

const postCompareRapportSuccess = (state, actions) => {
    return {
        ...state,
        fetchingNew: false,
        payload: [actions.payload, ...state.payload]
    }
}

const postCompareRapportError = (state, actions) => {
    return {
        ...state,
        fetchingNew: false
    }
}

const deleteCompareRapportRequest = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: true
    }
}

const deleteCompareRapportSuccess = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: false,
        payload: R.reject(R.propEq('id', actions.payload), state.payload)
    }
}

const deleteCompareRapportError = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: false
    }
}

const HANDLERS = {
    [GET_COMPARE_RAPPORTS]: getCompareRapportRequest,
    [GET_COMPARE_RAPPORTS_SUCCESS]: getCompareRapportSuccess,
    [GET_COMPARE_RAPPORTS_ERROR]: getCompareRapportError,
    [POST_COMPARE_RAPPORTS]: postCompareRapportRequest,
    [POST_COMPARE_RAPPORTS_ERROR]: postCompareRapportError,
    [POST_COMPARE_RAPPORTS_SUCCESS]: postCompareRapportSuccess,
    [DELETE_COMPARE_RAPPORTS_ERROR]: deleteCompareRapportError,
    [DELETE_COMPARE_RAPPORTS]: deleteCompareRapportRequest,
    [DELETE_COMPARE_RAPPORTS_SUCCESS]: deleteCompareRapportSuccess
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)
import { Grid } from '@mui/material';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import FuseJS from 'fuse.js';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getAllotmentAction } from '../actions/AllotmentsActions';
import { customerAction } from '../actions/CustomersActions';
import { getLocationAction } from '../actions/LocationsActions';
import { cloneVisitAction, destroyVisitAction, visitsAction } from '../actions/VisitsActions';
import { confirm } from '../components/Confirms/ConfirmDestroy';
import ScreenTitle from '../components/ScreenTitle';
import Visit from '../components/Visit';
import { getVisitsByFactory } from '../reducers/VisitsReducer';
import { useNavigate, useParams } from 'react-router';
import * as R from 'ramda'
import { VisitsActions, selectVisitsByLocationId } from '../reducers/VisitsReducer'
import { getItem } from 'localforage';
import { isReadOnlySelector } from '../reducers/MeReducer';

ReactModal.setAppElement('#root')

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const searchOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: false,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    "primary_use", "energies_use", "comment"
  ]
}

const VisitsContainer = ({ }) => {
  const fuseJSRef = useRef(new FuseJS([], searchOptions))
  const navigation = useNavigate()
  const { customer_id, location_id, allotment_id } = useParams()

  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const isReadOnly = useSelector(isReadOnlySelector)

  useEffect(() => {
    dispatch(customerAction(customer_id))
    dispatch(getLocationAction(location_id))
    if (allotment_id) {
      dispatch(getAllotmentAction(customer_id, allotment_id))
    }
    dispatch(VisitsActions.requestVisitsFromLocation({ location_id, customer_id, allotment_id }))
  }, [location_id, allotment_id, customer_id])

  const entities = useSelector(selectVisitsByLocationId(location_id))
  const customer = useSelector(state => state.customers.entities[customer_id])
  const location = useSelector(state => state.locations.entities[location_id])
  const allotment = useSelector(state => state.allotments.entities[allotment_id])
  const fetching = useSelector(state => state.visits.fetching)

  useEffect(() => {
    fuseJSRef.current.setCollection(entities)
  }, [entities])

  const visits = useMemo(() => {
    if (search === '') return entities
    const filters = fuseJSRef.current.search(search)
    return R.map(x => x.item, filters)
  }, [entities, search])

  const title = useMemo(() => {
    if (customer && location && allotment) {
      return `Visites - ${customer.name} - ${location.label} - ${allotment.name}`
    } else if (customer && location) {
      return `Visites - ${customer.name} - ${location.label}`
    } else if (customer) {
      return `Visites - ${customer.name}`
    }
    return 'Visits'
  }, [customer, location, allotment])

  const backUrl = useMemo(() => {
    if (allotment_id) {
      return `/customers/${customer_id}/lots/${allotment_id}/locations`
    }
    return `/customers/${customer_id}/locations`
  }, [allotment_id, customer_id])

  const onClone = useCallback((visite) => {
    dispatch(VisitsActions.cloneVisitByID({ id: visite.id, location_id, customer_id, allotment_id }))
  }, [customer_id, allotment_id, location_id])

  const onDestroy = useCallback(async (visite) => {
    const { location_attributes: { customer: { name } }, seen_at } = visite
    if (await confirm(`Êtes-vous sûr de vouloir supprimer la visite du ${moment(seen_at).format('DD/MM/YYYY')} du client ${name} ?`)) {
      dispatch(destroyVisitAction(visite.id, customer_id, location_id, allotment_id))
    }
  }, [customer_id, allotment_id, location_id])

  const onCreateVisitLink = useCallback(() => {
    if (allotment_id) {
      navigation(`/customers/${customer_id}/lots/${allotment_id}/locations/${location_id}/visits/new`)
      return
    }
    navigation(`/customers/${customer_id}/locations/${location_id}/visits/new`)
  }, [customer_id, allotment_id, location_id])

  return (
    <div>
      <ScreenTitle loading={fetching} onSearch={setSearch} back={true} button='Retour' href={backUrl} title={title} />
      <Grid container spacing={2}>
        {
          visits.map((item) => (
            <Grid key={item.id} item xs={12} sm={12} md={6} lg={4}>
              <Visit readonly={isReadOnly} onClone={onClone} onDestroy={onDestroy} data={item} />
            </Grid>
          ))
        }
      </Grid>
      {
        !isReadOnly && location &&
        <Fab onClick={onCreateVisitLink} color="primary" aria-label="add" className="fab-add">
          <AddIcon />
        </Fab>
      }
    </div>
  )
}

export default VisitsContainer
import Button from '@mui/material/Button'
// UI Elements
import Grid from '@mui/material/Grid'
import { Form } from 'formik'
import React from 'react'
import TextAreaField from './Formik/TextAreaField'
import TextField from './Formik/TextField'

const AllotmentForm = ({ isValid = true, ...props }) => {
  return (
    <Form autoComplete="off">
      <Grid container justify="center" spacing={0}>
        <Grid item xs={12}>
          <TextField
            name='name'
            label='Nom'
          />
          <TextAreaField
            name='comment'
            label='Commentaire'
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button variant="contained" color={isValid ? 'primary' : 'secondary'} type="submit">Sauvegarder</Button>
        </Grid>
      </Grid>
    </Form>
  )
}

export default AllotmentForm

import { createReducer } from 'reduxsauce'
import { APPLICATION_STARTUP_SUCCESS } from '../actions'

const initialValues = {
    applicationStarted: false
}

const doApplicationStartupSuccess = (state) => {
    return {
        ...state,
        applicationStarted: true
    }
}

export default createReducer(initialValues, {
    [APPLICATION_STARTUP_SUCCESS]: doApplicationStartupSuccess
})
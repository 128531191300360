import { Badge } from "@mui/material";
import { Formik } from "formik";
import * as R from "ramda";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Redirect from "../../components/Redirect";
import { flashMessage } from "redux-flash";
import {
  imageBankAction,
  updateImageBankAction,
} from "../../actions/ImageBanksAction";
import { confirm } from "../../components/Confirms/ConfirmAction";
import FormContainer from "./FormContainer";
import ScreenTitle from "../../components/ScreenTitle";
import { validationSchema } from "../../Models/ImageBank";
import { getCookie } from "../../utils/Cookies";
import { saveJSONFileInStorage } from "../../utils/JsonTools";

const UpdateContainer = ({ match, history, ...props }) => {
  const { id } = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const { image_bank, loading, error, isWaitForSync } = useSelector((state) => {
    return {
      image_bank: state.image_banks.entities[id],
      loading: state.image_banks.loading,
      error: state.image_banks.error,
      isWaitForSync: R.compose(
        R.includes(id),
        R.defaultTo([]),
        R.path(["image_banks", "waitForSync"])
      )(state),
    };
  });

  useEffect(() => {
    if (!isWaitForSync) {
      dispatch(imageBankAction(id));
    }
  }, []);

  const onSubmit = useCallback(async (values, formikCtx) => {
    formikCtx.setSubmitting(true);
    const newValues = await saveJSONFileInStorage(values);
    dispatch(updateImageBankAction(id, newValues, getCookie("access_token")));
    formikCtx.setSubmitting(false);
    // navigation("/banque-images");
    dispatch(flashMessage("L'image a été mise à jour."));
  }, []);

  const onBack = useCallback(
    async (e) => {
      e.preventDefault();

      if (formikRef.current.dirty) {
        if (
          !(await confirm(
            "Des éléments dans le formulaire n'ont pas été sauvegardés, êtes-vous sur de vouloir retourner sur la page d'avant ?"
          ))
        ) {
          return;
        }
      }
      navigation("/banque-images");
    },
    [formikRef]
  );

  if (loading && R.isNil(image_bank)) {
    return (
      <div>
        <ScreenTitle
          back={true}
          button="Retour"
          onClickBack={() => {
            navigation("/banque-images");
          }}
          title={"Chargement de l'image..."}
        ></ScreenTitle>
      </div>
    );
  }

  if (!loading && R.isNil(image_bank) && error === "404") {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={onSubmit}
        initialValues={image_bank}
        validationSchema={validationSchema}
      >
        {({ dirty, isValid }) => {
          return (
            <>
              <ScreenTitle
                dirty={dirty}
                back={true}
                button="Retour"
                onClickBack={onBack}
                title={"Edition d'une image"}
              />
              <FormContainer isValid={isValid} btnTitle="Sauvegarder" />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateContainer;

import * as R from 'ramda'
import * as types from '../actions'

export default function (state = {}, action) {
  switch (action.type) {
    case types.GET_TRADEMARKS_SUCCESS:
      const payload = action.response.reduce((prev, current) => {
        prev[current.id] = current
        return prev
      }, {})
      return R.mergeRight(state, payload)
    case types.DESTROY_TRADEMARKS_SUCCESS:
      return R.omit([action.payload.id], state)
    case types.SET_TRADEMARKS_FROM_SYNC:
      return R.mergeRight(state, action.payload)
    case types.SET_TRADEMARKS_FROM_SYNC_DELETION:
      return R.omit(action.payload, state)
    default:
      return state
  }
}

import { call, put, select } from "redux-saga/effects";
import { getBuildingFromVisitID } from "../services/Api";
import { BuildingsActions } from "../reducers/BuildingsReducer";
import { flashErrorMessage, flashMessage } from "redux-flash";
import { destroyBuilding, cloneBuilding } from "../services/Api";

export function* getBuildingsFromVisit(actions) {
  const {
    payload: { visit_id },
  } = actions;

  try {
    const rsp = yield call(getBuildingFromVisitID, visit_id);
    yield put(BuildingsActions.requestBuildingsFromVisitSuccess(rsp, visit_id));
  } catch (e) {
    if ("isAxiosError" in e) {
      yield put(
        BuildingsActions.requestBuildingsFromVisitError(
          e.response.status.toString(),
          visit_id
        )
      );
      return;
    }

    yield put(BuildingsActions.requestBuildingsFromVisitError("", visit_id));
  }
}

export function* destroyBuildingByID(actions) {
  const {
    payload: { building_id, visit_id },
  } = actions;
  try {
    const isOnline = yield select((state) => state.offline.online);

    if (!isOnline) {
      yield put(
        flashErrorMessage(
          "Impossible de supprimer cet élément en étant en mode offline!"
        )
      );
      return;
    }

    const rsp = yield call(destroyBuilding, building_id);
    yield put(BuildingsActions.destroyBuildingSuccess(building_id, visit_id));
    yield put(flashMessage("Elément supprimé"));
  } catch (e) {}
}

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  createFilterOptions,
  Alert,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { findIndex, isNil, propEq } from "ramda";
import { v4 as uuidv4 } from "uuid";
import { ProductAddRequest } from "../../actions/ProductAddActions";
import { isEmpty } from "ramda";

const filterSelect = createFilterOptions({
  matchFrom: "start",
});

const toInputUppercase = (e) => {
  e.target.value = ("" + e.target.value).toUpperCase();
};

const CreateEquipment = ({ category_id }) => {
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const category = useSelector((state) => state.category[category_id]);
  const trademarks = useSelector((state) => {
    return Object.values(state.trademarks);
  });
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    register,
    setError,
    formState,
  } = useForm({
    defaultValues: {
      trademark_id: null,
      product_type_id: null,
      product_id: null,
      comment: "",
    },
  });
  const _trademark_id = useWatch({
    control: control,
    name: "trademark_id",
  });

  const productTypes = useSelector((state) => {
    if (!_trademark_id) return [];
    const values = Object.values(state.product_types);

    return values.filter((x) => x.trademark_ids.includes(_trademark_id.id));
  });

  const _product_type_id = useWatch({
    control: control,
    name: "product_type_id",
  });

  const products = useSelector((state) => {
    if (!_product_type_id || !_trademark_id) return [];
    const values = Object.values(state.products);
    return values
      .filter(
        (x) =>
          x.category_id === category_id &&
          x.trademark_id === _trademark_id.id &&
          x.product_type_id === _product_type_id.id
      )
      .map((x) => ({
        label: x.name,
        id: x.id,
      }));
  });

  const onSubmit = useCallback(
    (values) => {
      if ("id" in values.trademark_id && "id" in values.product_type_id) {
        if (values.product_id && typeof values.product_id === "string") {
          // MaybeError
          if (
            products.findIndex((x) => x.label === values.product_id.trim()) >= 0
          ) {
            setError("product_id", {
              message: "Ce type existe déjà dans la base de données",
            });
            return;
          }
        } else if (values.product_id && "id" in values.product_id) {
          // Error
          setError("product_id", {
            message: "Ce type existe déjà dans la base de données",
          });
          return;
        }
      }

      if (
        isNil(values.product_id) &&
        products.findIndex((x) => x.label === "AUCUN")
      ) {
        setError("product_id", {
          message: "Ce type existe déjà dans la base de données",
        });
        return;
      }

      const currentTrademark =
        "inputValue" in values.trademark_id
          ? {
              id: uuidv4(),
              comment: null,
              name: values.trademark_id.inputValue,
            }
          : values.trademark_id;

      const currentProductType =
        "inputValue" in values.product_type_id
          ? {
              id: uuidv4(),
              comment: null,
              name: values.product_type_id.inputValue,
            }
          : values.product_type_id;

      const data = {
        id: uuidv4(),
        name:
          isEmpty(values.product_id) || isNil(values.product_id)
            ? "AUCUN"
            : values.product_id.trim(),
        comment: values.comment,
        product_type: currentProductType,
        trademark: currentTrademark,

        product_type_id: currentProductType.id,
        product_type_name: currentProductType.name,

        trademark_id: currentTrademark.id,
        trademark_name: currentTrademark.name,

        category_id: category.id,
        category_name: category.name,
      };

      dispatch(ProductAddRequest(data, category_id));
      setShowDialog(false);
      reset();
    },
    [products, category]
  );

  return (
    <>
      <button
        style={{
          marginRight: "10px",
        }}
        className="mb-2 btn btn-success"
        onClick={() => {
          setShowDialog(true);
        }}
      >
        +
      </button>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={() => {
          reset();
          setShowDialog(false);
        }}
        open={showDialog}
      >
        <DialogTitle>Saisie de matériel</DialogTitle>
        <DialogContent>
          <DialogContentText pb={2}>
            Ajouter du matériel dans la catégorie courante
          </DialogContentText>
          <Stack spacing={2}>
            {formState.submitCount > 0 &&
              !formState.isValid &&
              "product_id" in formState.errors && (
                <Alert color="error">
                  Ce type existe déjà dans la base de données
                </Alert>
              )}
            <Controller
              control={control}
              name="trademark_id"
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Autocomplete
                  onChange={(evt, value) => {
                    setValue("product_type_id", null);
                    setValue("product_id", null);
                    field.onChange(value);
                  }}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  value={field.value}
                  selectOnFocus
                  clearOnBlur
                  fullWidth
                  handleHomeEndKeys
                  freeSolo
                  options={trademarks}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") return option;
                    if ("inputValue" in option) return option.inputValue;
                    return option.name;
                  }}
                  filterOptions={(options, params) => {
                    let filtered = filterSelect(options, params);

                    const foundAucun = findIndex(
                      propEq("name", "AUCUN"),
                      options
                    );

                    if (foundAucun == -1) {
                      filtered = [
                        {
                          inputValue: "AUCUN",
                          name: `AUCUN`,
                          id: "AUCUN",
                        },
                        ...filtered,
                      ];
                    }

                    if (params.inputValue !== "") {
                      filtered = [
                        {
                          inputValue: params.inputValue,
                          name: `Ajouter "${params.inputValue}"`,
                          id: params.inputValue,
                        },
                        ...filtered,
                      ];
                    }

                    return filtered;
                  }}
                  renderOption={(internalProps, value) => {
                    return (
                      <li {...internalProps} key={value.id}>
                        {value.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        onInput={toInputUppercase}
                        label="La marque :"
                      />
                    );
                  }}
                />
              )}
            />
            <Controller
              rules={{
                required: true,
              }}
              control={control}
              name="product_type_id"
              render={({ field }) => (
                <Autocomplete
                  onChange={(evt, value) => {
                    setValue("product_id", null);
                    field.onChange(value);
                  }}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  value={field.value}
                  selectOnFocus
                  clearOnBlur
                  fullWidth
                  handleHomeEndKeys
                  freeSolo
                  options={productTypes}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") return option;
                    if ("inputValue" in option) return option.inputValue;
                    return option.name;
                  }}
                  filterOptions={(options, params) => {
                    let filtered = filterSelect(options, params);

                    const foundAucun = findIndex(
                      propEq("name", "AUCUN"),
                      options
                    );

                    if (foundAucun == -1) {
                      filtered = [
                        {
                          inputValue: "AUCUN",
                          name: `AUCUN`,
                          id: "AUCUN",
                        },
                        ...filtered,
                      ];
                    }

                    if (params.inputValue !== "") {
                      filtered = [
                        {
                          inputValue: params.inputValue,
                          name: `Ajouter "${params.inputValue}"`,
                          id: params.inputValue,
                        },
                        ...filtered,
                      ];
                    }

                    return filtered;
                  }}
                  renderOption={(internalProps, value) => {
                    return (
                      <li {...internalProps} key={value.id}>
                        {value.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        onInput={toInputUppercase}
                        label="Le modèle :"
                      />
                    );
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="product_id"
              render={({ field }) => (
                <Autocomplete
                  onInputChange={(evt, value) => {
                    field.onChange(value);
                  }}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  value={field.value}
                  fullWidth
                  freeSolo
                  options={products}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") return option;
                    if ("inputValue" in option) return option.inputValue;
                    return option.label;
                  }}
                  filterOptions={(options, params) => {
                    return filterSelect(options, params);
                  }}
                  renderOption={(internalProps, value) => {
                    return (
                      <li {...internalProps} key={value.id}>
                        {value.label}
                      </li>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        onInput={toInputUppercase}
                        label="Le type :"
                      />
                    );
                  }}
                />
              )}
            />
            <TextField
              {...register("comment")}
              multiline
              style={{ marginTop: 10 }}
              fullWidth
              label="Commentaire"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reset();
              setShowDialog(false);
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            color="primary"
            size="small"
            variant="contained"
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateEquipment;

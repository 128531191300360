import {
    DELETE_RAPPORTS,

    DELETE_RAPPORTS_ERROR, DELETE_RAPPORTS_SUCCESS, GET_RAPPORTS,
    GET_RAPPORTS_ERROR,
    GET_RAPPORTS_SUCCESS,
    POST_RAPPORTS,
    POST_RAPPORTS_ERROR,
    POST_RAPPORTS_SUCCESS
} from '../actions'

export const getRapports = () => {
    return {
        type: GET_RAPPORTS
    }
}

export const getRapportsSuccess = (payload) => {
    return {
        type: GET_RAPPORTS_SUCCESS,
        payload
    }
}

export const getRapportsError = () => {
    return {
        type: GET_RAPPORTS_ERROR
    }
}

export const postRapport = (payload) => {
    return {
        type: POST_RAPPORTS,
        payload
    }
}

export const postRapportSuccess = (payload) => {
    return {
        type: POST_RAPPORTS_SUCCESS,
        payload: payload
    }
}

export const postRapportError = () => {
    return {
        type: POST_RAPPORTS_ERROR,
    }
}

export const deleteRapport = (payload) => {
    return {
        type: DELETE_RAPPORTS,
        payload
    }
}

export const deleteRapportSuccess = (payload) => {
    return {
        type: DELETE_RAPPORTS_SUCCESS,
        payload: payload
    }
}

export const deleteRapportError = () => {
    return {
        type: DELETE_RAPPORTS_ERROR,
    }
}
import * as R from 'ramda'
import { createReducer } from 'reduxsauce'
import {
    DELETE_IMAGE_BANKS,

    DELETE_IMAGE_BANKS_ERROR, DELETE_IMAGE_BANKS_SUCCESS, GET_IMAGE_BANKS,

    GET_IMAGE_BANKS_ERROR, GET_IMAGE_BANKS_SUCCESS,



    POST_IMAGE_BANKS, POST_IMAGE_BANKS_ERROR,
    POST_IMAGE_BANKS_SUCCESS
} from '../actions'

import * as types from '../actions'


const INITIAL_STATE = {
    payload: [],
    entities: {},
    fetching: true,
    error: null,
    fetchingNew: false,
    fetchingDeletion: false,
    item: null
}


const getImageBanksRequest = (state) => {
    return {
        ...state,
        fetching: true
    }
}

const getImageBanksSuccess = (state, actions) => {

    const { payload } = actions

    const entities = R.reduce((acc, el) => {
      acc[el.id] = el
      return acc
    }, {}, payload)
    const image_banks = R.map(R.prop('id'), payload)

    return R.mergeDeepLeft(
      {
        entities: entities,

        loading: false
      }
    )(state)

    // return {
    //     ...state,
    //     fetching: false,
    //     payload: actions.payload
    // }
}

const getImageBanksError = (state, actions) => {
    return {
        ...state,
        fetching: false
    }
}

const postImageBankRequest = (state, actions) => {
    return {
        ...state,
        fetchingNew: true
    }
}

const postImageBankSuccess = (state, actions) => {

    let entities = state.entities
    entities[actions.payload.id] = actions.payload

    return {
        ...state,
        entities: entities,
        fetchingNew: false,
        payload: [actions.payload, ...state.payload]
    }
}

const postImageBankError = (state, actions) => {

    return {
        ...state,
        fetchingNew: false
    }
}

const deleteImageBankRequest = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: true
    }
}

const deleteImageBankSuccess = (state, actions) => {
  let entities = state.entities
  delete entities[actions.payload]

  return R.mergeDeepLeft({
    entities: entities
  }, state)
}

const deleteImageBankError = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: false
    }
}

const getImageBankRequest = (state, action) => {
    return R.mergeDeepLeft({
      loading: true,
      error: null
    }, state)
  }

  const getImageBankSuccess = (state, action) => {
    const { payload } = action
    return R.mergeDeepLeft({
      loading: false,
      error: null,
      entities: {
        [payload.id]: payload
      }
    }, state)
  }

  const getImageBankError = (state, action) => {
    const { payload } = action

    return R.mergeDeepLeft({
      loading: false,
      error: payload
    }, state)
  }



const getPublicImageBankRequest = (state, action) => {
  return R.mergeDeepLeft({
    loading: true,
    error: null
  }, state)
}

const getPublicImageBankSuccess = (state, action) => {
  const { payload } = action
  return R.mergeDeepLeft({
    loading: false,
    error: null,
    item: payload
  }, state)
}

const getPublicImageBankError = (state, action) => {
  const { payload } = action

  return R.mergeDeepLeft({
    loading: false,
    error: payload
  }, state)
}

const createImageBankSuccess = (state, action) => {
    const { meta: { version } } = action

    const currentVersion = R.path(['versions', action.payload.id], state)

    let entities = state.entities
    entities[action.payload.id] = action.payload

    if (currentVersion !== version) return state

    return R.mergeDeepLeft({
        entities: entities,
        waitForSync: R.without([action.payload.id], state.waitForSync)
    }, state)
}

const createImageBankError = (state, action) => {
    return R.mergeDeepLeft({}, state)
}


const updateImageBankRequest = (state, action) => {
    const { meta: { version } } = action

    const versions = R.compose(
      R.mergeLeft({ [action.payload.id]: version }),
      R.defaultTo({}),
      R.prop('versions')
    )(state)

    let entities = state.entities
    entities[action.payload.id] = action.payload

    return R.mergeDeepLeft({
      versions: versions,
      entities: entities
    }, state)
}

  const updateImageBankSuccess = (state, action) => {
    const { payload, meta: { version } } = action
    const currentVersion = R.path(['versions', action.payload.id], state)

    // if (currentVersion !== version) return state

    let entities = state.entities
    entities[action.payload.id] = action.payload

    return R.mergeDeepLeft({
      entities: entities
    }, state)
  }

  const updateImageBankError = (state, action) => {
    return state
  }


const HANDLERS = {
    [GET_IMAGE_BANKS]: getImageBanksRequest,
    [GET_IMAGE_BANKS_SUCCESS]: getImageBanksSuccess,
    [GET_IMAGE_BANKS_ERROR]: getImageBanksError,
    [POST_IMAGE_BANKS]: postImageBankRequest,
    [POST_IMAGE_BANKS_ERROR]: postImageBankError,
    [POST_IMAGE_BANKS_SUCCESS]: postImageBankSuccess,
    [DELETE_IMAGE_BANKS_ERROR]: deleteImageBankError,
    [DELETE_IMAGE_BANKS]: deleteImageBankRequest,
    [DELETE_IMAGE_BANKS_SUCCESS]: deleteImageBankSuccess,
    [types.GET_IMAGE_BANK]: getImageBankRequest,
    [types.GET_IMAGE_BANK_SUCCESS]: getImageBankSuccess,
    [types.GET_IMAGE_BANK_ERROR]: getImageBankError,
    [types.GET_PUBLIC_IMAGE_BANK]: getPublicImageBankRequest,
    [types.GET_PUBLIC_IMAGE_BANK_SUCCESS]: getPublicImageBankSuccess,
    [types.GET_PUBLIC_IMAGE_BANK_ERROR]: getPublicImageBankError,
    [types.CREATE_IMAGE_BANK_SUCCESS]: createImageBankSuccess,
    [types.CREATE_IMAGE_BANK_ERROR]: createImageBankError,
    [types.UPDATE_IMAGE_BANK_SUCCESS]: updateImageBankSuccess,
    [types.UPDATE_IMAGE_BANK_ERROR]: updateImageBankError,

}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)
import { APPLICATION_STARTUP, APPLICATION_STARTUP_SUCCESS } from '../actions'

export const doApplicationStartup = () => {
    return {
        type: APPLICATION_STARTUP
    }
}

export const doApplicationStartupSuccess = () => {
    return {
        type: APPLICATION_STARTUP_SUCCESS
    }
}
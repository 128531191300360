// UI Elements
import Button from '@mui/material/Button'
import React from 'react'
import Badge from '../components/Badge'

const CategoryBox = ({ category, onPress, count = 0, color = null, size = null }) => {
  const colorButton = false ? 'disabled' : 'primary'
  const style = (size && size === 'small') ? { width: "100%" } : { width: '100%' }

  return (
    <div style={{ marginRight: '10px', marginTop: '10px' }}>
      <Badge badgeContent={count}>
        <Button color={colorButton} onClick={onPress} p={2} m={1} boxShadow={1} variant="contained" style={{ backgroundColor: color, ...style }} size="small" boxShadow={1} variant="contained">
          {category.name}
        </Button>
      </Badge>
    </div>
  )
}

export default CategoryBox
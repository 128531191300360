import { flashMessage } from "redux-flash"
import { call, put } from 'redux-saga/effects'
import { deleteP3ReportError, deleteP3ReportSuccess, getP3ReportsError, getP3ReportsSuccess, postP3ReportError, postP3ReportSuccess } from '../actions/P3ReportsAction'
import { deleteP3ReportApi, getP3ReportsApi, postP3ReportApi } from '../services/Api'
import { getCookie } from '../utils/Cookies'
import history from '../services/History'

export function* getP3ReportsSaga() {
    try {
        const data = yield call(getP3ReportsApi, getCookie('access_token'))
        yield put(getP3ReportsSuccess(data))
    } catch (e) {
        yield put(getP3ReportsError())
    }
}

export function* postP3ReportSaga(actions) {
    try {
        const data = yield call(postP3ReportApi, actions.payload, getCookie('access_token'))
        yield put(postP3ReportSuccess(data))
        history.replace('/points-p3')
        yield put(flashMessage('Elément sauvegardé'))
    } catch (e) {
        yield put(flashMessage('Une erreur est survenu durant la sauvegarde.'))
        yield put(postP3ReportError())
    }
}

export function* deleteP3ReportSaga(actions) {
    try {
        yield call(deleteP3ReportApi, actions.payload, getCookie('access_token'))
        yield put(deleteP3ReportSuccess(actions.payload))
        yield put(flashMessage('Elément supprimé'))
    } catch (e) {
        yield put(deleteP3ReportError())
    }
}
import { call, put } from 'redux-saga/effects'
import * as types from '../actions'
import { getClimateZones } from '../services/Api'


export function* climateZonesSaga(payload) {
  try {
    const response = yield call(getClimateZones, payload)
    yield put({ type: types.GET_CLIMATE_ZONES_SUCCESS, response })

  } catch (error) {
    yield put({ type: types.GET_CLIMATE_ZONES_ERROR, error })
  }
}

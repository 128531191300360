import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import {
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  Grid,
  Box,
  Stack,
  Button,
  ImageList,
  ImageListItem,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { renameFile } from "../../utils/JsonTools";
import * as R from "ramda";
import Config from "../../config/Config";

const year = moment().year();
const years = Array.from(
  new Array(50),
  (val, index) => year - 49 + index
).reverse();

const ImageField = ({ field: { value }, onDelete, index }) => {
  const imgUrl = useMemo(() => {
    if (R.has("_destroy", value) && value._destroy) return null;
    if (R.has("url", value)) {
      return `${Config.value("backendHost")}${value.file.medium.url}`;
    }
    return URL.createObjectURL(value.file);
  }, [value]);

  if (R.has("_destroy", value) && value._destroy) return null;

  return (
    <ImageListItem style={{ position: "relative" }}>
      <img loading="lazy" src={imgUrl} />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "#DC143C",
          borderBottomRightRadius: "50%",
        }}
      >
        <CloseIcon
          onClick={() => {
            onDelete(index, value);
          }}
          color="inherit"
        />
      </div>
    </ImageListItem>
  );
};

const ProductVisitsImages = ({ control }) => {
  const inputRef = useRef(document.createElement("input"));

  const { append, fields, update, remove } = useFieldArray({
    control: control,
    name: "images_attributes",
  });

  const onImageAdded = useCallback((e) => {
    const file = e.target.files[0];

    append({
      id: uuidv4(),
      file: renameFile(file),
    });
  }, []);

  const showInputFile = useCallback(() => {
    inputRef.current.click();
  }, []);

  useEffect(() => {
    inputRef.current.type = "file";
    inputRef.current.accept = "image/*";
    inputRef.current.onchange = onImageAdded;
  }, [onImageAdded]);

  const onDelete = useCallback(
    (index, item) => {
      if (item.file instanceof File) {
        remove(index);
        return;
      }

      update(index, {
        ...item,
        _destroy: true,
      });
    },
    [update]
  );

  return (
    <Stack spacing={2}>
      <Button variant="contained" color="secondary" onClick={showInputFile}>
        Ajouter une image
      </Button>
      <ImageList cols={4}>
        {fields.map((item, index) => {
          return (
            <Controller
              key={item.id}
              control={control}
              name={`images_attributes.${index}`}
              render={({ ...field }) => (
                <ImageField {...field} onDelete={onDelete} index={index} />
              )}
            />
          );
        })}
      </ImageList>
    </Stack>
  );
};

export const ProductVisitsAddDialog = ({
  trademark_id,
  category_ids,
  product_type_id,
  product_id,
  onClose,
  onSubmit,
}) => {
  const product = useSelector((state) => state.products[product_id]);

  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      power: "",
      age: "",
      opinion: "",
      images_attributes: [],
    },
  });

  const measure_name =
    product.measure && product.measure.name ? product.measure.name : "Unité";
  const measure_unit =
    product.measure && product.measure.unit
      ? "(" + product.measure.unit + ")"
      : "";

  return (
    <Dialog maxWidth="md" fullWidth open={true} onClose={onClose}>
      <DialogTitle>Saisie de matériel</DialogTitle>
      <DialogContent>
        <Paper elevation={4} style={{ padding: "15px" }}>
          <Stack>
            <Typography variant="span" component="h5">
              {product.trademark_name}
              {" - "}
              {product.product_type_name}
              {" - "}
              {product.name}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Stack>
                    <div style={{ marginBottom: "5px" }} className="form-group">
                      <label style={{ fontSize: "11px" }}>
                        {measure_name} {measure_unit}
                      </label>
                      <input
                        min={0}
                        style={{ witdh: "100%" }}
                        className="form-control form-control-sm"
                        type="number"
                        {...register("power")}
                      />
                    </div>
                    <div style={{ marginBottom: "5px" }} className="form-group">
                      <label style={{ fontSize: "11px" }}>Année</label>
                      <select
                        style={{ witdh: "100%" }}
                        className="form-control form-control-sm"
                        {...register("age")}
                      >
                        <option></option>
                        {years.map((y) => {
                          return (
                            <option key={y} value={y}>
                              {y}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div style={{ marginBottom: "5px" }} className="form-group">
                      <label style={{ fontSize: "11px" }}>
                        Avis qualitatif
                      </label>
                      <select
                        style={{ witdh: "100%" }}
                        className="form-control form-control-sm"
                        {...register("opinion")}
                      >
                        <option></option>
                        <option value="bon">Bon</option>
                        <option value="moyen">Moyen</option>
                        <option value="mauvais">Mauvais</option>
                        <option value="hs">Hors-service</option>
                      </select>
                    </div>
                    <div style={{ marginBottom: "5px" }} className="form-group">
                      <label style={{ fontSize: "11px" }}>Commentaire</label>
                      <textarea
                        style={{ witdh: "100%" }}
                        className="form-control form-control-sm product-visit-text-area"
                        {...register("comment")}
                      />
                    </div>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ProductVisitsImages control={control} />
                </Grid>
              </Grid>
            </form>
          </Stack>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          size="small"
          variant="contained"
        >
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductVisitsAddDialog;

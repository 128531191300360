import { flashMessage } from "redux-flash"
import { call, put } from 'redux-saga/effects'
import { deleteRapportError, deleteRapportSuccess, getRapportsError, getRapportsSuccess, postRapportError, postRapportSuccess } from '../actions/RapportsAction'
import { deleteRapportApi, getRapportsApi, postRapportApi } from '../services/Api'
import { getCookie } from '../utils/Cookies'
import history from '../services/History'

export function* getRapportsSaga() {
    try {
        const data = yield call(getRapportsApi, getCookie('access_token'))
        yield put(getRapportsSuccess(data))
    } catch (e) {
        yield put(getRapportsError())
    }
}

export function* postRapportSaga(actions) {
    try {
        const data = yield call(postRapportApi, actions.payload, getCookie('access_token'))
        yield put(postRapportSuccess(data))
        history.replace('/rapports')
        yield put(flashMessage('Elément sauvegardé'))
    } catch (e) {
        yield put(flashMessage('Une erreur est survenu durant la sauvegarde.'))
        yield put(postRapportError())
    }
}

export function* deleteRapportSaga(actions) {
    try {
        yield call(deleteRapportApi, actions.payload, getCookie('access_token'))
        yield put(deleteRapportSuccess(actions.payload))
        yield put(flashMessage('Elément supprimé'))
    } catch (e) {
        yield put(deleteRapportError())
    }
}
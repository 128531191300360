import { object, string } from 'yup'

export const initialValues = {
    name: '',
    sku: '',
    subsku: '',
    zip_code: '',
    city: '',
    address: '',
    comment: '',
    file: null
}

export const validationSchema = object().shape({
    name: string().required('Le nom est requis'),
    sku: string(),
    subsku: string(),
    zip_code: string(),
    city: string(),
    address: string(),
    comment: string()
})
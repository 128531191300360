import { is } from 'ramda';
import Config from '../config/Config';
import { getCookie } from '../utils/Cookies';
import { getFileBlob } from "./StoreFile";
import { APIAuthenticate } from './Api'

const REGEX_LOCAL = /^(local):\/\/[^\s$.?#].[^\s]*$/
const REGEX_BACKEND_URL = new RegExp("^" + Config.value('backendHost') + "[^\\s$.?#].[^\\s]*$|^https:\/\/.*amazonaws.com.*")

export function NetworkError(response, status) {
  this.name = 'NetworkError';
  this.status = status;
  this.response = response;
}

NetworkError.prototype = Error.prototype;

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const getResponseBody = async (res) => {
  const contentType = res.headers.get('content-type') || false;
  if (contentType && contentType.indexOf('json') >= 0) {
    return await res.json();
  }
  return await res.text();
};

export const getHeaders = (headers) => {
  const {
    'Content-Type': contentTypeCapitalized,
    'content-type': contentTypeLowerCase,
    ...restOfHeaders
  } = headers || {};
  const contentType =
    contentTypeCapitalized || contentTypeLowerCase || 'application/json';
  return {
    ...restOfHeaders, 'content-type': contentType, 'Authorization': `Bearer ${getCookie('access_token')}`
  };
};

async function _buildFormData(formData, data, parentKey) {
  if (data && is(Array, data)) {
    await asyncForEach(Object.keys(data), async (key) => {
      await _buildFormData(formData, data[key], parentKey ? `${parentKey}[]` : key);
    })
  } else if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    await asyncForEach(Object.keys(data), async (key) => {
      await _buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    })
  } else {
    const value = data == null ? '' : data;

    if (typeof (value) === 'string' && value.match(REGEX_LOCAL)) {
      const item = await getFileBlob(value)
      formData.append(parentKey, item)
    } else {
      if (!REGEX_BACKEND_URL.test(value)) {
        formData.append(parentKey, value);
      }
    }
  }
}

export const getFormData = async (object) => {
  const formData = new FormData();

  await _buildFormData(formData, object);

  return formData;
};

export default async (effect, _action) => {
  const { url, json, method } = effect;
  try {
    const rsp = await APIAuthenticate.request({
      url: url,
      method: method,
      headers: {
      },
      data: await getFormData(json)
    })
    return rsp.data
  } catch (e) {
    if ("isAxiosError" in e) {
      throw new NetworkError(e.response.data, e.response.status)
    }

  }
}
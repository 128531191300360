import {
  Badge,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./Building.module.css";

const Building = ({
  item,
  onDelete,
  onClone,
  visit_id,
  category_id,
  badge,
  on3D,
  readonly
}) => {
  return (
    <Card className={styles.card}>
      <CardContent>
        <Grid container>
          <Grid xs={12} className="d-flex  justify-content-end">
            {item.panoramics_attributes.length > 0 && (
              <>
                {item.panoramics_attributes.map((pano, index) => (
                  <button
                    key={pano.id}
                    className="btn btn-sm btn-info mr-1"
                    onClick={() => on3D(pano, item.name, index + 1)}
                  >
                    3D #{index + 1}
                  </button>
                ))}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{item.name}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        {
          !readonly &&
          <>
            <button
              className="btn btn-sm btn-danger ml-2"
              onClick={() => onDelete(item)}
            >
              Supprimer
            </button>
            <Link
              className="btn btn-sm btn-warning  ml-2"
              to={`/visites/${visit_id}/batiments/${item.id}/edition`}
            >
              Modifier
            </Link>
            <button
              className="btn btn-sm btn-info  ml-2"
              onClick={() => onClone(item)}
            >
              Dupliquer
            </button>
          </>
        }
        {
          readonly &&
            <Link
              className="btn btn-sm btn-success  ml-2"
              to={`/visites/${visit_id}/batiments/${item.id}/edition`}
            >
              Afficher
            </Link>
        }
        <Badge className=" ml-2" color="primary" badgeContent={badge}>
          <Link
            className="btn btn-sm btn-success"
            to={`/visites/${visit_id}/batiments/${item.id}/visits`}
          >
            <span className="d-none d-sm-block">Equipements</span>
            <span className="d-block d-sm-none">Eqmts</span>
          </Link>
        </Badge>
      </CardActions>
    </Card>
  );
};

export default Building;

import 'bootstrap/dist/css/bootstrap.min.css'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { connect, Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import './App.css'
import { store, persistor } from './config/ConfigureStore'
import Routes from './config/Routes'
import Notifier from './containers/Notifier'
import 'moment/locale/fr'

const InnerApp = ({ bootstrap, applicationStarted }) => {
  if (bootstrap && applicationStarted) {
    return (
      <SnackbarProvider>
        <Routes />
        <Notifier />
      </SnackbarProvider>
    )
  }

  return null
}

const mapStateToProps = (state) => {
  return {
    applicationStarted: state.applications.applicationStarted
  }
}

const InnerAppConnected = connect(mapStateToProps)(InnerApp)

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {
          (bootstrap) => <InnerAppConnected bootstrap={bootstrap} />
        }
      </PersistGate>
    </Provider>
  )
}

export default App

import { Formik } from 'formik'
import * as R from 'ramda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import { flashMessage } from 'redux-flash'
import { v4 as uuidv4 } from 'uuid'
import { object, string } from 'yup'
import { updateVisitAction } from '../../actions/VisitsActions'
import { confirm } from '../../components/Confirms/ConfirmAction'
import ScreenTitle from '../../components/ScreenTitle'
import BuildingForm from './FormContainer'
import { BuildingsActions } from '../../reducers/BuildingsReducer'
import { saveJSONFileInStorage, saveJSONInStorage } from '../../utils/JsonTools'

const INITIAL_VALUE = {
    name: '',
    images_attributes: [],
    panoramics_attributes: [],
}

const validation = object().shape({
    name: string().required('La champ est obligatoire')
})

const BuildingsCreateContainer = ({ }) => {
    const { id, category_id } = useParams()
    const navigation = useNavigate()
    const dispatch = useDispatch()

    const category = useSelector(state => state.category[category_id])
    const visit = useSelector(state => state.visits.entities[id])

    const formikRef = useRef(null)

    const onSubmit = useCallback(async (values, formikBag) => {
        formikBag.setSubmitting(true)

        const valueInLocalForage = await saveJSONFileInStorage(values)
        await saveJSONInStorage('building',values)
        const newValues = {
            ...valueInLocalForage,
            id: uuidv4()
        }
        dispatch(BuildingsActions.createBuildingByVisitAndCategory(newValues, {
            visit_id: id,
            category_id
        }))
        dispatch(flashMessage('Batiment crée'))
        navigation(`/visites/${id}/batiments/${newValues.id}/edition`)
        formikBag.setSubmitting(false)
        dispatch()
    }, [category, id])

    const title = useMemo(() => {
        if (visit && category) {
            return `Batiments - ${visit.location_attributes.label} - ${category.name}`
        }
        return `Batiments`
    }, [visit, category])

    const onGoBack = useCallback(async (e) => {
        e.preventDefault();
        if (formikRef.current.dirty) {
            if (!await confirm("Des éléments dans le formulaire n'ont pas été sauvegardés, êtes-vous sur de vouloir retourner sur la page d'avant ?")) {
                return
            }
        }
        navigation(`/visites/${id}/categorie/${category_id}/batiments`, { replace: true })
    }, [formikRef])

    return (
        <Formik
            innerRef={formikRef}
            initialValues={{
                ...INITIAL_VALUE,
                category_id: category_id,
                visit_id: id,
                badge: 0
            }}
            onSubmit={onSubmit}
            validationSchema={validation}
            enableReinitialize
        >
            {
                ({ dirty, isValid }) => (
                    <div>
                        <ScreenTitle dirty={dirty} onClickBack={onGoBack} back={true} button='Retour' href={`/visites/${id}/categorie/${category_id}/batiments`} title={title} />
                        <BuildingForm isValid={isValid} />
                    </div>
                )
            }
        </Formik>
    )
}

export default BuildingsCreateContainer
import * as R from 'ramda'
import { createReducer } from 'reduxsauce'
import {
    DELETE_P3_REPORTS,

    DELETE_P3_REPORTS_ERROR, DELETE_P3_REPORTS_SUCCESS, GET_P3_REPORTS,

    GET_P3_REPORTS_ERROR, GET_P3_REPORTS_SUCCESS,



    POST_P3_REPORTS, POST_P3_REPORTS_ERROR,
    POST_P3_REPORTS_SUCCESS
} from '../actions'


const INITIAL_STATE = {
    payload: [],
    fetching: true,
    error: null,
    fetchingNew: false,
    fetchingDeletion: false
}


const getP3ReportRequest = (state) => {
    return {
        ...state,
        fetching: true
    }
}

const getP3ReportSuccess = (state, actions) => {
    return {
        ...state,
        fetching: false,
        payload: actions.payload
    }
}

const getP3ReportError = (state, actions) => {
    return {
        ...state,
        fetching: false
    }
}

const postP3ReportRequest = (state, actions) => {
    return {
        ...state,
        fetchingNew: true
    }
}

const postP3ReportSuccess = (state, actions) => {
    return {
        ...state,
        fetchingNew: false,
        payload: [actions.payload, ...state.payload]
    }
}

const postP3ReportError = (state, actions) => {
    return {
        ...state,
        fetchingNew: false
    }
}

const deleteP3ReportRequest = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: true
    }
}

const deleteP3ReportSuccess = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: false,
        payload: R.reject(R.propEq('id', actions.payload), state.payload)
    }
}

const deleteP3ReportError = (state, actions) => {
    return {
        ...state,
        fetchingDeletion: false
    }
}

const HANDLERS = {
    [GET_P3_REPORTS]: getP3ReportRequest,
    [GET_P3_REPORTS_SUCCESS]: getP3ReportSuccess,
    [GET_P3_REPORTS_ERROR]: getP3ReportError,
    [POST_P3_REPORTS]: postP3ReportRequest,
    [POST_P3_REPORTS_ERROR]: postP3ReportError,
    [POST_P3_REPORTS_SUCCESS]: postP3ReportSuccess,
    [DELETE_P3_REPORTS_ERROR]: deleteP3ReportError,
    [DELETE_P3_REPORTS]: deleteP3ReportRequest,
    [DELETE_P3_REPORTS_SUCCESS]: deleteP3ReportSuccess
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
//Actions
import { updateMeAction } from '../actions/MeActions'
// UI Elements
import ProfileForm from '../components/forms/ProfileForm'
import ScreenTitle from '../components/ScreenTitle'


const ProfileContainer = () => {
  const dispatch = useDispatch()
  const onSubmit = useCallback((values) => {
    dispatch(updateMeAction(values))
  }, [])

  return (
    <div>
      <ScreenTitle title="Gestion de profil" />
      <ProfileForm onSubmit={onSubmit} />
    </div>
  )
}

export default ProfileContainer
import { array, date, number, object, string } from "yup";

export const initialValues = {
  contract_type_id: null,
  energy_type_id: null,
  climate_zone_id: null,
  shon: null,
  energies_use: "",
  primary_use: "",
  seen_at: null,
  comment: "",
  location_attributes: {
    label: "",
    street: "",
    zip: "",
    city: "",
    year: "",
  },
  product_visits_attributes: [],
  images_attributes: [],
  buildings_attributes: [],
};

export const validationSchema = object().shape({
  contract_type_id: string().nullable(),
  energy_type_id: string().nullable(),
  climate_zone_id: string().nullable(),
  shon: number().nullable(),
  energies_use: string(),
  primary_use: string(),
  seen_at: date()
    .nullable()
    .required("La date de visite est requise")
    .max(new Date(), "La date de visite doit être avant aujourd'hui"),
  comment: string(),
  product_visits_attributes: array(),
  images_attributes: array(),
  buildings_attributes: array(),
});

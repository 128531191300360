import * as types from './index'

export const productTypesAction = (token, category_id, trademark_id) => {
  return {
    type: types.GET_PRODUCT_TYPES,
    token,
    category_id,
    trademark_id
  }
}

export const resetProductTypesAction = () => {
  return {
    type: types.RESET_PRODUCT_TYPES
  }
}

export const setProductTypesFromSync = (payload) => {
  return {
    type: types.SET_PRODUCT_TYPES_FROM_SYNC,
    payload
  }
}

export const setProductTypesFromSyncDeletion = (payload) => {
  return {
    type: types.SET_PRODUCT_TYPES_FROM_SYNC_DELETION,
    payload
  }
}
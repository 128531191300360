import { REFRESH_TOKEN_REQUEST } from '../actions';
import { store } from '../config/ConfigureStore';

export const customDiscard = async (error, _action, _retries) => {
    if (!('status' in error)) {
        return true;
    }

    if (error.status === 401) {
        store.dispatch({ type: REFRESH_TOKEN_REQUEST })
        return false
    }

    // discard http 4xx errors
    // $FlowFixMe
    return error.status >= 400 && error.status < 500;
}
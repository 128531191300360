import { call, put } from 'redux-saga/effects'
import * as types from '../actions'
import { getProducts } from '../services/Api'



export function* productsSaga(payload) {
  try {
    const response = yield call(getProducts, payload)
    yield put({ type: types.GET_PRODUCTS_SUCCESS, response })

  } catch (error) {
    yield put({ type: types.GET_PRODUCTS_ERROR, error })
  }
}

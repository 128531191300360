import { all, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import * as types from '../actions'
import {
  allotmentsSaga,
  cloneAllotmentSaga,
  destroyAllotmentSaga, getAllotmentSaga
} from './AllotmentsSaga'
import { doStartupApplication } from './ApplicationsSaga'
import { categorySaga, saveCategorySaga } from './CategoriesSaga'
import { climateZonesSaga } from './ClimateZonesSaga'
import { contractTypesSaga } from './ContractTypesSaga'
import { cloneCustomerSaga, customersSaga, getCustomerSaga } from './CustomersSaga'
import { energyTypesSaga } from './EnergyTypesSaga'
import { cloneLocationSaga, destroyLocationSaga, getLocationSaga, locationsSaga } from './LocationsSaga'
import { loginSaga, logoutSaga } from './loginSaga'
import { meSaga, updateMeSaga } from './MeSaga'
import { getProductFromProductAdd, getProductTypesFromProductAdd } from './ProductAddSaga'
import { productsSaga } from './ProductsSaga'
import { productTypesSaga } from './ProductTypesSaga'
import { deleteRapportSaga, getRapportsSaga, postRapportSaga } from './RapportsSaga'
import { deleteCompareRapportSaga, getCompareRapportsSaga, postCompareRapportSaga } from './CompareRapportsSaga'
import { deleteP2ReportSaga, getP2ReportsSaga, postP2ReportSaga } from './P2ReportsSaga'
import { deleteP3ReportSaga, getP3ReportsSaga, postP3ReportSaga } from './P3ReportsSaga'
import { refreshTokenSaga } from './RefreshTokenSaga'
import { syncSaga } from './SyncSaga'
import { destroyTrademarksSaga, trademarksSaga } from './TrademarksSaga'
import { destroyVisitsSaga, visitsSaga, getVisitsFromLocationIDSaga, cloneVisitByIdSaga, getVisitByID } from './VisitsSaga'
import { doFetchOffices } from './OfficesSaga'

import { VisitsActions } from '../reducers/VisitsReducer'
import { BuildingsActions } from '../reducers/BuildingsReducer'
import { getBuildingsFromVisit, cloneBuildingByID, destroyBuildingByID } from './BuildingsSaga'
import { getProductsVisitsFromVisit } from './ProductsVisitsSaga'
import { ProductsVisitsActions } from '../reducers/ProductsVisitsReducer'
import { deleteImageBankSaga, getImageBanksSaga, postImageBankSaga, getImageBankSaga, getPublicImageBankSaga } from './ImageBanksSaga'

export default function* startForman() {
  yield all([
    takeLatest(types.LOGIN_USER, loginSaga),
    takeLatest(types.GET_ME, meSaga),
    takeLatest(types.UPDATE_ME, updateMeSaga),
    takeLatest(types.GET_TRADEMARKS, trademarksSaga),
    takeLatest(types.GET_CONTRACT_TYPES, contractTypesSaga),
    takeLatest(types.GET_ENERGY_TYPES, energyTypesSaga),
    takeLatest(types.GET_CLIMATE_ZONES, climateZonesSaga),
    takeEvery(types.GET_CATEGORY, categorySaga),
    takeLatest(types.GET_PRODUCT_TYPES, productTypesSaga),
    takeLatest(types.GET_PRODUCTS, productsSaga),
    takeLatest(types.GET_CUSTOMERS, customersSaga),
    takeLatest(types.GET_CUSTOMER, getCustomerSaga),
    takeEvery(types.GET_LOCATIONS, locationsSaga),
    takeLatest(types.GET_LOCATION, getLocationSaga),
    takeLatest(types.GET_ALLOTMENTS, allotmentsSaga),
    takeLatest(types.GET_ALLOTMENT, getAllotmentSaga),
    takeLatest(types.SAVE_CATEGORY, saveCategorySaga),
    takeLatest(types.PRODUCT_ADD_REQUEST_PRODUCT_TYPE, getProductTypesFromProductAdd),
    takeLatest(types.PRODUCT_ADD_REQUEST_PRODUCT, getProductFromProductAdd),
    takeLatest(types.DESTROY_TRADEMARKS, destroyTrademarksSaga),
    takeLatest(types.DESTROY_LOCATION, destroyLocationSaga),
    takeLatest(types.DESTROY_VISIT, destroyVisitsSaga),
    takeLatest(types.GET_RAPPORTS, getRapportsSaga),
    takeLatest(types.POST_RAPPORTS, postRapportSaga),
    takeLatest(types.DELETE_RAPPORTS, deleteRapportSaga),
    takeLatest(types.GET_IMAGE_BANKS, getImageBanksSaga),
    takeLatest(types.GET_IMAGE_BANK, getImageBankSaga),
    takeLatest(types.GET_PUBLIC_IMAGE_BANK, getPublicImageBankSaga),
    takeLatest(types.POST_IMAGE_BANKS, postImageBankSaga),
    takeLatest(types.DELETE_IMAGE_BANKS, deleteImageBankSaga),
    takeLatest(types.GET_COMPARE_RAPPORTS, getCompareRapportsSaga),
    takeLatest(types.POST_COMPARE_RAPPORTS, postCompareRapportSaga),
    takeLatest(types.DELETE_COMPARE_RAPPORTS, deleteCompareRapportSaga),
    takeLatest(types.CLONE_CUSTOMER, cloneCustomerSaga),
    takeLatest(types.CLONE_ALLOTMENT, cloneAllotmentSaga),
    takeLatest(types.CLONE_LOCATION, cloneLocationSaga),
    takeLeading(types.APPLICATION_STARTUP, doStartupApplication),
    takeLeading(types.REFRESH_TOKEN_REQUEST, refreshTokenSaga),
    takeLeading(types.LOGIN_USER_SUCCESS, syncSaga),
    takeLeading(types.LOGOUT_REQUEST, logoutSaga),
    takeLatest(types.DESTROY_ALLOTMENT, destroyAllotmentSaga),
    takeLatest(types.OFFICE_REQUEST, doFetchOffices),
    takeLatest(types.GET_P2_REPORTS, getP2ReportsSaga),
    takeLatest(types.POST_P2_REPORTS, postP2ReportSaga),
    takeLatest(types.DELETE_P2_REPORTS, deleteP2ReportSaga),
    takeLatest(types.GET_P3_REPORTS, getP3ReportsSaga),
    takeLatest(types.POST_P3_REPORTS, postP3ReportSaga),
    takeLatest(types.DELETE_P3_REPORTS, deleteP3ReportSaga),

    // Visits
    takeEvery(VisitsActions.requestVisitsFromLocation.type, getVisitsFromLocationIDSaga),
    takeLatest(VisitsActions.cloneVisitByID.type, cloneVisitByIdSaga),
    takeLeading(VisitsActions.requestVisitById.type, getVisitByID),

    // Buildings
    takeEvery(BuildingsActions.requestBuildingsFromVisit.type, getBuildingsFromVisit),
    takeEvery(BuildingsActions.destroyBuilding.type, destroyBuildingByID),
    takeEvery(ProductsVisitsActions.requestFromVisitId.type, getProductsVisitsFromVisit)
  ])
}

import { Badge } from "@mui/material";
import { Formik } from "formik";
import * as R from "ramda";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Redirect from "../../components/Redirect";
import { flashMessage } from "redux-flash";
import { createSelector } from "reselect";
import { allotmentsAction } from "../../actions/AllotmentsActions";
import { customerAction } from "../../actions/CustomersActions";
import {
  getLocationAction,
  updateLocationAction,
} from "../../actions/LocationsActions";
import { confirm } from "../../components/Confirms/ConfirmAction";
import LocationForm from "../../components/forms/LocationForm";
import ScreenTitle from "../../components/ScreenTitle";
import { validationSchema } from "../../Models/Location";
import { buildLocationsKey } from "../../reducers/LocationsReducer";
import { saveJSONInStorage } from '../../utils/JsonTools'

const selectEntities = (state) => state.allotments.entities;
const selectAllotmentsObject = (customer_id) => (state) =>
  R.compose(
    R.defaultTo([]),
    R.path(["allotments", "ids", customer_id, "allotment_ids"])
  )(state);

const UpdateLocationContainer = ({ match, history }) => {
  const navigation = useNavigate();
  const { customer_id, allotment_id = null, id } = useParams();
  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const selector = useMemo(() => {
    const _selectAllotmentsObject = selectAllotmentsObject(customer_id);
    const entitiesAllotomentsSelector = createSelector(
      selectEntities,
      _selectAllotmentsObject,
      (entities, allotment_ids) => {
        return R.compose(
          R.values,
          R.filter((x) => {
            return (
              x.customer_id === customer_id && allotment_ids.includes(x.id)
            );
          })
        )(entities);
      }
    );

    const _key = buildLocationsKey(customer_id, allotment_id);

    return (state) => {
      return {
        allotments: entitiesAllotomentsSelector(state),
        location: state.locations.entities[id],
        customer: state.customers.entities[customer_id],
        loading: state.locations.loading,
        error: state.locations.error,
        isWaitForSync: R.compose(
          R.includes(id),
          R.defaultTo([]),
          R.path(["locations", "ids", _key, "waitForSync"])
        )(state),
      };
    };
  }, [customer_id]);
  const { customer, loading, error, location, allotments, isWaitForSync } =
    useSelector(selector);

  useEffect(() => {
    dispatch(customerAction(customer_id));
    if (!isWaitForSync) {
      dispatch(getLocationAction(id));
    }
    dispatch(allotmentsAction(customer_id));
  }, []);

  const title = useMemo(() => {
    if (!customer) return "";
    return `${customer.name} - Edition d'un site`;
  }, []);

  const onBack = useCallback(
    async (e) => {
      e.preventDefault();
      if (formikRef.current && formikRef.current.dirty) {
        if (
          !(await confirm(
            "Des éléments dans le formulaire n'ont pas été sauvegardés, êtes-vous sur de vouloir retourner sur la page d'avant ?"
          ))
        ) {
          return;
        }
      }
      if (allotment_id) {
        navigation(`/customers/${customer_id}/lots/${allotment_id}/locations`);
        return;
      }
      navigation(`/customers/${customer_id}/locations`);
    },
    [formikRef]
  );

  const onSubmit = useCallback(
    (values, formikCtx) => {
      formikCtx.setSubmitting(true);
      dispatch(
        updateLocationAction(customer_id, allotment_id, id, {
          ...values,
          updated_at: new Date().toISOString(),
        })
      );
      formikCtx.setSubmitting(false);
      dispatch(flashMessage("Le site a été mis à jour."));
      saveJSONInStorage('location',values)
      if (allotment_id) {
        navigation(`/customers/${customer_id}/lots/${allotment_id}/locations`);
        return;
      }
      navigation(`/customers/${customer_id}/locations`);
    },
    [customer_id, allotment_id, id]
  );

  const visitUrl = useMemo(() => {
    if (allotment_id) {
      return `/customers/${customer_id}/lots/${allotment_id}/locations/${id}/visits`;
    }

    return `/customers/${customer_id}/locations/${id}/visits`;
  }, []);

  if (loading && R.isNil(location)) {
    return (
      <div>
        <ScreenTitle
          back={true}
          button="Retour"
          onClickBack={() => {
            history.replace(`/customers/${customer_id}/locations`);
          }}
          title={"Chargement du lots..."}
        />
      </div>
    );
  }

  if (!loading && R.isNil(location) && error === "404") {
    return <Redirect to={`/customers/${customer_id}/locations`} />;
  }

  return (
    <div>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={location}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid }) => {
          return (
            <>
              <ScreenTitle
                dirty={dirty}
                onClickBack={onBack}
                back={true}
                button="Retour"
                title={title}
              >
                <div>
                  <Badge
                    color="secondary"
                    badgeContent={location.visits_counter}
                  >
                    <Link
                      className="btn btn-success btn-sm"
                      p={2}
                      m={1}
                      size="small"
                      variant="contained"
                      to={visitUrl}
                    >
                      Visites
                    </Link>
                  </Badge>
                </div>
              </ScreenTitle>
              <LocationForm isValid={isValid} allotments={allotments} />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateLocationContainer;

import * as R from 'ramda'
import { call, put, select } from 'redux-saga/effects'
import { setCategoryFromSync, setCategoryFromSyncDeletion } from '../actions/CategoriesActions'
import { climateZonesAction } from '../actions/ClimateZonesActions'
import { contractTypesAction } from '../actions/ContractTypesActions'
import { energyTypesAction } from '../actions/EnergyTypesActions'
import { setProductFromSync, setProductFromSyncDeletion } from '../actions/ProductsActions'
import { setProductTypesFromSync, setProductTypesFromSyncDeletion } from '../actions/ProductTypesActions'
import { setTrademarkFromSync, setTrademarkFromSyncDeletion } from '../actions/TrademarksActions'
import { getSyncCategories, getSyncProducts, getSyncProductTypes, getSyncTrademarks, removeSyncCategories, removeSyncProducts, removeSyncProductTypes, removeSyncTrademarks } from '../services/Api'
import { makeApi } from '../services/ApiSaga'

export function* syncCategoriesSaga() {
    const categories = yield select((state) => state.category)
    const category_ids = Object.values(categories)
    const category_ids_with_updated_at = R.filter((x) => {
        return R.has('updated_at', x) && R.prop('updated_at', x)
    }, category_ids)
    const maxDateString = Math.max(...R.map(x => new Date(x.updated_at), category_ids_with_updated_at))
    const maxDate = maxDateString === -Infinity ? null : new Date(maxDateString)
    const rsp = yield call(makeApi, getSyncCategories, 'access_token', maxDate ? maxDate.toISOString() : '')

    const categoriesData = rsp.reduce((prev, current) => {
        prev[current.id] = current
        return prev
    }, {})

    yield put(setCategoryFromSync(categoriesData))

    const categories_ids = yield select((state) => Object.keys(state.category))
    const data = yield call(makeApi, removeSyncCategories, { ids: categories_ids })
    yield put(setCategoryFromSyncDeletion(data))
}

export function* syncProductTypesSaga() {
    const productTypes = yield select((state) => state.product_types)
    const productTypesObj = Object.values(productTypes)
    const productTypesObjUpdatedAt = R.filter((x) => {
        return R.has('updated_at', x) && R.prop('updated_at', x)
    }, productTypesObj)
    const maxDateString = Math.max(...R.compose(R.map(x => new Date(x)), R.reject(R.isNil), R.map(R.prop('updated_at')))(productTypesObjUpdatedAt))
    const maxDate = maxDateString === -Infinity ? null : new Date(maxDateString)

    const rsp = yield call(makeApi, getSyncProductTypes, 'access_token', maxDate ? maxDate.toISOString() : '')

    const productTypesData = rsp.reduce((prev, current) => {
        prev[current.id] = current
        return prev
    }, {})

    yield put(setProductTypesFromSync(productTypesData))

    const product_types_ids = yield select((state) => Object.keys(state.product_types))
    const data = yield call(makeApi, removeSyncProductTypes, { ids: product_types_ids })
    yield put(setProductTypesFromSyncDeletion(data))
}

export function* syncProductsSaga() {
    const products = yield select((state) => state.products)
    const productsObject = Object.values(products)
    const productsObjectUpdatedAt = R.filter((x) => {
        return R.has('updated_at', x) && R.prop('updated_at', x)
    }, productsObject)
    const maxDateString = Math.max(...R.compose(R.map(x => new Date(x)), R.reject(R.isNil), R.map(R.prop('updated_at')))(productsObjectUpdatedAt))
    const maxDate = maxDateString === -Infinity ? null : new Date(maxDateString)
    const rsp = yield call(makeApi, getSyncProducts, 'access_token', maxDate ? maxDate.toISOString() : '')

    const productsData = rsp.reduce((prev, current) => {
        prev[current.id] = current
        return prev
    }, {})

    yield put(setProductFromSync(productsData))

    const products_ids = yield select((state) => Object.keys(state.products))
    const data = yield call(makeApi, removeSyncProducts, { ids: products_ids })
    yield put(setProductFromSyncDeletion(data))
}

export function* syncTrademarkSaga() {
    const trademarks = yield select((state) => state.trademarks)
    const trademarksObject = Object.values(trademarks)
    const trademarksObjectUpdated_at = R.filter((x) => {
        return R.has('updated_at', x) && R.prop('updated_at', x)
    }, trademarksObject)
    const maxDateString = Math.max(...R.compose(R.map(x => new Date(x)), R.reject(R.isNil), R.map(R.prop('updated_at')))(trademarksObjectUpdated_at))
    const maxDate = maxDateString === -Infinity ? null : new Date(maxDateString)
    const rsp = yield call(makeApi, getSyncTrademarks, 'access_token', maxDate ? maxDate.toISOString() : '')
    const productsData = rsp.reduce((prev, current) => {
        prev[current.id] = current
        return prev
    }, {})
    yield put(setTrademarkFromSync(productsData))

    const trademarks_ids = yield select((state) => Object.keys(state.trademarks))
    const data = yield call(makeApi, removeSyncTrademarks, { ids: trademarks_ids })
    yield put(setTrademarkFromSyncDeletion(data))

}

export function* syncOther() {
    yield put(contractTypesAction())
    yield put(climateZonesAction())
    yield put(energyTypesAction())
}

export function* syncSaga() {
    try {
        yield call(syncCategoriesSaga)
        yield call(syncProductTypesSaga)
        yield call(syncProductsSaga)
        yield call(syncTrademarkSaga)
        yield call(syncOther)
    } catch (e) {
        console.log('ERR', e)
    }
}
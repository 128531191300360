import * as R from 'ramda'
import * as types from '../actions'

export default function (state = {}, action) {
  const response = action.data

  switch (action.type) {
    case types.GET_CATEGORY:
      return state
    case types.GET_CATEGORY_SUCCESS:
      const data = R.mergeRight(state, response)
      return data
    case types.GET_CATEGORY_ERROR:
      return state
    case types.SET_CATEGORY_FROM_SYNC:
      return R.mergeRight(state, action.payload)
    case types.SET_CATEGORY_FROM_SYNC_DELETION:
      return R.omit(action.payload, state)
    case types.PRODUCT_ADD_REQUEST:
      const { payload: { trademark }, meta: { category_id } } = action
      const category = {
        ...state[category_id],
        trademarks: R.uniqBy(R.prop('id'), [
          ...state[category_id].trademarks,
          trademark
        ])
      };
      return R.mergeRight(state, { [category_id]: category })
    default:
      return state
  }
}

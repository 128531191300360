import RamdamColor from 'randomcolor'
import { call, put } from 'redux-saga/effects'
import * as types from '../actions'
import { SaveCategoryErrorAction, SaveCategorySuccessAction } from '../actions/CategoriesActions'
import { getCategory, getRootsCategories, saveCategory } from '../services/Api'
import { getCookie } from '../utils/Cookies'



export function* rootsCategoriesSaga(payload) {
  try {
    const response = yield call(getRootsCategories, payload)
    yield put({ type: types.GET_ROOTS_CATEGORIES_SUCCESS, response })

  } catch (error) {
    yield put({ type: types.GET_ROOTS_CATEGORIES_ERROR, error })
  }
}

export function* categorySaga(payload) {
  const { meta: { with_color } } = payload
  try {
    const response = yield call(getCategory, payload)
    const token = getCookie('access_token')

    if (with_color) {
      const generatedColor = RamdamColor({
        //hue: 'monochrome',
        seed: "908S7DHQzaaz89D78SQD90Q0SD98QS0Dç_à*ù$ù",
        count: response.children.length * response.children.length,
        luminosity: 'dark',
      })
      const countChildren = response.children.length + 1
      const colorPadding = Math.round(0xFFFFFF / countChildren)
      const newResponse = {
        ...response,
        children: response.children.map((v, i) => {
          return {
            ...v,
            color: generatedColor[i * i]
          }
        })
      }
      yield put({ type: types.GET_CATEGORY_SUCCESS, data: { [response.id.toString()]: newResponse } })

    } else {
      yield put({ type: types.GET_CATEGORY_SUCCESS, data: { [response.id.toString()]: response } })
    }
  } catch (error) {
    yield put({ type: types.GET_CATEGORY_ERROR, error })
  }
}

export function* saveCategorySaga(action) {
  const { payload: { label, parent_id } } = action
  const token = getCookie('access_token')

  try {
    const response = yield call(saveCategory, { token: token, category: { name: label, parent_id: parent_id } })

    yield put({ type: types.GET_CATEGORY, token, id: parent_id })
    yield put(SaveCategorySuccessAction())
  } catch (e) {
    yield put(SaveCategoryErrorAction())
  }
}
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Redirect = ({ to, state }) => {
    const navigation = useNavigate()

    useEffect(() => {
        navigation(to, { state: state, replace: true })
    }, [])
    return null
}

export default Redirect
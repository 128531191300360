import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import FuseJS from "fuse.js";
import * as R from "ramda";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
// Actions
import {
  cloneCustomerAction,
  customersAction,
} from "../actions/CustomersActions";
import Customer from "../components/Customer";
// UI Elements
import ScreenTitle from "../components/ScreenTitle";
import { getCookie } from "../utils/Cookies";
import { useNavigate } from "react-router";
import { createSelector } from "reselect";
import { isReadOnlySelector } from "../reducers/MeReducer";

const searchOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name", "address", "zip_code", "city", "comment"],
};

const sortByNameCaseInsensitive = R.sortBy(
  R.compose(R.toLower, R.prop("name"))
);

const customersIdsSelector = (state) =>
  R.uniq([...state.customers.customers_ids, ...state.customers.waitForSync]);
const customersEntities = (state) => state.customers.entities;

const customersSelector = createSelector(
  customersIdsSelector,
  customersEntities,
  (ids, entities) => {
    return sortByNameCaseInsensitive(ids.map((x) => entities[x]));
  }
);

const DashboardContainer = ({}) => {
  const fuseJSRef = useRef(new FuseJS([], searchOptions));
  const navigation = useNavigate();
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const isReadOnly = useSelector(isReadOnlySelector)
  const customers = useSelector(customersSelector);
  const loading = useSelector((state) => state.customers.loading);

  useEffect(() => {
    const token = getCookie("access_token");
    dispatch(customersAction(token));
  }, []);

  useEffect(() => {
    fuseJSRef.current.setCollection(customers);
  }, [customers]);

  const customersFiltered = useMemo(() => {
    if (search === "") return customers;
    const filters = fuseJSRef.current.search(search);
    return R.map((x) => x.item, filters);
  }, [search, customers]);

  const onSearch = useCallback(
    (_value = "") => {
      setSearch(_value);
    },
    [customers]
  );

  const onClone = useCallback((id) => {
    dispatch(cloneCustomerAction(id));
  }, []);

  return (
    <div>
      <ScreenTitle
        loading={loading}
        onSearch={onSearch}
        title="Tableau de bord"
      />
      <Grid container spacing={1}>
        {customersFiltered.map((v, i) => {
          return (
            <Grid key={v.id} item xs={12} sm={12} md={6} lg={4}>
              <Customer readonly={isReadOnly} onClone={onClone} data={v} size={4} />
            </Grid>
          );
        })}
      </Grid>
      {
        !isReadOnly &&
          <Fab
            onClick={() => {
              navigation("/customers_new");
            }}
            color="primary"
            aria-label="add"
            className="fab-add"
          >
            <AddIcon />
          </Fab>
      }
    </div>
  );
};

export default DashboardContainer;

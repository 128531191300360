import { object, string } from 'yup'

export const validationSchema = object().shape({
    label: string().required('Le nom est requis'),
    street: string().required('La rue est requise'),
    zip: string().required('Le code postal est requis'),
    city: string().required('La ville est requise'),
    year: string().nullable(),
    allotment_id: string().nullable(),
    comment: string()
})
export const initialValues = {
    label: '',
    street: '',
    zip: '',
    city: '',
    year: '',
    allotment_id: null,
    comment: ''
}
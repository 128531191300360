import { Formik } from "formik";
import * as R from "ramda";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { flashMessage } from "redux-flash";
import { createSelector } from "reselect";
import { v4 as uuidv4 } from "uuid";
import { allotmentsAction } from "../../actions/AllotmentsActions";
import { customerAction } from "../../actions/CustomersActions";
import { createLocationAction } from "../../actions/LocationsActions";
import { confirm } from "../../components/Confirms/ConfirmAction";
import LocationForm from "../../components/forms/LocationForm";
import ScreenTitle from "../../components/ScreenTitle";
import { initialValues, validationSchema } from "../../Models/Location";
import { saveJSONInStorage } from '../../utils/JsonTools'

const selectAllotmentsEntitites = (state) => state.allotments.entities;
const selectAllotmentsIds = (customer_id) => (state) => {
  const { allotment_ids = [], waitForSync = [] } =
    state.allotments.ids[customer_id] || {};

  return R.uniq([...allotment_ids, ...waitForSync]);
};

const selectAllotments = (customer_id) =>
  createSelector(
    selectAllotmentsIds(customer_id),
    selectAllotmentsEntitites,
    (ids, entities) => {
      return ids.map((x) => entities[x]);
    }
  );

const CreateLocationContainer = ({ match, history }) => {
  const navigation = useNavigate();
  const { customer_id, allotment_id = null } = useParams();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const allotments = useSelector(selectAllotments(customer_id));

  const customer = useSelector(
    (state) => state.customers.entities[customer_id]
  );

  useEffect(() => {
    dispatch(customerAction(customer_id));
    dispatch(allotmentsAction(customer_id));
  }, []);

  const title = useMemo(() => {
    if (!customer) return "";
    return `${customer.name} - Nouveau site`;
  }, []);

  const onBack = useCallback(
    async (e) => {
      e.preventDefault();
      if (formikRef.current && formikRef.current.dirty) {
        if (
          !(await confirm(
            "Des éléments dans le formulaire n'ont pas été sauvegardés, êtes-vous sur de vouloir retourner sur la page d'avant ?"
          ))
        ) {
          return;
        }
      }
      if (allotment_id) {
        navigation(`/customers/${customer_id}/lots/${allotment_id}/locations`);
        return;
      }
      navigation(`/customers/${customer_id}/locations`);
    },
    [formikRef]
  );

  const onSubmit = useCallback(
    (values, formikCtx) => {
      formikCtx.setSubmitting(true);
      const newValues = {
        ...values,
        customer_id: customer_id,
        allotment_id: allotment_id,
        id: uuidv4(),
      };
      saveJSONInStorage('location',newValues)
      dispatch(createLocationAction(customer_id, allotment_id, newValues));
      formikCtx.setSubmitting(false);
      dispatch(flashMessage("Le site a été créé."));
      if (allotment_id) {
        navigation(
          `/customers/${customer_id}/lots/${allotment_id}/locations/${newValues.id}/edit`
        );
        return;
      }
      navigation(`/customers/${customer_id}/locations/${newValues.id}/edit`);
    },
    [customer_id, allotment_id]
  );

  return (
    <div>
      <Formik
        innerRef={formikRef}
        initialValues={{
          ...initialValues,
          allotment_id: allotment_id,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid }) => {
          return (
            <>
              <ScreenTitle
                dirty={dirty}
                onClickBack={onBack}
                back={true}
                button="Retour"
                title={title}
              />
              <LocationForm isValid={isValid} allotments={allotments} />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateLocationContainer;

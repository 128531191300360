import * as R from "ramda";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScreenTitle from "../../components/ScreenTitle";
import { publicImageBankAction } from "../../actions/ImageBanksAction";
import { useParams } from "react-router";
import { Pannellum } from "pannellum-react";
import moment from "moment";

const ShowContainer = ({ match, history, ...props }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { image_bank, error, isWaitForSync, loading } = useSelector((state) => {
    return {
      image_bank: state.image_banks.item,
      error: state.image_banks.error,
      loading: state.image_banks.loading,
      isWaitForSync: R.compose(
        R.includes(id),
        R.defaultTo([]),
        R.path(["image_banks", "waitForSync"])
      )(state),
    };
  });
  useEffect(() => {
    dispatch(publicImageBankAction(id));
  }, []);

  if( error !==  "404" || error !== '500') {
    return (
      <div>

        {image_bank && image_bank.file &&
          ((image_bank.expire_date !== null &&
            moment(image_bank.expire_date) >= moment()) ||
            image_bank.expire_date == null) && (
            <div>
              <ScreenTitle title={image_bank.title} />
              <Pannellum
                width="100%"
                height="500px"
                image={image_bank.file}
                pitch={10}
                yaw={180}
                hfov={110}
                autoLoad
                onLoad={() => {
                  console.log("panorama loaded");
                }}
              ></Pannellum>
            </div>
          )
        }
        { image_bank &&
          ((image_bank.expire_date !== null &&
            moment(image_bank.expire_date) <= moment())) && (
            <div>
              <ScreenTitle title="Cette image n'est plus disponible" />
            </div>
          )
        }
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <ScreenTitle title="Cette image n'est plus disponible" />
        </div>
      </div>
    );
  }
};

export default ShowContainer;

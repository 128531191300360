import AddIcon from "@mui/icons-material/Add";
import { Button, Fab, Grid, Typography } from "@mui/material";
import FuseJS from "fuse.js";
import { Pannellum } from "pannellum-react";
import * as R from "ramda";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Building from "../components/Building";
import { confirm } from "../components/Confirms/ConfirmDestroy";
import ScreenTitle from "../components/ScreenTitle";

import { createSelector } from "reselect";
import {
  BuildingsActions,
  getBuildingsForCategoryId,
} from "../reducers/BuildingsReducer";
import { isReadOnlySelector } from "../reducers/MeReducer";

const searchOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: false,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["name"],
};

const selectProductsVisitisIds = (visit_id) => (state) =>
  [
    ...(state.products_visits.ids[visit_id] || []),
    ...(state.products_visits.versions[visit_id] || []),
  ];

const selectProductsVisitsEntity = (state) => state.products_visits.entities;

const countProductsVisitsForBuilding = (visit_id, building_id) =>
  createSelector(
    selectProductsVisitisIds(visit_id),
    selectProductsVisitsEntity,
    (ids, entities) => {
      return ids.filter((x) => {
        if (!entities[x]) return false;
        return entities[x].building_id === building_id;
      }).length;
    }
  );

const BuildingsItem = ({
  visit_id,
  category_id,
  building,
  onClone,
  onDelete,
  on3D,
  fullWidth,
  readonly
}) => {
  const badge = useSelector(
    countProductsVisitsForBuilding(visit_id, building.id)
  );
  return (
    <Grid item xs={12} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 4}>
      <Building
        readonly={readonly}
        badge={badge}
        visit_id={visit_id}
        category_id={category_id}
        onClone={onClone}
        onDelete={onDelete}
        item={building}
        on3D={on3D}
      />
    </Grid>
  );
};

const BuildingsContainer = ({}) => {
  const navigation = useNavigate();
  const [viewer3D, setViewer3D] = useState(null);
  const { id, category_id } = useParams();
  const FuseJSRef = useRef(new FuseJS([], searchOptions));
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const isReadOnly = useSelector(isReadOnlySelector)

  const entities = useSelector(getBuildingsForCategoryId(id, category_id));

  useEffect(() => {
    FuseJSRef.current.setCollection(entities);
  }, [entities]);

  const buildings = useMemo(() => {
    if (search === "") return entities;
    const filters = FuseJSRef.current.search(search);
    return R.map((x) => x.item, filters);
  }, [search, entities]);

  const visit = useSelector((state) => state.visits.entities[id]);
  const category = useSelector((state) => state.category[category_id]);
  const title = useMemo(() => {
    if (visit && category) {
      return `Batiments - ${visit.location_attributes.label} - ${category.name}`;
    }
    return `Batiments`;
  }, [visit, category]);

  const onDelete = useCallback(async (item) => {
    if (await confirm(`Êtes-vous sur de vouloir supprimer ${item.name} ?`)) {
      dispatch(BuildingsActions.destroyBuilding(item.id, id));
    }
  });

  const onClone = useCallback(async (item) => {
    const value = {
      ...item,
      name: `${item.name} (copie)`,
      id: uuidv4(),
      badge: 0,
      images_attributes: [],
      panoramics_attributes: [],
    };
    dispatch(
      BuildingsActions.createBuildingByVisitAndCategory(value, {
        visit_id: id,
        category_id,
      })
    );
  });

  const on3D = useCallback((item, name, index) => {
    setViewer3D((oldState) => {
      if (oldState && oldState.item.id === item.id) return null;
      return { item, name, index };
    });
  }, []);

  return (
    <div>
      <ScreenTitle
        onSearch={setSearch}
        back={true}
        button="Retour"
        href={`/visites/${id}/edition`}
        title={title}
      />
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={!viewer3D ? 12 : 6}
          lg={!viewer3D ? 12 : 6}
          sx={{ display: { xs: !viewer3D ? "block" : "none", md: "block" } }}
        >
          <Grid container spacing={1}>
            {buildings.map((item) => {
              return (
                <BuildingsItem
                  readonly={isReadOnly}
                  key={item.id}
                  fullWidth={!!viewer3D}
                  building={item}
                  visit_id={id}
                  category_id={category_id}
                  onClone={onClone}
                  onDelete={onDelete}
                  on3D={on3D}
                />
              );
            })}
          </Grid>
        </Grid>
        {viewer3D && (
          <Grid item xs={12} md={6} lg={6}>
            <Pannellum
              width="100%"
              height="500px"
              pitch={10}
              yaw={180}
              hfov={110}
              autoLoad
              image={viewer3D.item.file}
            />
            <Typography textAlign="center">
              {viewer3D.name} 3D #{viewer3D.index}
            </Typography>
            <Button
              sx={{ display: { xs: "inline-flex", md: "none" } }}
              onClick={() => setViewer3D(null)}
              color="error"
              variant="contained"
            >
              FERMER
            </Button>
          </Grid>
        )}
      </Grid>
      {
        !isReadOnly &&
          <Fab
            onClick={() => navigation(`create`)}
            color="primary"
            aria-label="add"
            className="fab-add"
          >
            <AddIcon />
          </Fab>
      }
    </div>
  );
};

export default BuildingsContainer;

import { object, string, date } from 'yup'

export const initialValues = {
    title: '',
    expire_date: null,
    file: null
}

export const validationSchema = object().shape({
    title: string().required('Le titre est requis'),
    expire_date: date().nullable()
})
import { call, put } from 'redux-saga/effects'
import * as types from '../actions'
import { enqueueSnackbar } from '../actions/notistackActions'
import { destroyTrademarksFailureActions, destroyTrademarksSuccessActions } from '../actions/TrademarksActions'
import { destroyTrademarks, getTrademarks } from '../services/Api'
import { getCookie } from '../utils/Cookies'


export function* trademarksSaga(payload) {
  try {
    const response = yield call(getTrademarks, payload)
    yield put({ type: types.GET_TRADEMARKS_SUCCESS, response })

  } catch (error) {
    yield put({ type: types.GET_TRADEMARKS_ERROR, error })
  }
}

export function* destroyTrademarksSaga(action) {
  const { payload: { id } } = action
  const token = getCookie('access_token')

  try {
    const payload = { token: token, id: id }
    const response = yield call(destroyTrademarks, payload)
    if (response.status === 200) {
      yield put(enqueueSnackbar({
        message: 'Suppression réussie !',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      }))
      yield put(destroyTrademarksSuccessActions(id))
    } else {
      yield put(enqueueSnackbar({
        message: 'Un produit est encore lié à une visite pour cette marque.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }))
      yield put(destroyTrademarksFailureActions(id))
    }
  } catch (e) {
    yield put(destroyTrademarksFailureActions(id))
  }
}
import { Formik } from 'formik'
import React, { useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { flashMessage } from 'redux-flash'
import { v4 as uuidv4 } from 'uuid'
import { createCustomerAction } from '../../actions/CustomersActions'
import { confirm } from '../../components/Confirms/ConfirmAction'
import CustomerForm from '../../components/forms/CustomerForm'
import ScreenTitle from '../../components/ScreenTitle'
import { initialValues, validationSchema } from '../../Models/Customer'
import { getCookie } from '../../utils/Cookies'
import { saveJSONFileInStorage, saveJSONInStorage } from '../../utils/JsonTools'


const CreateCustomerContainer = ({ }) => {
    const navigation = useNavigate()
    const formikRef = useRef(null)
    const dispatch = useDispatch()

    const onBack = useCallback(async (e) => {
        e.preventDefault()

        if (formikRef.current.dirty) {
            if (!await confirm("Des éléments dans le formulaire n'ont pas été sauvegardés, êtes-vous sur de vouloir retourner sur la page d'avant ?")) {
                return
            }
        }
        navigation('/dashboard')
    }, [formikRef])

    const onSubmit = useCallback(async (values, formikCtx) => {
        formikCtx.setSubmitting(true)
        const newValues = await saveJSONFileInStorage({ id: uuidv4(), ...values })
        await saveJSONInStorage('customer', { id: uuidv4(), ...values });
        dispatch(createCustomerAction(getCookie('access_token'), newValues))
        formikCtx.setSubmitting(false)
        navigation(`/customers/${newValues.id}/edit`, { replace: true })
        dispatch(flashMessage('Le client a été créé.'))
    }, [])

    return (
        <div>
            <Formik
                innerRef={formikRef}
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {
                    ({ dirty, isValid }) => {
                        return (
                            <>
                                <ScreenTitle dirty={dirty} back={true} button='Retour' onClickBack={onBack} title={'Nouveau client'} />
                                <CustomerForm isValid={isValid} btnTitle='Sauvegarder' />
                            </>
                        )
                    }
                }
            </Formik>
        </div>
    )
}

export default CreateCustomerContainer
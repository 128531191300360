import * as types from '../actions'

const INITIAL_STATE = {
    product_types: {
        fetching: false,
        data: [],
        error: null,
    },
    products: {
        fetching: false,
        data: [],
        error: null,
    }
}

export const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.PRODUCT_ADD_REQUEST_PRODUCT_TYPE:
            return {
                ...state,
                product_types: {
                    fetching: true,
                    data: [],
                    error: null,
                }
            }
        case types.PRODUCT_ADD_REQUEST_PRODUCT_TYPE_SUCCESS:
            return {
                ...state,
                product_types: {
                    fetching: false,
                    data: action.payload,
                    error: null,
                }
            }
        case types.PRODUCT_ADD_REQUEST_PRODUCT_TYPE_ERROR:
            return {
                ...state,
                product_types: {
                    fetching: false,
                    data: [],
                    error: true,
                }
            }
        case types.PRODUCT_ADD_REQUEST_PRODUCT:
            return {
                ...state,
                products: {
                    fetching: true,
                    data: [],
                    error: null,
                }
            }
        case types.PRODUCT_ADD_REQUEST_PRODUCT_SUCCESS:
            return {
                ...state,
                products: {
                    fetching: false,
                    data: action.payload,
                    error: null,
                }
            }
        case types.PRODUCT_ADD_REQUEST_PRODUCT_ERROR:
            return {
                ...state,
                products: {
                    fetching: false,
                    data: [],
                    error: true,
                }
            }
        default:
            return state
    }
}
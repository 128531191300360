import Config from "../config/Config";
import * as types from "./index";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export const locationsAction = (customer_id, allotment_id) => {
  return {
    type: types.GET_LOCATIONS,
    payload: {
      customer_id,
      allotment_id,
    },
  };
};

export const locationsActionsSuccess = (customer_id, allotment_id, payload) => {
  return {
    type: types.GET_LOCATIONS_SUCCESS,
    payload,
    meta: {
      customer_id,
      allotment_id,
    },
  };
};

export const locationsActionsError = (customer_id, allotment_id, payload) => {
  return {
    type: types.GET_LOCATIONS_ERROR,
    payload,
    meta: {
      customer_id,
      allotment_id,
    },
  };
};

export const createLocationAction = (customer_id, allotment_id, location) => {
  const internalUUID = uuidv4();
  return {
    type: types.CREATE_LOCATION,
    payload: location,
    meta: {
      id: location.id,
      customer_id,
      allotment_id,
      merge: true,
      version: internalUUID,
      createdAt: new Date().toISOString(),
      offline: {
        effect: {
          url: `/api/v1/customers/${customer_id}/locations`,
          method: "POST",
          json: location,
          headers: {
            "Cache-Control": "no-cache",
          },
        },
        commit: {
          type: types.CREATE_LOCATION_SUCCESS,
          meta: {
            customer_id,
            allotment_id,
            version: internalUUID,
          },
        },
        rollback: {
          type: types.CREATE_LOCATION_ERROR,
          meta: {
            customer_id,
            allotment_id,
            version: internalUUID,
          },
        },
      },
    },
  };
};

export const getLocationAction = (id) => {
  return {
    type: types.GET_LOCATION,
    payload: { id },
  };
};

export const getLocationActionSuccess = (id, payload) => {
  return {
    type: types.GET_LOCATION_SUCCESS,
    payload,
    meta: {
      id,
    },
  };
};

export const getLocationActionError = (id, payload) => {
  return {
    type: types.GET_LOCATION_ERROR,
    payload,
    meta: {
      id,
    },
  };
};

export const updateLocationAction = (
  customer_id,
  allotment_id,
  id,
  location
) => {
  const internalUUID = uuidv4();
  return {
    type: types.UPDATE_LOCATION,
    payload: location,
    meta: {
      id: id,
      merge: true,
      version: internalUUID,
      createdAt: new Date().toISOString(),
      offline: {
        effect: {
          url: `/api/v1/locations/${id}`,
          method: "PATCH",
          json: location,
          headers: {
            "Cache-Control": "no-cache",
          },
        },
        commit: {
          type: types.UPDATE_LOCATION_SUCCESS,
          meta: {
            customer_id,
            allotment_id,
            id,
            version: internalUUID,
          },
        },
        rollback: {
          type: types.UPDATE_LOCATION_ERROR,
          meta: {
            customer_id,
            allotment_id,
            id,
            version: internalUUID,
          },
        },
      },
    },
  };
};

export const destroyLocationAction = (customer_id, allotment_id, id) => {
  return {
    type: types.DESTROY_LOCATION,
    payload: { id },
    meta: { customer_id, allotment_id },
  };
};

export const destroyLocationSuccessAction = (customer_id, allotment_id, id) => {
  return {
    type: types.DESTROY_LOCATION_SUCCESS,
    payload: { id },
    meta: { customer_id, allotment_id },
  };
};

export const destroyLocationErrorAction = (customer_id, allotment_id, id) => {
  return {
    type: types.DESTROY_LOCATION_ERROR,
    payload: { id },
    meta: { customer_id, allotment_id },
  };
};

export const cloneLocationAction = (id, customer_id, allotment_id) => {
  return {
    type: types.CLONE_LOCATION,
    payload: { id },
    meta: { customer_id, allotment_id },
  };
};

export const cloneLocationSuccessAction = (
  customer_id,
  allotment_id,
  payload
) => {
  return {
    type: types.CLONE_LOCATION_SUCCESS,
    payload: payload,
    meta: { customer_id, allotment_id },
  };
};

export const cloneLocationErrorAction = (customer_id, allotment_id, id) => {
  return {
    type: types.CLONE_LOCATION_ERROR,
    payload: { id },
    meta: { customer_id, allotment_id },
  };
};

import * as R from "ramda";
import React, { Component } from "react";
import { Nav, Navbar, NavDropdown, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logoutAction } from "../actions/LoginActions";
import { meAction } from "../actions/MeActions";
import Config from "../config/Config";

class Menu extends Component {
  Logout() {
    this.props.logout();
  }

  componentDidMount() {
    this.props.meRequest();
  }

  renderOffline() {
    if (this.props.offline.outbox.length <= 0) {
      return null;
    }

    if (this.props.offline.outbox.length == 1) {
      return (
        <div>
          {this.props.offline.busy && (
            <Spinner className="mr-2" size="sm" animation="grow" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}

          <Navbar.Text className="justify-content-end">
            <Link to={"/offlines"}>
              {this.props.offline.outbox.length} élement en attente
            </Link>
          </Navbar.Text>
        </div>
      );
    }

    return (
      <div>
        {this.props.offline.busy && (
          <Spinner className="mr-2" size="sm" animation="grow" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
        <Navbar.Text className="justify-content-end">
          <Link to={"/offlines"}>
            {this.props.offline.outbox.length} élements en attente
          </Link>
        </Navbar.Text>
      </div>
    );
  }

  render() {
    return (
      <Navbar expand="lg">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand>
          <NavDropdown
            className="d-lg-none"
            title="Profil"
            id="basic-nav-equipments"
          >
            <p className="dropdown-item disabled">
              {Config.value("appVersion")}
            </p>
            <Link className="dropdown-item" to="/profile">
              Mon profil
            </Link>
            <Link className="dropdown-item" to="/local_images">
              Images local
            </Link>
            <Link className="dropdown-item" to="/local_logs">
              Log local
            </Link>
            <a className="dropdown-item" onClick={this.Logout.bind(this)}>
              Déconnexion
            </a>
          </NavDropdown>
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link
              style={{ color: this.props.offline.online ? "green" : "red" }}
              className="nav-link"
              to="/dashboard"
            >
              GEMA
            </Link>

            <Link className="nav-link" to="/rapports">
              Rapports
            </Link>
            <Link className="nav-link" to="/comparaison_rapports">
              Rapports comparatifs
            </Link>
            <Link className="nav-link" to="/chiffrages-p2">
              Chiffrages P2
            </Link>
            <Link className="nav-link" to="/points-p3">
              Points P3
            </Link>
            <Link className="nav-link" to="/banque-images">
              Banque d'images
            </Link>
          </Nav>
        </Navbar.Collapse>
        {this.renderOffline()}
        <Navbar.Collapse className="justify-content-end">
          <NavDropdown
            className="d-none d-lg-block"
            title={
              R.path(["me", "last_name"], this.props) +
              " " +
              R.path(["me", "first_name"], this.props)
            }
            id="basic-nav-equipments"
          >
            <p className="dropdown-item disabled">
              {Config.value("appVersion")}
            </p>
            <Link className="dropdown-item" to="/profile">
              Mon profil
            </Link>
            <Link className="dropdown-item" to="/local_images">
              Images local
            </Link>
            <Link className="dropdown-item" to="/local_logs">
              Log local
            </Link>
            <a className="dropdown-item" onClick={this.Logout.bind(this)}>
              Déconnexion
            </a>
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = (response) => {
  return {
    me: response.me.payload,
    offline: response.offline,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logoutAction()),
    meRequest: () => dispatch(meAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);

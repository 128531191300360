import React from 'react'
import ScreenTitle from '../../components/ScreenTitle'
import FormContainer from './FormContainer'

const CompareRapportCreateContainer = () => {
    return (
        <div>
            <ScreenTitle back={true} button='Retour' href='/comparaison_rapports' title="Création d'un rapport de comparaison" />
            <FormContainer />
        </div>
    )
}

export default CompareRapportCreateContainer
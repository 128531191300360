import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react'
import { confirmable, createConfirmation } from 'react-confirm'

const ConfirmAction = ({ show, proceed, confirmation, options, ...props }) => {
    return (
        <Dialog
            open={show}
            onClose={() => { proceed(false) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Confirmation de l'action !</DialogTitle>
            <DialogContent>
                {confirmation}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => proceed(false)} color="primary" >
                    Non
                </Button>
                <Button autoFocus onClick={() => { proceed(true) }} color="primary">
                    Oui
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const ConfirmableWrapper = confirmable(ConfirmAction)

export const confirm = (confirmation, options = {}) => {
    return createConfirmation(ConfirmableWrapper)({ confirmation, options })
}


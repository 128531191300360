import { useField } from 'formik'
import * as R from 'ramda'
import React, { useCallback, useMemo } from 'react'
import LocationsCheckbox from './LocationsCheckbox'

const VisitsIdsSelector = R.compose(
    R.map(R.prop('id')),
    R.flatten,
    R.map(R.prop('visits'))
)

const AllotmentsCheckbox = ({ allotment, locations }) => {
    const [field, meta, helpers] = useField('visit_ids')
    const visitsIds = useMemo(() => {
        return VisitsIdsSelector(locations)
    }, [locations])
    const onChange = useCallback((e) => {
        const value = e.target.checked

        if (value) {
            helpers.setValue(
                R.compose(
                    R.uniq,
                    R.concat(visitsIds)
                )(field.value)
            )
        } else {
            helpers.setValue(R.without(visitsIds, field.value))
        }
    }, [field, visitsIds])
    const checked = R.any((i) => visitsIds.includes(i), field.value)

    return (
        <div className='rapportsAllotmentsContainer'>
            <div className='rapportsCheckboxContainer'>
                <input checked={checked} onChange={onChange} id={`allotment-${allotment.id}`} type='checkbox' />
                <label htmlFor={`allotment-${allotment.id}`}>{allotment.name}</label>
            </div>
            <LocationsCheckbox locations={locations} />
        </div>
    )
}

export default AllotmentsCheckbox
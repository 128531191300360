import Config from "../config/Config";
import * as types from "./index";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export const visitsAction = (customer_id, location_id, allotment_id) => {
  return {
    type: types.GET_VISITS,
    payload: {
      customer_id,
      location_id,
      allotment_id,
    },
  };
};

export const visitsActionSuccess = (
  customer_id,
  location_id,
  allotment_id,
  payload
) => {
  return {
    type: types.GET_VISITS_SUCCESS,
    payload,
    meta: {
      customer_id,
      location_id,
      allotment_id,
    },
  };
};

export const visitsActionError = (
  customer_id,
  location_id,
  allotment_id,
  payload
) => {
  return {
    type: types.GET_VISITS_ERROR,
    payload,
    meta: {
      customer_id,
      location_id,
      allotment_id,
    },
  };
};

export const visitAction = (id) => {
  return {
    type: types.GET_VISIT,
    payload: { id },
  };
};

export const visitActionSuccess = (id, payload) => {
  return {
    type: types.GET_VISIT_SUCCESS,
    payload,
    meta: { id },
  };
};

export const visitActionError = (id, payload) => {
  return {
    type: types.GET_VISIT_ERROR,
    payload,
    meta: { id },
  };
};

export const updateVisitAction = (id, payload) => {
  const internalUUID = uuidv4();
  return {
    type: types.UPDATE_VISIT,
    payload: payload,
    meta: {
      id: id,
      version: internalUUID,
      createdAt: new Date().toISOString(),
      offline: {
        effect: {
          url: `/api/v1/visits/${id}`,
          method: "PATCH",
          json: payload,
          headers: {
            "Cache-Control": "no-cache",
          },
        },
        commit: {
          type: types.UPDATE_VISIT_SUCCESS,
          meta: { id, version: internalUUID },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: types.UPDATE_VISIT_ERROR,
          meta: { id, version: internalUUID },
        },
      },
    },
  };
};

export const createVisitAction = (
  customer_id,
  allotment_id,
  location_id,
  payload
) => {
  const internalUUID = uuidv4();
  return {
    type: types.CREATE_VISIT,
    payload: payload,
    meta: {
      id: payload.id,
      customer_id,
      allotment_id,
      location_id,
      version: internalUUID,
      createdAt: new Date().toISOString(),
      offline: {
        effect: {
          url: `/api/v1/visits`,
          method: "POST",
          json: payload,
          headers: {
            "Cache-Control": "no-cache",
          },
        },
        commit: {
          type: types.CREATE_VISIT_SUCCESS,
          meta: {
            id: payload.id,
            customer_id,
            allotment_id,
            location_id,
            version: internalUUID,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: types.CREATE_VISIT_ERROR,
          meta: {
            id: payload.id,
            customer_id,
            allotment_id,
            location_id,
            version: internalUUID,
          },
        },
      },
    },
  };
};

export const cloneVisitAction = (
  id,
  location_id,
  customer_id,
  allotment_id
) => {
  return {
    type: types.CLONE_VISIT,
    payload: { id },
    meta: { location_id, customer_id, allotment_id },
  };
};

export const cloneVisitActionSuccess = (
  id,
  customer_id,
  location_id,
  allotment_id,
  payload
) => {
  return {
    type: types.CLONE_VISIT_SUCCESS,
    payload,
    meta: { id, location_id, customer_id, allotment_id },
  };
};

export const cloneVisitActionError = (
  id,
  customer_id,
  location_id,
  allotment_id,
  payload
) => {
  return {
    type: types.CLONE_VISIT_ERROR,
    payload,
    meta: { id, location_id, customer_id, allotment_id },
  };
};

export const destroyVisitAction = (
  id,
  customer_id,
  location_id,
  allotment_id
) => {
  return {
    type: types.DESTROY_VISIT,
    payload: { id, location_id, customer_id, allotment_id },
  };
};

export const destroyVisitSuccessAction = (
  id,
  customer_id,
  location_id,
  allotment_id
) => {
  return {
    type: types.DESTROY_VISIT_SUCCESS,
    payload: { id, location_id, customer_id, allotment_id },
  };
};

export const destroyVisitErrorAction = (
  id,
  customer_id,
  location_id,
  allotment_id
) => {
  return {
    type: types.DESTROY_VISIT_ERROR,
    payload: { id, location_id, customer_id, allotment_id },
  };
};

import React, { useState, useCallback } from "react";
import { Box } from "@mui/material";
import CategoriesSelector from "./CategoriesSelector";
import ProductTypesSelector from "./ProductTypesSelector";
import * as R from "ramda";
import ProductsSelector from "./ProductsSelector";
import ProductVisitsAddDialog from "./ProductVisitsAddDialog";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const ProductsViews = ({
  root_category_id,
  append,
  visit_id,
  building_id,
  control,
}) => {
  const [categories, setCategories] = useState([root_category_id]);
  const [trademark, setTrademark] = useState(null);
  const [productType, setProductType] = useState(null);
  const [product, setProduct] = useState(null);

  const productEntities = useSelector((state) => state.products[product]);
  const lastCategoryEntity = useSelector(
    (state) => state.category[R.last(categories)]
  );

  const onNavigate = useCallback(({ type, object_id, index }) => {
    if (type === "CATEGORY") {
      setTrademark(null);
      setProductType(null);
      setCategories((paths) => {
        return [...R.take(index + 1, paths), object_id];
      });
    } else if (type === "TRADEMARK") {
      setTrademark(object_id);
      setProductType(null);
    } else if (type === "PRODUCT_TYPE") {
      setProductType(object_id);
    } else if (type === "PRODUCT") {
      setProduct(object_id);
    }
  }, []);

  const onClose = useCallback(() => {
    setProduct(null);
  }, []);

  const onAddProduct = useCallback(
    (formValues) => {
      const values = {
        ...formValues,
        id: uuidv4(),
        product: productEntities,
        product_id: product,
        category_ids: categories,
        visit_id: visit_id,
        category_name: lastCategoryEntity.name,

        category_root_id: R.head(categories),
        building_id: building_id,
      };
      setProduct(null);
      append(values);
    },
    [
      append,
      productEntities,
      visit_id,
      building_id,
      categories,
      lastCategoryEntity,
      product,
    ]
  );

  return (
    <Box
      className="list_categories"
      display="flex"
      style={{ overflowX: "scroll" }}
    >
      {categories.map((category_id, index) => {
        return (
          <CategoriesSelector
            control={control}
            onPress={onNavigate}
            key={category_id}
            index={index}
            category_id={category_id}
          />
        );
      })}
      {trademark && (
        <ProductTypesSelector
          control={control}
          onPress={onNavigate}
          trademark_id={trademark}
          category_id={R.last(categories)}
        />
      )}
      {productType && (
        <ProductsSelector
          control={control}
          onPress={onNavigate}
          trademark_id={trademark}
          category_id={R.last(categories)}
          product_type_id={productType}
        />
      )}
      {product && (
        <ProductVisitsAddDialog
          trademark_id={trademark}
          category_ids={categories}
          product_type_id={productType}
          product_id={product}
          onClose={onClose}
          onSubmit={onAddProduct}
        />
      )}
    </Box>
  );
};

export default ProductsViews;

// UI Elements
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import React from 'react'
import FlashMessage from "../components/FlashMessage"
import Header from './Header'
import styles from './LayoutContainer.module.css'
import { Outlet } from 'react-router-dom'
import { CssBaseline } from '@mui/material'


export const LayoutContainer = (props) => {
  return (
    <>
      <CssBaseline />
      <Header />
      <main>
        <Container maxWidth="xl" className={styles.container}>
          <FlashMessage />
          <Outlet />
        </Container>
      </main>
    </>
  )
}

export default LayoutContainer
import { Button, Grid } from "@mui/material";
import { Form } from "formik";
import moment from "moment";
import React, { useMemo } from "react";
import SelectField from "../../components/forms/Formik/SelectField";
import TextArea from "../../components/forms/Formik/TextAreaField";
import TextField from "../../components/forms/Formik/TextField";

const Year = moment().year();
const Years = Array.from(
  new Array(100),
  (val, index) => Year - 99 + index
).reverse();

const LocationForm = ({ isValid = true, allotments = [] }) => {
  return (
    <Form autoComplete="off">
      <Grid container justify="center" spacing={0}>
        <Grid item xs={12}>
          <TextField name="label" label="Libellé" />
        </Grid>
        <Grid item xs={12}>
          <TextField name="street" label="Adresse" />
        </Grid>
        <Grid item xs={12}>
          <TextField name="zip" label="Code postal" />
        </Grid>
        <Grid item xs={12}>
          <TextField name="city" label="Ville" />
        </Grid>
        <Grid item xs={12}>
          <SelectField name="year" label="Année de mise en service">
            <option></option>
            {Years.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12}>
          <SelectField name="allotment_id" label="Lot">
            <option></option>
            {allotments.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12}>
          <TextArea name="comment" label="Commentaire" />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color={isValid ? "primary" : "secondary"}
            type="submit"
          >
            Sauvegarder
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default LocationForm;

import Cookies from 'js-cookie';

export function setCookie(cname, cvalue, hours) {
  const time = new Date(new Date().getTime() + hours * 60 * 60 * 1000)
  return Cookies.set(cname, cvalue)
}

export function getCookie(cname) {
  return Cookies.get(cname)
}

export function removeCookies(name) {
  Cookies.remove(name)
}
import { call, put, race, take } from 'redux-saga/effects'
import { REFRESH_TOKEN_ERROR, REFRESH_TOKEN_REQUEST, REFRESH_TOKEN_SUCCESS } from '../actions'

export function* makeApi(apiRequest, ...args) {
    try {
        return yield call(apiRequest, ...args)
    } catch (e) {
        if (e.message === '401') {
            yield put({ type: REFRESH_TOKEN_REQUEST })
            const { success } = yield race({
                success: take(REFRESH_TOKEN_SUCCESS),
                error: take(REFRESH_TOKEN_ERROR)
            })

            if (success) {
                return yield call(makeApi, apiRequest, ...args)
            }
        }
        throw new Error(e.message)
    }
}
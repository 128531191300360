import { useField } from 'formik'
import { isEmpty } from 'ramda'
import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { getFile } from '../../../services/StoreFile'
import { renameFile } from '../../../utils/JsonTools'

const FileField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props)
    const [thumb, setThumb] = useState(null)
    useEffect(() => {
        if (!field.value || isEmpty(field.value)) return

        if (field.value && field.value instanceof Blob) {
            setThumb(window.URL.createObjectURL(field.value))
            return
        }

        (async () => {
            const item = await getFile(field.value)
            if (item) {
                setThumb(item.url)
            }
        })()


    }, [field.value])

    return (
        <div className="form-group" style={{ marginBottom: "5px" }}>
            <label style={{ fontSize: "11px" }} className={(meta.touched && meta.error) && 'text-danger'}>
                {label}
                {meta.touched && ((meta.error && <span className="text-danger">&nbsp; -- &nbsp;{meta.error}</span>))}
            </label>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroupFileAddon01">Upload</span>
                </div>
                <div className="custom-file">
                    <input
                        type="file"
                        className="custom-file-input"
                        id="inputGroupFile01"
                        aria-describedby="inputGroupFileAddon01"
                        onBlur={field.onBlur}
                        onChange={(evt) => {
                            helpers.setValue(renameFile(evt.currentTarget.files[0]))
                        }}
                    />
                    <label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>
                </div>
            </div>
            {
                thumb && <Image style={{ maxHeight: '200px', maxWidth: '200px' }} src={thumb} thumbnail fluid />
            }

        </div>
    )
}

export default FileField
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import ScreenTitle from "../components/ScreenTitle";
import { VisitsActions } from "../reducers/VisitsReducer";
import Redirect from "../components/Redirect";

const RouterVisitContainer = ({}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.visits.fetching);
  const waitForSync = useSelector((state) =>
    state.visits.waitForSync.includes(id)
  );
  const visitsExistsInState = useSelector(
    (state) => !!state.visits.entities[id]
  );
  const error = useSelector((state) => state.visits.error);

  useEffect(() => {
    // Dont load if not synced with backend
    if (!waitForSync) {
      dispatch(VisitsActions.requestVisitById({ id }));
    }
  }, [id]);

  if (error === "404") return <Redirect to="/dashboard" />;

  if ((loading && !visitsExistsInState) || !visitsExistsInState) {
    return (
      <div>
        <ScreenTitle
          href={"/dashboard"}
          title="Chargement...."
          loading={loading}
          back={true}
          button={"Retour"}
        />
      </div>
    );
  }

  return <Outlet />;
};

export default RouterVisitContainer;

import localForage from 'localforage'
import { v4 as uuidv4 } from 'uuid'


export const saveJSON = async (type, json, name = null) => {
    const uuid = uuidv4()
    const date = new Date()
    const generateName = `${type}://${uuid}:${date}`
    const item = await localForage.setItem(generateName, { data: json, created_at: new Date()})

    return {
        id: generateName,
        item
    }
}

export const getJSON = async (name) => {

    const item = await localForage.getItem(name)
    if (item) {
        return {
            id: name,
            item
        }
    }
    return null
}

export const removeJSON = async (name) => {
    await localForage.removeItem(name)
}

import { Badge } from "@mui/material";
import { Formik } from "formik";
import * as R from "ramda";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Redirect from "../../components/Redirect";
import { flashMessage } from "redux-flash";
import {
  customerAction,
  updateCustomerAction,
} from "../../actions/CustomersActions";
import { confirm } from "../../components/Confirms/ConfirmAction";
import CustomerForm from "../../components/forms/CustomerForm";
import ScreenTitle from "../../components/ScreenTitle";
import { validationSchema } from "../../Models/Customer";
import { getCookie } from "../../utils/Cookies";
import { saveJSONFileInStorage, saveJSONInStorage } from "../../utils/JsonTools";

const UpdateCustomerContainer = ({ match, history, ...props }) => {
  const { id } = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const { customer, loading, error, isWaitForSync } = useSelector((state) => {
    return {
      customer: state.customers.entities[id],
      loading: state.customers.loading,
      error: state.customers.error,
      isWaitForSync: R.compose(
        R.includes(id),
        R.defaultTo([]),
        R.path(["customers", "waitForSync"])
      )(state),
    };
  });

  useEffect(() => {
    if (!isWaitForSync) {
      dispatch(customerAction(id));
    }
  }, []);

  const onSubmit = useCallback(async (values, formikCtx) => {
    formikCtx.setSubmitting(true);
    const newValues = await saveJSONFileInStorage(values);
    await saveJSONInStorage('customer',values);
    dispatch(
      updateCustomerAction(
        id,
        {
          ...newValues,
          updated_at: new Date().toISOString(),
        },
        getCookie("access_token")
      )
    );
    formikCtx.setSubmitting(false);
    navigation("/dashboard");
    dispatch(flashMessage("Le client a été mis à jour."));
  }, []);

  const onBack = useCallback(
    async (e) => {
      e.preventDefault();

      if (formikRef.current.dirty) {
        if (
          !(await confirm(
            "Des éléments dans le formulaire n'ont pas été sauvegardés, êtes-vous sur de vouloir retourner sur la page d'avant ?"
          ))
        ) {
          return;
        }
      }
      navigation("/dashboard");
    },
    [formikRef]
  );

  if (loading && R.isNil(customer)) {
    return (
      <div>
        <ScreenTitle
          back={true}
          button="Retour"
          onClickBack={() => {
            navigation("/dashboard");
          }}
          title={"Chargement du client..."}
        ></ScreenTitle>
      </div>
    );
  }

  if (!loading && R.isNil(customer) && error === "404") {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        onSubmit={onSubmit}
        initialValues={customer}
        validationSchema={validationSchema}
      >
        {({ dirty, isValid }) => {
          return (
            <>
              <ScreenTitle
                dirty={dirty}
                back={true}
                button="Retour"
                onClickBack={onBack}
                title={"Edition d'un client"}
              >
                <div>
                  <Badge
                    color="secondary"
                    badgeContent={customer.locations_counter}
                  >
                    <Link
                      className="btn btn-success btn-sm"
                      p={2}
                      m={1}
                      size="small"
                      variant="contained"
                      to={`/customers/${id}/locations`}
                    >
                      Sites
                    </Link>
                  </Badge>
                  <Badge
                    color="secondary"
                    badgeContent={customer.allotments_counter}
                  >
                    <Link
                      className="btn btn-success btn-sm ml-10"
                      p={2}
                      m={1}
                      size="small"
                      variant="contained"
                      to={`/customers/${id}/lots`}
                    >
                      Lots
                    </Link>
                  </Badge>
                </div>
              </ScreenTitle>
              <CustomerForm isValid={isValid} btnTitle="Sauvegarder" />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateCustomerContainer;

import { Formik } from 'formik'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { flashMessage } from 'redux-flash'
import { v4 as uuidv4 } from 'uuid'
import { createAllotmentAction } from '../../actions/AllotmentsActions'
import { customerAction } from '../../actions/CustomersActions'
import { confirm } from '../../components/Confirms/ConfirmAction'
import AllotmentForm from '../../components/forms/AllotmentForm'
import ScreenTitle from '../../components/ScreenTitle'
import { initialValues, validationSchema } from '../../Models/Allotment'
import { saveJSONInStorage } from '../../utils/JsonTools'

const CreateAllotmentContainer = ({ match, history }) => {
    const navigation = useNavigate()
    const { customer_id } = useParams()
    const dispatch = useDispatch()
    const formikRef = useRef(null)

    const selector = useCallback((state) => {
        return {
            customer: state.customers.entities[customer_id]
        }
    }, [customer_id])
    const { customer } = useSelector(selector)

    useEffect(() => {
        dispatch(customerAction(customer_id))
    }, [])

    const title = useMemo(() => {
        if (!customer) return ''
        return `${customer.name} - Nouveau lots`
    }, [])

    const onBack = useCallback(async (e) => {
        e.preventDefault()
        if (formikRef.current && formikRef.current.dirty) {
            if (!await confirm("Des éléments dans le formulaire n'ont pas été sauvegardés, êtes-vous sur de vouloir retourner sur la page d'avant ?")) {
                return
            }
        }
        navigation(`/customers/${customer_id}/lots`)
    }, [formikRef])

    const onSubmit = useCallback((values, formikCtx) => {
        formikCtx.setSubmitting(true)
        const newValues = {
            ...values,
            customer_id: customer_id,
            id: uuidv4()
        }
        saveJSONInStorage('allotment',newValues)
        dispatch(createAllotmentAction(customer_id, newValues))
        formikCtx.setSubmitting(false)
        navigation(`/customers/${customer_id}/lots/${newValues.id}/edit`)
        dispatch(flashMessage('Le lot a été créé.'))
    }, [customer_id])

    return (
        <div>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {
                    ({ dirty, isValid }) => {
                        return (
                            <>
                                <ScreenTitle dirty={dirty} onClickBack={onBack} back={true} button='Retour' title={title} />
                                <AllotmentForm isValid={isValid} />
                            </>
                        )
                    }
                }
            </Formik>
        </div>
    )
}

export default CreateAllotmentContainer
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import React from 'react'
import { useSelector } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField';


const ProfileForm = ({ onSubmit }) => {
  const currentProfile = useSelector((state) => state.me.payload)

  const { control, handleSubmit, formState, reset } = useForm({
    defaultValues: {
      ...currentProfile,
      password: ""
    }
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Grid container justify="center" spacing={3}>
        <Grid item xs={12} sm={5}>
          <Controller
            name='email'
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => <TextField margin="normal" type='email' fullWidth placeholder="Email" label='Email' error={fieldState?.error?.type} {...field} />}
          />

          <Controller
            name='password'
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => <TextField margin="normal" type='password' fullWidth placeholder="Mot de passe" label='Mot de passe' error={fieldState?.error?.type} {...field} />}
          />

          <Controller
            name='last_name'
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => <TextField margin="normal" type='text' fullWidth placeholder="Nom de famille" label='Nom de famille' error={fieldState?.error?.type} {...field} />}
          />

          <Controller
            name='first_name'
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => <TextField margin="normal" type='text' fullWidth placeholder="Prénom" label='Prénom' error={fieldState?.error?.type} {...field} />}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Controller
            name='phone'
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => <TextField margin="normal" type='text' fullWidth placeholder="Téléphone" label='Téléphone' error={fieldState?.error?.type} {...field} />}
          />
          <Controller
            name='mobile_phone'
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => <TextField margin="normal" type='text' fullWidth placeholder="Mobile" label='Mobile' error={fieldState?.error?.type} {...field} />}
          />
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <Button type="button" variant="contained" disabled={formState.isSubmitting} onClick={() => reset()}>
            Clear Values
          </Button>
          &nbsp;
          <Button variant="contained" color="primary" type="submit" disabled={formState.isSubmitting}>Enregistrer</Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default ProfileForm

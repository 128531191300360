import * as R from "ramda";
import { createReducer } from "reduxsauce";
import { createSlice } from "@reduxjs/toolkit";
import {
  CLONE_LOCATION,
  CLONE_LOCATION_ERROR,
  CLONE_LOCATION_SUCCESS,
  CREATE_LOCATION,
  CREATE_LOCATION_SUCCESS,
  DESTROY_LOCATION,
  DESTROY_LOCATION_ERROR,
  DESTROY_LOCATION_SUCCESS,
  GET_LOCATION,
  GET_LOCATIONS,
  GET_LOCATIONS_ERROR,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATION_ERROR,
  GET_LOCATION_SUCCESS,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
} from "../actions";
import moment from "moment";

const initialValues = {
  entities: {},
  fetching: false,
  error: null,
  ids: {},
};

const LocationsSlice = createSlice({
  initialState: initialValues,
  name: "Locations",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GET_LOCATIONS, (state, action) => {
        const {
          payload: { customer_id, allotment_id },
        } = action;

        const key = buildLocationsKey(customer_id, allotment_id);
        state.fetching = true;
        state.error = null;
        state.ids[key] ||= {
          waitForSync: [],
          location_ids: [],
        };
      })
      .addCase(GET_LOCATIONS_SUCCESS, (state, action) => {
        const {
          payload,
          meta: { customer_id, allotment_id },
        } = action;
        const key = buildLocationsKey(customer_id, allotment_id);

        state.fetching = false;
        payload.forEach((item) => {
          if (state.entities[item.id]) {
            const itemState = state.entities[item.id];
            if (moment(itemState.updated_at).isBefore(item.updated_at)) {
              state.entities[item.id] = item;
            }
          } else {
            state.entities[item.id] = item;
          }
        });
        state.ids[key].location_ids = payload.map((x) => x.id);
      })
      .addCase(GET_LOCATIONS_ERROR, (state) => {
        state.fetching = false;
      })
      .addCase(CREATE_LOCATION, (state, action) => {
        const {
          payload,
          meta: { customer_id, allotment_id },
        } = action;
        const key = buildLocationsKey(customer_id, allotment_id);

        state.ids[key].waitForSync.push(payload.id);
        state.entities[payload.id] = payload;
      })
      .addCase(CREATE_LOCATION_SUCCESS, (state, action) => {
        const {
          payload,
          meta: { customer_id, allotment_id },
        } = action;
        const key = buildLocationsKey(customer_id, allotment_id);

        state.entities[payload.id] = payload;
        state.ids[key].location_ids.push(payload.id);
        const index = state.ids[key].waitForSync.findIndex(
          (x) => x === payload.id
        );
        if (index >= 0) {
          state.ids[key].waitForSync.splice(index, 1);
        }
      })
      .addCase(GET_LOCATION, (state) => {
        state.fetching = true;
        state.error = null;
      })
      .addCase(GET_LOCATION_SUCCESS, (state, action) => {
        const { payload } = action;

        state.entities[payload.id] = payload;
      })
      .addCase(GET_LOCATION_ERROR, (state, action) => {
        state.fetching = false;
        state.error = action.payload;
      })
      .addCase(UPDATE_LOCATION, (state, action) => {
        const { payload } = action;

        state.entities[payload.id] = payload;
      })
      .addCase(UPDATE_LOCATION_SUCCESS, (state, action) => {
        const { payload } = action;
        state.entities[payload.id] = payload;
      })
      .addCase(CLONE_LOCATION, (state) => {
        state.fetching = true;
      })
      .addCase(CLONE_LOCATION_ERROR, (state) => {
        state.fetching = false;
      })
      .addCase(CLONE_LOCATION_SUCCESS, (state, action) => {
        const {
          payload,
          meta: { customer_id, allotment_id },
        } = action;
        const key = buildLocationsKey(customer_id, allotment_id);

        state.entities[payload.id] = payload;
        state.ids[key].location_ids.push(payload.id);
        state.fetching = false;
      })
      .addCase(DESTROY_LOCATION, (state, action) => {
        state.fetching = true;
      })
      .addCase(DESTROY_LOCATION_ERROR, (state) => {
        state.fetching = false;
      })
      .addCase(DESTROY_LOCATION_SUCCESS, (state, action) => {
        const {
          payload: { id },
          meta: { customer_id, allotment_id },
        } = action;
        state.fetching = false;
        const key = buildLocationsKey(customer_id, allotment_id);
        const index = state.ids[key].location_ids.findIndex((x) => x === id);
        if (index >= 0) {
          state.ids[key].location_ids.splice(index, 1);
        }
        if (state.entities[id]) {
          delete state.entities[id];
        }
      });
  },
});

export const buildLocationsKey = (customer_id, allotment_id) => {
  if (allotment_id) {
    return `${customer_id}/${allotment_id}`;
  }

  return customer_id;
};

export default LocationsSlice.reducer;

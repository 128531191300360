import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
// UI Elements
import Typography from '@mui/material/Typography'
import moment from 'moment'
import * as R from 'ramda'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Visit.module.css'
moment().locale('fr')

const Visit = ({ data, onClone, onDestroy, readonly }) => {
  const date = moment(data.seen_at).format('DD MMMM YYYY')
  return (
    <Card className={styles.card} >
      <CardContent>
        <Typography variant="h6">
          {
            R.path(['location_attributes', 'customer', 'name'], data)
          }
        </Typography>
        <Typography>
          {data.location_attributes.label}
        </Typography>
        <div className={styles.details}>
          <Typography style={{ flex: 1 }}>
            {(data.contract_type ? data.contract_type.code : 'NP')} / {(data.energy_type ? data.energy_type.code : 'NP')}
          </Typography>
          <Typography style={{ flex: 1 }}>
            {date}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        {
          !readonly &&
          <>
            <button className="btn btn-sm btn-danger" onClick={() => { onDestroy(data) }}>
              Supprimer
            </button  >
            <Link className="btn  btn-sm btn-warning ml-2" to={`/visites/${data.id}/edition`}>
              Modifier
            </Link>
            <a className="btn btn-sm btn-info  ml-2" onClick={() => { onClone(data) }}>
              Dupliquer
            </a>
          </>
        }
        {
          readonly &&
          <>
            <Link className="btn  btn-sm btn-success ml-2" to={`/visites/${data.id}/edition`}>
              Afficher
            </Link>
          </>
        }
      </CardActions>
    </Card>
  )
}

export default Visit
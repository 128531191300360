import React from 'react'
// UI Elements
import { Alert } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getLatestMessage } from 'redux-flash'

const FlashMessage = () => {
  const { flash } = useSelector((state) => {
    return {
      flash: getLatestMessage(state)
    }
  })

  if (!flash) return null

  return (
    <Alert variant={flash.isError ? 'danger' : 'success'}>
      {flash.message}
    </Alert>
  )
}

export default FlashMessage
import { createAction, createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";
import { createSelector } from "reselect";

const getbBuildingEntities = (state) => state.buildings.entities || {};
const getBuildingIDS = (visit_id) => (state) =>
  state.buildings.ids[visit_id] || [];
const getBuildingVersions = (visit_id) => (state) =>
  state.buildings.versions[visit_id] || [];
export const getBuildings = (visitId) => {
  return createSelector(
    getbBuildingEntities,
    getBuildingIDS(visitId),
    getBuildingVersions(visitId),
    (entities, ids, versionIds) => {
      return R.compose(
        R.reject(R.isNil),
        R.map((x) => entities[x])
      )([...ids, ...versionIds]);
    }
  );
};

export const getBuildingsForCategoryId = (visitId, categoryId) => {
  return createSelector(getBuildings(visitId), (buildings) => {
    return R.filter((x) => x.category_id === categoryId, buildings);
  });
};

const createBuildingByVisitAndCategorySuccess =
  "Buildings.createBuildingByVisitAndCategorySuccess";
const createBuildingByVisitAndCategoryError =
  "Buildings.createBuildingByVisitAndCategoryError";
const createBuildingByVisitAndCategory = createAction(
  "Buildings.createBuildingByVisitAndCategory",
  (payload, { visit_id, category_id }) => {
    return {
      payload,
      meta: {
        visit_id,
        category_id,
        createdAt: new Date().toISOString(),
        offline: {
          effect: {
            url: "/api/v2/buildings",
            method: "POST",
            json: payload,
          },
          commit: {
            type: createBuildingByVisitAndCategorySuccess,
            meta: {
              visit_id,
              category_id,
            },
          },
          rollback: {
            type: createBuildingByVisitAndCategoryError,
            meta: {
              visit_id,
              category_id,
            },
          },
        },
      },
    };
  }
);

const updateBuildingByVisitAndCategorySuccess =
  "Buildings.updateBuildingByVisitAndCategorySuccess";
const updateBuildingByVisitAndCategoryError =
  "Buildings.updateBuildingByVisitAndCategoryError";
const updateBuildingByVisitAndCategory = createAction(
  "Buildings.updateBuildingByVisitAndCategory",
  (payload, { visit_id, building_id }) => {
    return {
      payload,
      meta: {
        visit_id,
        building_id,
        createdAt: new Date().toISOString(),
        offline: {
          effect: {
            url: "/api/v2/buildings",
            method: "PATCH",
            json: payload,
          },
          commit: {
            type: updateBuildingByVisitAndCategorySuccess,
            meta: {
              visit_id,
              building_id,
            },
          },
          rollback: {
            type: updateBuildingByVisitAndCategoryError,
            meta: {
              visit_id,
              building_id,
            },
          },
        },
      },
    };
  }
);

const BuildingsSice = createSlice({
  name: "Buildings",
  initialState: {
    entities: {},
    ids: {},
    fetching: false,
    error: false,
    waitForSync: [],
    versions: {},
  },
  reducers: {
    requestBuildingsFromVisit(state, action) {
      const {
        payload: { visit_id },
      } = action;
      state.fetching = true;
      state.error = false;
      state.ids[visit_id] ||= [];
      state.versions[visit_id] ||= [];
    },
    requestBuildingsFromVisitSuccess: {
      prepare(payload, visit_id) {
        return { payload, meta: { visit_id } };
      },
      reducer(state, actions) {
        const {
          payload,
          meta: { visit_id },
        } = actions;

        state.fetching = false;

        payload.forEach((building) => {
          state.entities[building.id] = building;
        });

        const ids = R.map((x) => x.id, payload);
        state.ids[visit_id] = ids;
      },
    },
    requestBuildingsFromVisitError: {
      prepare(payload, visit_id) {
        return { payload, meta: { visit_id } };
      },
      reducer(state, actions) {
        const {
          payload,
          meta: { visit_id },
        } = actions;
        state.fetching = false;
        state.error = payload;
      },
    },
    destroyBuilding: {
      prepare(building_id, visit_id) {
        return {
          payload: { building_id, visit_id },
        };
      },
      reducer(state) {
        state.fetching = true;
      },
    },
    destroyBuildingSuccess: {
      prepare(building_id, visit_id) {
        return {
          payload: { building_id, visit_id },
        };
      },
      reducer(state, actions) {
        state.fetching = false;
        const {
          payload: { building_id, visit_id },
        } = actions;
        if (state.entities[building_id]) {
          delete state.entities[building_id];
        }
        const index = state.ids[visit_id].findIndex((x) => x === building_id);
        if (index >= 0) {
          state.ids[visit_id].splice(index, 1);
        }
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBuildingByVisitAndCategory.type, (state, actions) => {
        const {
          payload,
          meta: { visit_id, category_id },
        } = actions;
        state.ids[visit_id] ||= [];
        state.versions[visit_id] ||= [];

        state.entities[payload.id] = payload;
        state.versions[visit_id].push(payload.id);
        state.waitForSync.push(payload.id);
      })
      .addCase(createBuildingByVisitAndCategorySuccess, (state, actions) => {
        const {
          payload,
          meta: { visit_id, category_id },
        } = actions;

        state.entities[payload.id] = payload;
        state.ids[visit_id].push(payload.id);
        const index = state.waitForSync.findIndex((x) => x == payload.id);
        if (index >= 0) {
          state.waitForSync.splice(index, 1);
        }

        const indexVersion = state.versions[visit_id].findIndex(
          (x) => x === payload.id
        );
        if (indexVersion >= 0) {
          state.versions[visit_id].splice(indexVersion, 1);
        }
      })
      .addCase(updateBuildingByVisitAndCategory.type, (state, actions) => {
        const {
          payload,
          meta: { visit_id, building_id },
        } = actions;

        state.entities[payload.id] = payload;
      })
      .addCase(updateBuildingByVisitAndCategorySuccess, (state, actions) => {
        const {
          payload,
          meta: { visit_id, building_id },
        } = actions;
        state.entities[payload.id] = payload;
      });
  },
});

export const BuildingsReducer = BuildingsSice.reducer;

export const BuildingsActions = {
  ...BuildingsSice.actions,
  createBuildingByVisitAndCategory,
  updateBuildingByVisitAndCategory,
};

import React from 'react'
import './Badge.css'

const Badge = ({ children, badgeContent, color = '#007bff' }) => {
    return (
        <span className="badgeContainer">
            {children}
            {
                badgeContent !== 0 &&
                <span className='myBadge' style={{ backgroundColor: color ? color : '#007bff!' }}>
                    {
                        badgeContent
                    }
                </span>
            }
        </span>
    )
}

export default Badge
import { createReducer } from 'reduxsauce'
import { LOGIN_USER, LOGIN_USER_ERROR, LOGIN_USER_SUCCESS, LOGOUT_REQUEST } from '../actions'

const initialValues = {
  isAuthentified: false,
  loading: false,
  error: null
}

const LoginRequest = (state, action) => {
  return {
    ...state,
    loading: true,
    error: null
  }
}

const LoginSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    isAuthentified: true,
    error: null,
  }
}

const LoginError = (state, action) => {
  const { payload } = action
  return {
    ...state,
    loading: false,
    error: payload
  }
}

const LogoutRequest = (state, action) => {
  return initialValues
}


export default createReducer(initialValues, {
  [LOGIN_USER]: LoginRequest,
  [LOGIN_USER_SUCCESS]: LoginSuccess,
  [LOGIN_USER_ERROR]: LoginError,
  [LOGOUT_REQUEST]: LogoutRequest
})
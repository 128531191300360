import { Badge } from '@mui/material'
// UI Elements
import Button from '@mui/material/Button'
import React from 'react'
import { useLocation } from 'react-router'


const TrademarkBox = ({ count, trademark, onPress, size }) => {
  const location = useLocation()

  const isSelected = location.pathname.split('/').includes(`t${trademark.id.toString()}`) // TODO NEED TO BE IMPROVE
  const color = isSelected ? 'disabled' : 'primary'
  const style = (size && size === 'small') ? { width: "100%" } : { width: "100%" }

  return (
    <div style={{ marginRight: '10px', marginTop: '10px' }}>
      <Badge color="primary" badgeContent={count} style={{ width: '100%' }}>
        <Button color={color} onClick={onPress} p={2} m={1} boxShadow={1} variant="contained" style={style} size="small">
          {trademark.name}
        </Button>
      </Badge>
    </div>
  )
}

export default TrademarkBox

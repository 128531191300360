import { Button, Grid } from "@mui/material";
import { FieldArray, Form } from "formik";
import * as R from "ramda";
import React from "react";
import { useSelector } from "react-redux";
import DateField from "../../components/forms/Formik/DateField";
import SelectField from "../../components/forms/Formik/SelectField";
import TextAreaField from "../../components/forms/Formik/TextAreaField";
import TextField from "../../components/forms/Formik/TextField";
import ImagesField from "./Formik/ImagesField";

const VisitForm = ({ isValid, readonly }) => {
  const { contract_types, energy_types, climate_zones } = useSelector(
    (state) => {
      return {
        contract_types: R.compose(
          R.defaultTo([]),
          R.path(["contract_types", "response"])
        )(state),
        energy_types: R.compose(
          R.defaultTo([]),
          R.path(["energy_types", "response"])
        )(state),
        climate_zones: R.compose(
          R.defaultTo([]),
          R.path(["climate_zones", "response"])
        )(state),
      };
    }
  );

  return (
    <Form autoComplete="off">
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <TextField name="location_attributes.label" label={"Site"} disabled />
          <TextField name="location_attributes.street" label={"Rue"} disabled />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="location_attributes.zip"
            label={"Code postal"}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField name="location_attributes.city" label={"Ville"} disabled />
        </Grid>
        <Grid item xs={6}>
          <DateField name="seen_at" label="Date de visite" />
        </Grid>
        <Grid item xs={6}>
          <TextField name="shon" label="SHON" type="number" />
        </Grid>
        <Grid item xs={6}>
          <SelectField name="climate_zone_id" label="Zone climatique">
            <option></option>
            {climate_zones.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="location_attributes.year"
            label="Année de construction"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField name="contract_type_id" label="Type de contrat">
            <option></option>
            {contract_types.map((item) => (
              <option key={item.id} value={item.id}>
                {item.code}
              </option>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={6}>
          <SelectField name="energy_type_id" label="Type d'énergie">
            <option></option>
            {energy_types.map((item) => (
              <option key={item.id} value={item.id}>
                {item.code}
              </option>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={6}>
          <TextField name="primary_use" label="Usage principal" />
        </Grid>
        <Grid item xs={6}>
          <TextField name="energies_use" label="Energies et usages" />
        </Grid>
        <Grid item xs={12}>
          <TextAreaField name="comment" label="Commentaire" />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <FieldArray name="images_attributes" component={ImagesField} />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        {
          !readonly &&
            <Button
              variant="contained"
              color={isValid ? "primary" : "secondary"}
              type="submit"
            >
              Enregistrer
            </Button>
        }
      </Grid>
    </Form>
  );
};

export default VisitForm;

import * as types from './index'

export const meAction = () => {
  return {
    type: types.GET_ME,
  }
}

export const updateMeAction = (user) => {
  return {
    type: types.UPDATE_ME,
    payload: user
  }
}

import { flashErrorMessage, flashMessage } from 'redux-flash'
import { call, put, select } from 'redux-saga/effects'
import * as types from '../actions'
import { cloneCustomerErrorAction, cloneCustomerSuccessAction } from '../actions/CustomersActions'
import { cloneCustomer, getCustomer, getCustomers } from '../services/Api'
import { makeApi } from '../services/ApiSaga'

export function* customersSaga(payload) {
  try {
    const response = yield call(makeApi, getCustomers, payload)
    yield put({ type: types.GET_CUSTOMERS_SUCCESS, payload: response })

  } catch (error) {
    yield put({ type: types.GET_CUSTOMERS_ERROR, payload: error.message })
  }
}

export function* getCustomerSaga(action) {
  const { payload: { id } } = action
  try {
    const rsp = yield call(makeApi, getCustomer, id)
    yield put({ type: types.GET_CUSTOMER_SUCCESS, payload: rsp })

  } catch (error) {
    yield put({ type: types.GET_CUSTOMER_ERROR, payload: error.message })
  }
}

export function* cloneCustomerSaga(actions) {
  const { payload: { id } } = actions
  const isOnline = yield select((state) => state.offline.online)

  if (!isOnline) {
    yield put(flashErrorMessage('Impossible de cloner cet élément en étant en mode offline!'))
    yield put(cloneCustomerErrorAction(id))
    return
  }
  try {
    const rsp = yield call(makeApi, cloneCustomer, '', id)
    yield put(cloneCustomerSuccessAction(rsp))
    yield put(flashMessage('Elément cloné'))
  } catch (e) {
    yield put(flashErrorMessage('Un problème est survenu...'))
    yield put(cloneCustomerErrorAction(id))
  }
}

import localForage from 'localforage'
import { v4 as uuidv4 } from 'uuid'

const REGEX_URL = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/

const _fileCache = {}

export const saveFile = async (file, name = null) => {
    if (name) {
        const item = await localForage.setItem(name, file)
        const url = window.URL.createObjectURL(item)
        _fileCache[name] = url
        return {
            id: name,
            url
        }
    }
    const generateName = `local://${uuidv4()}`
    const item = await localForage.setItem(generateName, file)
    const url = window.URL.createObjectURL(item)
    _fileCache[generateName] = url
    return {
        id: generateName,
        url: url
    }
}

export const getFile = async (name) => {
    if (_fileCache[name]) {
        return {
            id: name,
            url: _fileCache[name]
        }
    }
    const item = await localForage.getItem(name)
    if (item) {
        const url = window.URL.createObjectURL(item)
        return {
            id: name,
            url: url
        }
    }
    if (name && typeof (name) === 'string' && name.match(REGEX_URL)) {
        return {
            id: uuidv4(),
            url: name
        }
    }
    return null
}

export const removeFile = async (name) => {
    delete _fileCache[name]
    await localForage.removeItem(name)
}

export const getFileBlob = async (name) => {
    return await localForage.getItem(name)
}
import {
  Button,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FuseJS from "fuse.js";
import moment from "moment";
import * as R from "ramda";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteP2Report, getP2Reports } from "../actions/P2ReportsAction";
import { confirm } from "../components/Confirms/ConfirmDestroy";
import ScreenTitle from "../components/ScreenTitle";
import { useNavigate } from "react-router";
import { isReadOnlySelector } from "../reducers/MeReducer";

const searchOptions = {
  shouldSort: true,
  tokenize: true,
  matchAllTokens: false,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    "user_fullname",
    "name",
    "market_object",
    "customer.name",
    "customer.city",
    "customer.address",
    "customer.comment",
    "customer.zip_code",
  ],
};

const P2ReportContainer = ({}) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const isReadOnly = useSelector(isReadOnlySelector)
  const [{ search, value }, setValues] = useState({ search: [], value: "" });
  useEffect(() => {
    dispatch(getP2Reports());
  }, []);

  const { p2_reports } = useSelector((state) => {
    return {
      p2_reports: state.p2_reports.payload,
    };
  });

  useEffect(() => {
    const count = R.compose(
      R.length,
      R.filter((x) => !x),
      R.uniq,
      R.map(R.prop("is_generate"))
    )(p2_reports);

    if (count === 0) return;
    const idInterval = setInterval(() => {
      dispatch(getP2Reports());
    }, 5000);

    return () => {
      clearInterval(idInterval);
    };
  }, [p2_reports]);

  const onDestroy = useCallback(async (item) => {
    if (await confirm(`Êtes-vous sûr de vouloir supprimer ce chiffrage P2 ?`)) {
      dispatch(deleteP2Report(item.id));
    }
  }, []);

  useEffect(() => {
    const fuseJS = new FuseJS(p2_reports, searchOptions);
    setValues((oldState) => {
      if (oldState.value === "") return { ...oldState, search: p2_reports };

      return {
        ...oldState,
        search: fuseJS.search(oldState.value).map((x) => x.item),
      };
    });
  }, [p2_reports]);

  const onSearch = useCallback(
    (_value) => {
      const fuseJS = new FuseJS(p2_reports, searchOptions);
      setValues((oldState) => {
        if (_value === "") return { value: _value, search: p2_reports };

        return {
          value: _value,
          search: fuseJS.search(oldState.value).map((x) => x.item),
        };
      });
    },
    [p2_reports]
  );

  return (
    <div>
      <ScreenTitle onSearch={onSearch} title="Gestion des chiffrages P2" />
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Généré le</TableCell>
              <TableCell>Généré par</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>XLS</TableCell>
              <TableCell size="small" align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {search.map((p2_report) => (
              <TableRow key={p2_report.id}>
                <TableCell>
                  {moment(p2_report.created_at).format("DD-MM-YYYY HH:mm")}
                </TableCell>
                <TableCell>{p2_report.user_fullname}</TableCell>
                <TableCell>
                  {p2_report.date &&
                    moment(p2_report.date).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell>{p2_report.customer.name}</TableCell>
                {!p2_report.is_generate && (
                  <TableCell>En cours de génération...</TableCell>
                )}
                {p2_report.is_generate && (
                  <TableCell>
                    <a target="_blank" href={p2_report.xls_url}>
                      {p2_report.xls_name}
                    </a>
                  </TableCell>
                )}

                <TableCell size="small" align="right">
                  {
                    !isReadOnly &&
                      <Button
                        onClick={() => onDestroy(p2_report)}
                        variant="contained"
                        color="secondary"
                      >
                        Supprimer
                      </Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {
        !isReadOnly &&
          <Fab
            onClick={() => navigation("/chiffrages-p2/create")}
            color="primary"
            aria-label="add"
            className="fab-add"
          >
            <AddIcon />
          </Fab>
      }
    </div>
  );
};

export default P2ReportContainer;
